

import type { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { gql } from 'graphql-tag'

/* tslint:disable */
/* eslint-disable */

const VariableName = ' $1fcbcbff-3e78-462f-b45c-668a3e09bfd8'

const ScalarBrandingField = ' $1fcbcbff-3e78-462f-b45c-668a3e09bfd9'

type CustomScalar<T> = { [ScalarBrandingField]: T }

class Variable<T, Name extends string> {
  private [VariableName]: Name
  // @ts-ignore
  private _type?: T

  // @ts-ignore
  constructor(name: Name, private readonly isRequired?: boolean) {
    this[VariableName] = name
  }
}

type ArrayInput<I> = [I] extends [$Atomic] ? never : ReadonlyArray<VariabledInput<I>>

type AllowedInlineScalars<S> = S extends string | number ? S : never

export type UnwrapCustomScalars<T> = T extends CustomScalar<infer S>
  ? S
  : T extends ReadonlyArray<infer I>
  ? ReadonlyArray<UnwrapCustomScalars<I>>
  : T extends Record<string, any>
  ? { [K in keyof T]: UnwrapCustomScalars<T[K]> }
  : T

type VariableWithoutScalars<T, Str extends string> = Variable<UnwrapCustomScalars<T>, Str>

// the array wrapper prevents distributive conditional types
// https://www.typescriptlang.org/docs/handbook/2/conditional-types.html#distributive-conditional-types
type VariabledInput<T> = [T] extends [CustomScalar<infer S> | null | undefined]
  ? // scalars only support variable input
    Variable<S | null | undefined, any> | AllowedInlineScalars<S> | null | undefined
  : [T] extends [CustomScalar<infer S>]
  ? Variable<S, any> | AllowedInlineScalars<S>
  : [T] extends [$Atomic]
  ? Variable<T, any> | T
  : T extends ReadonlyArray<infer I>
  ? VariableWithoutScalars<T, any> | T | ArrayInput<I>
  : T extends Record<string, any> | null | undefined
  ?
      | VariableWithoutScalars<T | null | undefined, any>
      | null
      | undefined
      | { [K in keyof T]: VariabledInput<T[K]> }
      | T
  : T extends Record<string, any>
  ? VariableWithoutScalars<T, any> | { [K in keyof T]: VariabledInput<T[K]> } | T
  : never

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never

/**
 * Creates a new query variable
 *
 * @param name The variable name
 */
export const $ = <Type, Name extends string>(name: Name): Variable<Type, Name> => {
  return new Variable(name)
}

/**
 * Creates a new query variable. A value will be required even if the input is optional
 *
 * @param name The variable name
 */
export const $$ = <Type, Name extends string>(name: Name): Variable<NonNullable<Type>, Name> => {
  return new Variable(name, true)
}

type SelectOptions = {
  argTypes?: { [key: string]: string }
  args?: { [key: string]: any }
  selection?: Selection<any>
}

class $Field<Name extends string, Type, Vars = {}> {
  public kind: 'field' = 'field'
  public type!: Type

  public vars!: Vars
  public alias: string | null = null

  constructor(public name: Name, public options: SelectOptions) {}

  as<Rename extends string>(alias: Rename): $Field<Rename, Type, Vars> {
    const f = new $Field(this.name, this.options)
    f.alias = alias
    return f as any
  }
}

class $Base<Name extends string> {
  // @ts-ignore
  constructor(private $$name: Name) {}

  protected $_select<Key extends string>(
    name: Key,
    options: SelectOptions = {}
  ): $Field<Key, any, any> {
    return new $Field(name, options)
  }
}

// @ts-ignore
class $Union<T, Name extends String> extends $Base<Name> {
  // @ts-ignore
  private $$type!: T
  // @ts-ignore
  private $$name!: Name

  constructor(private selectorClasses: { [K in keyof T]: { new (): T[K] } }, $$name: Name) {
    super($$name)
  }

  $on<Type extends keyof T, Sel extends Selection<T[Type]>>(
    alternative: Type,
    selectorFn: (selector: T[Type]) => [...Sel]
  ): $UnionSelection<GetOutput<Sel>, GetVariables<Sel>> {
    const selection = selectorFn(new this.selectorClasses[alternative]())

    return new $UnionSelection(alternative as string, selection)
  }
}

// @ts-ignore
class $Interface<T, Name extends string> extends $Base<Name> {
  // @ts-ignore
  private $$type!: T
  // @ts-ignore
  private $$name!: Name

  constructor(private selectorClasses: { [K in keyof T]: { new (): T[K] } }, $$name: Name) {
    super($$name)
  }
  $on<Type extends keyof T, Sel extends Selection<T[Type]>>(
    alternative: Type,
    selectorFn: (selector: T[Type]) => [...Sel]
  ): $UnionSelection<GetOutput<Sel>, GetVariables<Sel>> {
    const selection = selectorFn(new this.selectorClasses[alternative]())

    return new $UnionSelection(alternative as string, selection)
  }
}

class $UnionSelection<T, Vars> {
  public kind: 'union' = 'union'
  // @ts-ignore
  private vars!: Vars
  constructor(public alternativeName: string, public alternativeSelection: Selection<T>) {}
}

type Selection<_any> = ReadonlyArray<$Field<any, any, any> | $UnionSelection<any, any>>

type NeverNever<T> = [T] extends [never] ? {} : T

type Simplify<T> = { [K in keyof T]: T[K] } & {}

type LeafType<T> = T extends CustomScalar<infer S> ? S : T

export type GetOutput<X extends Selection<any>> = Simplify<
  UnionToIntersection<
    {
      [I in keyof X]: X[I] extends $Field<infer Name, infer Type, any>
        ? { [K in Name]: LeafType<Type> }
        : never
    }[keyof X & number]
  > &
    NeverNever<
      {
        [I in keyof X]: X[I] extends $UnionSelection<infer Type, any> ? LeafType<Type> : never
      }[keyof X & number]
    >
>

type PossiblyOptionalVar<VName extends string, VType> = undefined extends VType
  ? { [key in VName]?: VType }
  : null extends VType
  ? { [key in VName]?: VType }
  : { [key in VName]: VType }

type ExtractInputVariables<Inputs> = Inputs extends Variable<infer VType, infer VName>
  ? PossiblyOptionalVar<VName, VType>
  : // Avoid generating an index signature for possibly undefined or null inputs.
  // The compiler incorrectly infers null or undefined, and we must force access the Inputs
  // type to convince the compiler its "never", while still retaining {} as the result
  // for null and undefined cases
  // Works around issue 79
  Inputs extends null | undefined
  ? { [K in keyof Inputs]: Inputs[K] }
  : Inputs extends $Atomic
  ? {}
  : Inputs extends any[] | readonly any[]
  ? UnionToIntersection<
      { [K in keyof Inputs]: ExtractInputVariables<Inputs[K]> }[keyof Inputs & number]
    >
  : UnionToIntersection<{ [K in keyof Inputs]: ExtractInputVariables<Inputs[K]> }[keyof Inputs]>

export type GetVariables<Sel extends Selection<any>, ExtraVars = {}> = UnionToIntersection<
  {
    [I in keyof Sel]: Sel[I] extends $Field<any, any, infer Vars>
      ? Vars
      : Sel[I] extends $UnionSelection<any, infer Vars>
      ? Vars
      : never
  }[keyof Sel & number]
> &
  ExtractInputVariables<ExtraVars>

type ArgVarType = {
  type: string
  isRequired: boolean
  array: {
    isRequired: boolean
  } | null
}

const arrRegex = /\[(.*?)\]/

/**
 * Converts graphql string type to `ArgVarType`
 * @param input
 * @returns
 */
function getArgVarType(input: string): ArgVarType {
  const array = input.includes('[')
    ? {
        isRequired: input.endsWith('!'),
      }
    : null

  const type = array ? arrRegex.exec(input)![1]! : input
  const isRequired = type.endsWith('!')

  return {
    array,
    isRequired: isRequired,
    type: type.replace('!', ''),
  }
}

function fieldToQuery(prefix: string, field: $Field<any, any, any>) {
  const variables = new Map<string, { variable: Variable<any, any>; type: ArgVarType }>()

  function stringifyArgs(
    args: any,
    argTypes: { [key: string]: string },
    argVarType?: ArgVarType
  ): string {
    switch (typeof args) {
      case 'string':
        const cleanType = argVarType!.type
        if ($Enums.has(cleanType!)) return args
        else return JSON.stringify(args)
      case 'number':
      case 'boolean':
        return JSON.stringify(args)
      default:
        if (args == null) return 'null'
        if (VariableName in (args as any)) {
          if (!argVarType)
            throw new globalThis.Error('Cannot use variabe as sole unnamed field argument')
          const variable = args as Variable<any, any>
          const argVarName = variable[VariableName]
          variables.set(argVarName, { type: argVarType, variable: variable })
          return '$' + argVarName
        }
        if (Array.isArray(args))
          return '[' + args.map(arg => stringifyArgs(arg, argTypes, argVarType)).join(',') + ']'
        const wrapped = (content: string) => (argVarType ? '{' + content + '}' : content)
        return wrapped(
          Array.from(Object.entries(args))
            .map(([key, val]) => {
              let argTypeForKey = argTypes[key]
              if (!argTypeForKey) {
                throw new globalThis.Error(`Argument type for ${key} not found`)
              }
              const cleanType = argTypeForKey.replace('[', '').replace(']', '').replace(/!/g, '')
              return (
                key +
                ':' +
                stringifyArgs(val, $InputTypes[cleanType]!, getArgVarType(argTypeForKey))
              )
            })
            .join(',')
        )
    }
  }

  function extractTextAndVars(field: $Field<any, any, any> | $UnionSelection<any, any>) {
    if (field.kind === 'field') {
      let retVal = field.name
      if (field.alias) retVal = field.alias + ':' + retVal
      const args = field.options.args,
        argTypes = field.options.argTypes
      if (args && Object.keys(args).length > 0) {
        retVal += '(' + stringifyArgs(args, argTypes!) + ')'
      }
      let sel = field.options.selection
      if (sel) {
        retVal += '{'
        for (let subField of sel) {
          retVal += extractTextAndVars(subField)
        }
        retVal += '}'
      }
      return retVal + ' '
    } else if (field.kind === 'union') {
      let retVal = '... on ' + field.alternativeName + ' {'
      for (let subField of field.alternativeSelection) {
        retVal += extractTextAndVars(subField)
      }
      retVal += '}'

      return retVal + ' '
    } else {
      throw new globalThis.Error('Uknown field kind')
    }
  }

  const queryRaw = extractTextAndVars(field)!

  const queryBody = queryRaw.substring(queryRaw.indexOf('{'))

  const varList = Array.from(variables.entries())
  let ret = prefix
  if (varList.length) {
    ret +=
      '(' +
      varList
        .map(([name, { type: kind, variable }]) => {
          let type = kind.array ? '[' : ''
          type += kind.type
          if (kind.isRequired) type += '!'
          if (kind.array) type += kind.array.isRequired ? ']!' : ']'

          if (!type.endsWith('!') && (variable as any).isRequired === true) {
            type += '!'
          }

          return '$' + name + ':' + type
        })
        .join(',') +
      ')'
  }
  ret += queryBody

  return ret
}

export type OutputTypeOf<T> = T extends $Interface<infer Subtypes, any>
  ? { [K in keyof Subtypes]: OutputTypeOf<Subtypes[K]> }[keyof Subtypes]
  : T extends $Union<infer Subtypes, any>
  ? { [K in keyof Subtypes]: OutputTypeOf<Subtypes[K]> }[keyof Subtypes]
  : T extends $Base<any>
  ? { [K in keyof T]?: OutputTypeOf<T[K]> }
  : [T] extends [$Field<any, infer FieldType, any>]
  ? FieldType
  : [T] extends [(selFn: (arg: infer Inner) => any) => any]
  ? OutputTypeOf<Inner>
  : [T] extends [(args: any, selFn: (arg: infer Inner) => any) => any]
  ? OutputTypeOf<Inner>
  : never

export type QueryOutputType<T extends TypedDocumentNode<any>> = T extends TypedDocumentNode<
  infer Out
>
  ? Out
  : never

export type QueryInputType<T extends TypedDocumentNode<any>> = T extends TypedDocumentNode<
  any,
  infer In
>
  ? In
  : never

export function fragment<T, Sel extends Selection<T>>(
  GQLType: { new (): T },
  selectFn: (selector: T) => [...Sel]
) {
  return selectFn(new GQLType())
}

type LastOf<T> = UnionToIntersection<T extends any ? () => T : never> extends () => infer R
  ? R
  : never

// TS4.0+
type Push<T extends any[], V> = [...T, V]

// TS4.1+
type TuplifyUnion<T, L = LastOf<T>, N = [T] extends [never] ? true : false> = true extends N
  ? []
  : Push<TuplifyUnion<Exclude<T, L>>, L>

type AllFieldProperties<I> = {
  [K in keyof I]: I[K] extends $Field<infer Name, infer Type, any> ? $Field<Name, Type, any> : never
}

type ValueOf<T> = T[keyof T]

export type AllFields<T> = TuplifyUnion<ValueOf<AllFieldProperties<T>>>

export function all<I extends $Base<any>>(instance: I) {
  const prototype = Object.getPrototypeOf(instance)
  const allFields = Object.getOwnPropertyNames(prototype)
    .map(k => prototype[k])
    .filter(o => o?.kind === 'field')
    .map(o => o?.name) as (keyof typeof instance)[]
  return allFields.map(fieldName => instance?.[fieldName]) as any as AllFields<I>
}

// We use a dummy conditional type that involves GenericType to defer the compiler's inference of
// any possible variables nested in this type. This addresses a problem where variables are
// inferred with type unknown
type ExactArgNames<GenericType, Constraint> = GenericType extends never
  ? never
  : [Constraint] extends [$Atomic | CustomScalar<any>]
  ? GenericType
  : Constraint extends ReadonlyArray<infer InnerConstraint>
  ? GenericType extends ReadonlyArray<infer Inner>
    ? ReadonlyArray<ExactArgNames<Inner, InnerConstraint>>
    : GenericType
  : GenericType & {
      [Key in keyof GenericType]: Key extends keyof Constraint
        ? ExactArgNames<GenericType[Key], Constraint[Key]>
        : never
    }

type $Atomic = ActionType | AggregateType | ApplyPolicy | AxisType | ChartType | DataPointActionType | DataSourceCapability | DataType | EntityType | FieldTypeKey | HealthStatus | InviteMethod | ListSortDirection | NumericComparator | OverrideLocationBehaviour | PieChartStyle | RecordItemStatus | ReferencedContext | SchemaProviderType | Section | StringComparator | SubjectIdentifier | SubjectSelfAccessStatusFlag | TenantFeatureKey | UserIdentifierType | VisualisationType | number | string | boolean | null | undefined

let $Enums = new Set<string>(["ActionType","AggregateType","ApplyPolicy","AxisType","ChartType","DataPointActionType","DataSourceCapability","DataType","EntityType","FieldTypeKey","HealthStatus","InviteMethod","ListSortDirection","NumericComparator","OverrideLocationBehaviour","PieChartStyle","RecordItemStatus","ReferencedContext","SchemaProviderType","Section","StringComparator","SubjectIdentifier","SubjectSelfAccessStatusFlag","TenantFeatureKey","UserIdentifierType","VisualisationType"])



export class IAction extends $Interface<{DisplayAction: DisplayAction,PerformAction: PerformAction}, "IAction"> {
  constructor() {
    super({DisplayAction: DisplayAction,PerformAction: PerformAction}, "IAction")
  }
  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get type(): $Field<"type", DataPointActionType>  {
       return this.$_select("type") as any
      }
}


export class IAxis extends $Interface<{Axis: Axis,SteppedAxis: SteppedAxis}, "IAxis"> {
  constructor() {
    super({Axis: Axis,SteppedAxis: SteppedAxis}, "IAxis")
  }
  
      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get label(): $Field<"label", string>  {
       return this.$_select("label") as any
      }

      
      series<Sel extends Selection<Series>>(selectorFn: (s: Series) => [...Sel]):$Field<"series", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Series)
      };
      return this.$_select("series", options as any) as any
    }
  

      
      get type(): $Field<"type", AxisType>  {
       return this.$_select("type") as any
      }
}


export class ICapabilityMetadata extends $Interface<{StructuredDataCapabilityMetadata: StructuredDataCapabilityMetadata}, "ICapabilityMetadata"> {
  constructor() {
    super({StructuredDataCapabilityMetadata: StructuredDataCapabilityMetadata}, "ICapabilityMetadata")
  }
  
      
      get capability(): $Field<"capability", DataSourceCapability>  {
       return this.$_select("capability") as any
      }
}


export class IDataSource extends $Interface<{BundledDataSource: BundledDataSource,CustomDataSource: CustomDataSource}, "IDataSource"> {
  constructor() {
    super({BundledDataSource: BundledDataSource,CustomDataSource: CustomDataSource}, "IDataSource")
  }
  
      
      configurationOptions<Sel extends Selection<ConfigurationOption>>(selectorFn: (s: ConfigurationOption) => [...Sel]):$Field<"configurationOptions", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationOption)
      };
      return this.$_select("configurationOptions", options as any) as any
    }
  

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }
}


export class IDataTypeValue extends $Interface<{BooleanDataTypeValue: BooleanDataTypeValue,DateDataTypeValue: DateDataTypeValue,DateTimeDataTypeValue: DateTimeDataTypeValue,DecimalDataTypeValue: DecimalDataTypeValue,IntegerDataTypeValue: IntegerDataTypeValue,StringDataTypeValue: StringDataTypeValue,TimeDataTypeValue: TimeDataTypeValue,UserIdentifierDataTypeValue: UserIdentifierDataTypeValue}, "IDataTypeValue"> {
  constructor() {
    super({BooleanDataTypeValue: BooleanDataTypeValue,DateDataTypeValue: DateDataTypeValue,DateTimeDataTypeValue: DateTimeDataTypeValue,DecimalDataTypeValue: DecimalDataTypeValue,IntegerDataTypeValue: IntegerDataTypeValue,StringDataTypeValue: StringDataTypeValue,TimeDataTypeValue: TimeDataTypeValue,UserIdentifierDataTypeValue: UserIdentifierDataTypeValue}, "IDataTypeValue")
  }
  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }
}


export class IDataTypeValueFilter extends $Interface<{BooleanDataTypeValueFilter: BooleanDataTypeValueFilter,DateDataTypeValueFilter: DateDataTypeValueFilter,DateTimeDataTypeValueFilter: DateTimeDataTypeValueFilter,DecimalDataTypeValueFilter: DecimalDataTypeValueFilter,IntegerDataTypeValueFilter: IntegerDataTypeValueFilter,StringDataTypeValueFilter: StringDataTypeValueFilter,TimeDataTypeValueFilter: TimeDataTypeValueFilter,UserIdentifierDataTypeValueFilter: UserIdentifierDataTypeValueFilter}, "IDataTypeValueFilter"> {
  constructor() {
    super({BooleanDataTypeValueFilter: BooleanDataTypeValueFilter,DateDataTypeValueFilter: DateDataTypeValueFilter,DateTimeDataTypeValueFilter: DateTimeDataTypeValueFilter,DecimalDataTypeValueFilter: DecimalDataTypeValueFilter,IntegerDataTypeValueFilter: IntegerDataTypeValueFilter,StringDataTypeValueFilter: StringDataTypeValueFilter,TimeDataTypeValueFilter: TimeDataTypeValueFilter,UserIdentifierDataTypeValueFilter: UserIdentifierDataTypeValueFilter}, "IDataTypeValueFilter")
  }
  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }
}


export class IDisplayOptions extends $Interface<{GraphChartDisplayOption: GraphChartDisplayOption,LiquidDisplayOption: LiquidDisplayOption,PieChartDisplayOption: PieChartDisplayOption,TableDisplayOption: TableDisplayOption}, "IDisplayOptions"> {
  constructor() {
    super({GraphChartDisplayOption: GraphChartDisplayOption,LiquidDisplayOption: LiquidDisplayOption,PieChartDisplayOption: PieChartDisplayOption,TableDisplayOption: TableDisplayOption}, "IDisplayOptions")
  }
  
      
      get visualisationType(): $Field<"visualisationType", VisualisationType>  {
       return this.$_select("visualisationType") as any
      }
}


export class IFieldType extends $Interface<{}, "IFieldType"> {
  constructor() {
    super({}, "IFieldType")
  }
  
      
      get key(): $Field<"key", FieldTypeKey>  {
       return this.$_select("key") as any
      }
}


export class IGQLFieldType extends $Interface<{MultipleChoiceFieldType: MultipleChoiceFieldType,SimpleFieldType: SimpleFieldType}, "IGQLFieldType"> {
  constructor() {
    super({MultipleChoiceFieldType: MultipleChoiceFieldType,SimpleFieldType: SimpleFieldType}, "IGQLFieldType")
  }
  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get key(): $Field<"key", FieldTypeKey>  {
       return this.$_select("key") as any
      }
}


export class IIdentifier extends $Interface<{NestedIdentifier: NestedIdentifier,SingleIdentifier: SingleIdentifier}, "IIdentifier"> {
  constructor() {
    super({NestedIdentifier: NestedIdentifier,SingleIdentifier: SingleIdentifier}, "IIdentifier")
  }
  
      
      get allIdentifiers(): $Field<"allIdentifiers", Readonly<Array<string>>>  {
       return this.$_select("allIdentifiers") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }
}


export class ILiquidParseResult extends $Interface<{InvalidLiquidParseResult: InvalidLiquidParseResult,ValidLiquidParseResult: ValidLiquidParseResult}, "ILiquidParseResult"> {
  constructor() {
    super({InvalidLiquidParseResult: InvalidLiquidParseResult,ValidLiquidParseResult: ValidLiquidParseResult}, "ILiquidParseResult")
  }
  
      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }
}


export class ISchemaParseResult extends $Interface<{InvalidSchemaParseResult: InvalidSchemaParseResult,ValidSchemaParseResult: ValidSchemaParseResult}, "ISchemaParseResult"> {
  constructor() {
    super({InvalidSchemaParseResult: InvalidSchemaParseResult,ValidSchemaParseResult: ValidSchemaParseResult}, "ISchemaParseResult")
  }
  
      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }
}


export class ISeries extends $Interface<{Series: Series,SeriesWithReferenceValue: SeriesWithReferenceValue}, "ISeries"> {
  constructor() {
    super({Series: Series,SeriesWithReferenceValue: SeriesWithReferenceValue}, "ISeries")
  }
  
      
      get chartType(): $Field<"chartType", ChartType | null>  {
       return this.$_select("chartType") as any
      }

      
      get colour(): $Field<"colour", string | null>  {
       return this.$_select("colour") as any
      }

      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get hidden(): $Field<"hidden", boolean>  {
       return this.$_select("hidden") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get liquidFormatter(): $Field<"liquidFormatter", string | null>  {
       return this.$_select("liquidFormatter") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get order(): $Field<"order", number>  {
       return this.$_select("order") as any
      }

      
      get renderedName(): $Field<"renderedName", string | null>  {
       return this.$_select("renderedName") as any
      }
}


export class IStageValidationResult extends $Interface<{GroupStageValidationResult: GroupStageValidationResult,StageValidationResult: StageValidationResult}, "IStageValidationResult"> {
  constructor() {
    super({GroupStageValidationResult: GroupStageValidationResult,StageValidationResult: StageValidationResult}, "IStageValidationResult")
  }
  
      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      outputs<Sel extends Selection<TypedField>>(selectorFn: (s: TypedField) => [...Sel]):$Field<"outputs", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TypedField)
      };
      return this.$_select("outputs", options as any) as any
    }
  

      
      steps<Sel extends Selection<StepValidationResult>>(selectorFn: (s: StepValidationResult) => [...Sel]):$Field<"steps", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StepValidationResult)
      };
      return this.$_select("steps", options as any) as any
    }
  
}


export class ISubjectCustomField extends $Interface<{SubjectComputedField: SubjectComputedField,SubjectInputField: SubjectInputField}, "ISubjectCustomField"> {
  constructor() {
    super({SubjectComputedField: SubjectComputedField,SubjectInputField: SubjectInputField}, "ISubjectCustomField")
  }
  
      
      get canModify(): $Field<"canModify", boolean>  {
       return this.$_select("canModify") as any
      }

      
      clone<Args extends VariabledInput<{
        id: Uuid,
      }>,Sel extends Selection<ISubjectCustomField>>(args: ExactArgNames<Args, {
        id: Uuid,
      }>, selectorFn: (s: ISubjectCustomField) => [...Sel]):$Field<"clone", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "Uuid!"
            },
        args,

        selection: selectorFn(new ISubjectCustomField)
      };
      return this.$_select("clone", options as any) as any
    }
  

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }
}


export class ITransformDefinition extends $Interface<{AggregateDefinition: AggregateDefinition,LiquidMappingDefinition: LiquidMappingDefinition}, "ITransformDefinition"> {
  constructor() {
    super({AggregateDefinition: AggregateDefinition,LiquidMappingDefinition: LiquidMappingDefinition}, "ITransformDefinition")
  }
  
      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get outputKey(): $Field<"outputKey", string>  {
       return this.$_select("outputKey") as any
      }
}


export class SubjectCustomFieldValue extends $Interface<{SubjectCustomFieldArrayValue: SubjectCustomFieldArrayValue,SubjectCustomFieldSingleValue: SubjectCustomFieldSingleValue}, "SubjectCustomFieldValue"> {
  constructor() {
    super({SubjectCustomFieldArrayValue: SubjectCustomFieldArrayValue,SubjectCustomFieldSingleValue: SubjectCustomFieldSingleValue}, "SubjectCustomFieldValue")
  }
  
      
      customField<Sel extends Selection<ISubjectCustomField>>(selectorFn: (s: ISubjectCustomField) => [...Sel]):$Field<"customField", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ISubjectCustomField)
      };
      return this.$_select("customField", options as any) as any
    }
  
}


export class Widget extends $Interface<{IFrameWidget: IFrameWidget,Visualisation: Visualisation}, "Widget"> {
  constructor() {
    super({IFrameWidget: IFrameWidget,Visualisation: Visualisation}, "Widget")
  }
  
      
      get forPortal(): $Field<"forPortal", boolean>  {
       return this.$_select("forPortal") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get title(): $Field<"title", string>  {
       return this.$_select("title") as any
      }
}


/**
 * A connection to a list of items.
 */
export class AccessibleStructuralEntitiesConnection extends $Base<"AccessibleStructuralEntitiesConnection"> {
  constructor() {
    super("AccessibleStructuralEntitiesConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<AccessibleStructuralEntitiesEdge>>(selectorFn: (s: AccessibleStructuralEntitiesEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AccessibleStructuralEntitiesEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<StructuralEntity>>(selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "AccessibleStructuralEntitiesConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class AccessibleStructuralEntitiesEdge extends $Base<"AccessibleStructuralEntitiesEdge"> {
  constructor() {
    super("AccessibleStructuralEntitiesEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<StructuralEntity>>(selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "AccessibleStructuralEntitiesEdge">  {
       return this.$_select("__typename") as any
      }
}


export class AggregateDefinition extends $Base<"AggregateDefinition"> {
  constructor() {
    super("AggregateDefinition")
  }

  
      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get inputKeys(): $Field<"inputKeys", Readonly<Array<string>>>  {
       return this.$_select("inputKeys") as any
      }

      
      get outputKey(): $Field<"outputKey", string>  {
       return this.$_select("outputKey") as any
      }

      
      get type(): $Field<"type", AggregateType>  {
       return this.$_select("type") as any
      }

      
      get __typename(): $Field<"__typename", "AggregateDefinition">  {
       return this.$_select("__typename") as any
      }
}


export class AireVaultSecretReference extends $Base<"AireVaultSecretReference"> {
  constructor() {
    super("AireVaultSecretReference")
  }

  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get urn(): $Field<"urn", string>  {
       return this.$_select("urn") as any
      }

      
      get __typename(): $Field<"__typename", "AireVaultSecretReference">  {
       return this.$_select("__typename") as any
      }
}


export class AireVaultValueReference extends $Base<"AireVaultValueReference"> {
  constructor() {
    super("AireVaultValueReference")
  }

  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get urn(): $Field<"urn", string>  {
       return this.$_select("urn") as any
      }

      
      get __typename(): $Field<"__typename", "AireVaultValueReference">  {
       return this.$_select("__typename") as any
      }
}


export class AppConfig extends $Base<"AppConfig"> {
  constructor() {
    super("AppConfig")
  }

  
      
      customFieldTypes<Args extends VariabledInput<{
        first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<CustomFieldTypesConnection>>(args: ExactArgNames<Args, {
        first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: CustomFieldTypesConnection) => [...Sel]):$Field<"customFieldTypes", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
customFieldTypes<Sel extends Selection<CustomFieldTypesConnection>>(selectorFn: (s: CustomFieldTypesConnection) => [...Sel]):$Field<"customFieldTypes", GetOutput<Sel> | undefined , GetVariables<Sel>>
customFieldTypes(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new CustomFieldTypesConnection)
      };
      return this.$_select("customFieldTypes", options as any) as any
    }
  

      
      featureSet<Sel extends Selection<GQLFeatureSet>>(selectorFn: (s: GQLFeatureSet) => [...Sel]):$Field<"featureSet", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new GQLFeatureSet)
      };
      return this.$_select("featureSet", options as any) as any
    }
  

      
      permissions<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<PermissionsConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: PermissionsConnection) => [...Sel]):$Field<"permissions", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
permissions<Sel extends Selection<PermissionsConnection>>(selectorFn: (s: PermissionsConnection) => [...Sel]):$Field<"permissions", GetOutput<Sel> | undefined , GetVariables<Sel>>
permissions(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new PermissionsConnection)
      };
      return this.$_select("permissions", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "AppConfig">  {
       return this.$_select("__typename") as any
      }
}


export class AppPermission extends $Base<"AppPermission"> {
  constructor() {
    super("AppPermission")
  }

  
      
      get action(): $Field<"action", string>  {
       return this.$_select("action") as any
      }

      
      get entityType(): $Field<"entityType", EntityType>  {
       return this.$_select("entityType") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get subject(): $Field<"subject", string>  {
       return this.$_select("subject") as any
      }

      
      get __typename(): $Field<"__typename", "AppPermission">  {
       return this.$_select("__typename") as any
      }
}


export class AvailableForm extends $Base<"AvailableForm"> {
  constructor() {
    super("AvailableForm")
  }

  
      
      get classification(): $Field<"classification", string>  {
       return this.$_select("classification") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get title(): $Field<"title", string>  {
       return this.$_select("title") as any
      }

      
      get __typename(): $Field<"__typename", "AvailableForm">  {
       return this.$_select("__typename") as any
      }
}


export class Axis extends $Base<"Axis"> {
  constructor() {
    super("Axis")
  }

  
      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get label(): $Field<"label", string>  {
       return this.$_select("label") as any
      }

      
      series<Sel extends Selection<Series>>(selectorFn: (s: Series) => [...Sel]):$Field<"series", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Series)
      };
      return this.$_select("series", options as any) as any
    }
  

      
      get type(): $Field<"type", AxisType>  {
       return this.$_select("type") as any
      }

      
      get __typename(): $Field<"__typename", "Axis">  {
       return this.$_select("__typename") as any
      }
}


export class BooleanDataTypeValue extends $Base<"BooleanDataTypeValue"> {
  constructor() {
    super("BooleanDataTypeValue")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      get value(): $Field<"value", boolean | null>  {
       return this.$_select("value") as any
      }

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "BooleanDataTypeValue">  {
       return this.$_select("__typename") as any
      }
}


export class BooleanDataTypeValueFilter extends $Base<"BooleanDataTypeValueFilter"> {
  constructor() {
    super("BooleanDataTypeValueFilter")
  }

  
      
      get booleanValue(): $Field<"booleanValue", boolean | null>  {
       return this.$_select("booleanValue") as any
      }

      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }

      
      get __typename(): $Field<"__typename", "BooleanDataTypeValueFilter">  {
       return this.$_select("__typename") as any
      }
}


export class BundledDataSource extends $Base<"BundledDataSource"> {
  constructor() {
    super("BundledDataSource")
  }

  
      
      configurationOptions<Sel extends Selection<ConfigurationOption>>(selectorFn: (s: ConfigurationOption) => [...Sel]):$Field<"configurationOptions", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationOption)
      };
      return this.$_select("configurationOptions", options as any) as any
    }
  

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get __typename(): $Field<"__typename", "BundledDataSource">  {
       return this.$_select("__typename") as any
      }
}


export class CollectionItem extends $Base<"CollectionItem"> {
  constructor() {
    super("CollectionItem")
  }

  
      
      get positionIndex(): $Field<"positionIndex", number>  {
       return this.$_select("positionIndex") as any
      }

      
      widget<Sel extends Selection<Widget>>(selectorFn: (s: Widget) => [...Sel]):$Field<"widget", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Widget)
      };
      return this.$_select("widget", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "CollectionItem">  {
       return this.$_select("__typename") as any
      }
}


export class ComputedField extends $Base<"ComputedField"> {
  constructor() {
    super("ComputedField")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isArray(): $Field<"isArray", boolean>  {
       return this.$_select("isArray") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get liquidMapping(): $Field<"liquidMapping", string>  {
       return this.$_select("liquidMapping") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "ComputedField">  {
       return this.$_select("__typename") as any
      }
}


export class ConfigurationExportResult extends $Base<"ConfigurationExportResult"> {
  constructor() {
    super("ConfigurationExportResult")
  }

  
      
      get configExported(): $Field<"configExported", boolean>  {
       return this.$_select("configExported") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get message(): $Field<"message", string>  {
       return this.$_select("message") as any
      }

      
      get __typename(): $Field<"__typename", "ConfigurationExportResult">  {
       return this.$_select("__typename") as any
      }
}


export class ConfigurationFile extends $Base<"ConfigurationFile"> {
  constructor() {
    super("ConfigurationFile")
  }

  
      
      get created(): $Field<"created", DateTime>  {
       return this.$_select("created") as any
      }

      
      get filePath(): $Field<"filePath", string>  {
       return this.$_select("filePath") as any
      }

      
      get version(): $Field<"version", number>  {
       return this.$_select("version") as any
      }

      
      get __typename(): $Field<"__typename", "ConfigurationFile">  {
       return this.$_select("__typename") as any
      }
}


export class ConfigurationImportResult extends $Base<"ConfigurationImportResult"> {
  constructor() {
    super("ConfigurationImportResult")
  }

  
      
      get configImported(): $Field<"configImported", boolean>  {
       return this.$_select("configImported") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get message(): $Field<"message", string>  {
       return this.$_select("message") as any
      }

      
      get __typename(): $Field<"__typename", "ConfigurationImportResult">  {
       return this.$_select("__typename") as any
      }
}


export class ConfigurationOption extends $Base<"ConfigurationOption"> {
  constructor() {
    super("ConfigurationOption")
  }

  
      
      get isRequired(): $Field<"isRequired", boolean>  {
       return this.$_select("isRequired") as any
      }

      
      get isSensitive(): $Field<"isSensitive", boolean>  {
       return this.$_select("isSensitive") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "ConfigurationOption">  {
       return this.$_select("__typename") as any
      }
}


export class ConfigurationValue extends $Base<"ConfigurationValue"> {
  constructor() {
    super("ConfigurationValue")
  }

  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      value<Sel extends Selection<AireVaultValueReference>>(selectorFn: (s: AireVaultValueReference) => [...Sel]):$Field<"value", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AireVaultValueReference)
      };
      return this.$_select("value", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "ConfigurationValue">  {
       return this.$_select("__typename") as any
      }
}


export class CustomDataSource extends $Base<"CustomDataSource"> {
  constructor() {
    super("CustomDataSource")
  }

  
      
      configurationOptions<Sel extends Selection<ConfigurationOption>>(selectorFn: (s: ConfigurationOption) => [...Sel]):$Field<"configurationOptions", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationOption)
      };
      return this.$_select("configurationOptions", options as any) as any
    }
  

      
      connectionConfiguration<Sel extends Selection<CustomDataSourceConnectionConfiguration>>(selectorFn: (s: CustomDataSourceConnectionConfiguration) => [...Sel]):$Field<"connectionConfiguration", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new CustomDataSourceConnectionConfiguration)
      };
      return this.$_select("connectionConfiguration", options as any) as any
    }
  

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "CustomDataSource">  {
       return this.$_select("__typename") as any
      }
}


export class CustomDataSourceConnectionConfiguration extends $Base<"CustomDataSourceConnectionConfiguration"> {
  constructor() {
    super("CustomDataSourceConnectionConfiguration")
  }

  
      
      get clientId(): $Field<"clientId", string>  {
       return this.$_select("clientId") as any
      }

      
      get clientSecret(): $Field<"clientSecret", string>  {
       return this.$_select("clientSecret") as any
      }

      
      get endpoint(): $Field<"endpoint", URL>  {
       return this.$_select("endpoint") as any
      }

      
      get scope(): $Field<"scope", string>  {
       return this.$_select("scope") as any
      }

      
      get __typename(): $Field<"__typename", "CustomDataSourceConnectionConfiguration">  {
       return this.$_select("__typename") as any
      }
}


export class CustomField extends $Base<"CustomField"> {
  constructor() {
    super("CustomField")
  }

  
      
      field<Sel extends Selection<ISubjectCustomField>>(selectorFn: (s: ISubjectCustomField) => [...Sel]):$Field<"field", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ISubjectCustomField)
      };
      return this.$_select("field", options as any) as any
    }
  

      
      get positionIndex(): $Field<"positionIndex", number | null>  {
       return this.$_select("positionIndex") as any
      }

      
      get __typename(): $Field<"__typename", "CustomField">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class CustomFieldTypesConnection extends $Base<"CustomFieldTypesConnection"> {
  constructor() {
    super("CustomFieldTypesConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<CustomFieldTypesEdge>>(selectorFn: (s: CustomFieldTypesEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new CustomFieldTypesEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<IGQLFieldType>>(selectorFn: (s: IGQLFieldType) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IGQLFieldType)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "CustomFieldTypesConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class CustomFieldTypesEdge extends $Base<"CustomFieldTypesEdge"> {
  constructor() {
    super("CustomFieldTypesEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<IGQLFieldType>>(selectorFn: (s: IGQLFieldType) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IGQLFieldType)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "CustomFieldTypesEdge">  {
       return this.$_select("__typename") as any
      }
}


export class Dashboard extends $Base<"Dashboard"> {
  constructor() {
    super("Dashboard")
  }

  
      
      get assignedSections(): $Field<"assignedSections", Readonly<Array<Section>>>  {
       return this.$_select("assignedSections") as any
      }

      
      get forPortal(): $Field<"forPortal", boolean>  {
       return this.$_select("forPortal") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      items<Sel extends Selection<CollectionItem>>(selectorFn: (s: CollectionItem) => [...Sel]):$Field<"items", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new CollectionItem)
      };
      return this.$_select("items", options as any) as any
    }
  

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get tenantId(): $Field<"tenantId", Uuid>  {
       return this.$_select("tenantId") as any
      }

      
      get title(): $Field<"title", string>  {
       return this.$_select("title") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get widgetContextReferences(): $Field<"widgetContextReferences", Readonly<Array<ReferencedContext>>>  {
       return this.$_select("widgetContextReferences") as any
      }

      
      get __typename(): $Field<"__typename", "Dashboard">  {
       return this.$_select("__typename") as any
      }
}


export class DashboardItemSettings extends $Base<"DashboardItemSettings"> {
  constructor() {
    super("DashboardItemSettings")
  }

  
      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get positionIndex(): $Field<"positionIndex", number>  {
       return this.$_select("positionIndex") as any
      }

      
      get __typename(): $Field<"__typename", "DashboardItemSettings">  {
       return this.$_select("__typename") as any
      }
}


export class DataExtract extends $Base<"DataExtract"> {
  constructor() {
    super("DataExtract")
  }

  
      
      availableFields<Sel extends Selection<ISchemaParseResult>>(selectorFn: (s: ISchemaParseResult) => [...Sel]):$Field<"availableFields", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ISchemaParseResult)
      };
      return this.$_select("availableFields", options as any) as any
    }
  

      
      get createdAt(): $Field<"createdAt", DateTime>  {
       return this.$_select("createdAt") as any
      }

      
      dataSourceInstance<Sel extends Selection<DataSourceInstance>>(selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"dataSourceInstance", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("dataSourceInstance", options as any) as any
    }
  

      
      get dataSourceInstanceId(): $Field<"dataSourceInstanceId", Uuid>  {
       return this.$_select("dataSourceInstanceId") as any
      }

      
      get description(): $Field<"description", string | null>  {
       return this.$_select("description") as any
      }

      
      get extractJSON(): $Field<"extractJSON", JSON | null>  {
       return this.$_select("extractJSON") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get isCacheable(): $Field<"isCacheable", boolean>  {
       return this.$_select("isCacheable") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      subjectIdentifierConfiguration<Sel extends Selection<SubjectIdentifierConfiguration>>(selectorFn: (s: SubjectIdentifierConfiguration) => [...Sel]):$Field<"subjectIdentifierConfiguration", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectIdentifierConfiguration)
      };
      return this.$_select("subjectIdentifierConfiguration", options as any) as any
    }
  

      
      tenantDataSource<Sel extends Selection<DataSourceInstanceDto>>(selectorFn: (s: DataSourceInstanceDto) => [...Sel]):$Field<"tenantDataSource", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourceInstanceDto)
      };
      return this.$_select("tenantDataSource", options as any) as any
    }
  

      
      get title(): $Field<"title", string>  {
       return this.$_select("title") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "DataExtract">  {
       return this.$_select("__typename") as any
      }
}


export class DataExtractDimension extends $Base<"DataExtractDimension"> {
  constructor() {
    super("DataExtractDimension")
  }

  
      
      get dataExtractId(): $Field<"dataExtractId", Uuid>  {
       return this.$_select("dataExtractId") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "DataExtractDimension">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class DataExtractsConnection extends $Base<"DataExtractsConnection"> {
  constructor() {
    super("DataExtractsConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<DataExtractsEdge>>(selectorFn: (s: DataExtractsEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataExtractsEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<DataExtract>>(selectorFn: (s: DataExtract) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataExtract)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "DataExtractsConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class DataExtractsEdge extends $Base<"DataExtractsEdge"> {
  constructor() {
    super("DataExtractsEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<DataExtract>>(selectorFn: (s: DataExtract) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataExtract)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "DataExtractsEdge">  {
       return this.$_select("__typename") as any
      }
}


export class DataOrdering extends $Base<"DataOrdering"> {
  constructor() {
    super("DataOrdering")
  }

  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get orderDirection(): $Field<"orderDirection", ListSortDirection>  {
       return this.$_select("orderDirection") as any
      }

      
      get __typename(): $Field<"__typename", "DataOrdering">  {
       return this.$_select("__typename") as any
      }
}


export class DataPointFilter extends $Base<"DataPointFilter"> {
  constructor() {
    super("DataPointFilter")
  }

  
      
      and<Sel extends Selection<OrFilter>>(selectorFn: (s: OrFilter) => [...Sel]):$Field<"and", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new OrFilter)
      };
      return this.$_select("and", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "DataPointFilter">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class DataPointsConnection extends $Base<"DataPointsConnection"> {
  constructor() {
    super("DataPointsConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<DataPointsEdge>>(selectorFn: (s: DataPointsEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataPointsEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<FormattedDataPoint>>(selectorFn: (s: FormattedDataPoint) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FormattedDataPoint)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "DataPointsConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class DataPointsEdge extends $Base<"DataPointsEdge"> {
  constructor() {
    super("DataPointsEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<FormattedDataPoint>>(selectorFn: (s: FormattedDataPoint) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FormattedDataPoint)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "DataPointsEdge">  {
       return this.$_select("__typename") as any
      }
}


export class DataSourceBasicMetadata extends $Base<"DataSourceBasicMetadata"> {
  constructor() {
    super("DataSourceBasicMetadata")
  }

  
      
      configurationOptions<Sel extends Selection<ConfigurationOption>>(selectorFn: (s: ConfigurationOption) => [...Sel]):$Field<"configurationOptions", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationOption)
      };
      return this.$_select("configurationOptions", options as any) as any
    }
  

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get singleton(): $Field<"singleton", boolean>  {
       return this.$_select("singleton") as any
      }

      
      get __typename(): $Field<"__typename", "DataSourceBasicMetadata">  {
       return this.$_select("__typename") as any
      }
}


export class DataSourceCapabilityMetadata extends $Base<"DataSourceCapabilityMetadata"> {
  constructor() {
    super("DataSourceCapabilityMetadata")
  }

  
      
      get capabilities(): $Field<"capabilities", Readonly<Array<DataSourceCapability>>>  {
       return this.$_select("capabilities") as any
      }

      
      capabilitiesMetadata<Sel extends Selection<ICapabilityMetadata>>(selectorFn: (s: ICapabilityMetadata) => [...Sel]):$Field<"capabilitiesMetadata", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ICapabilityMetadata)
      };
      return this.$_select("capabilitiesMetadata", options as any) as any
    }
  

      
      providesCapability<Args extends VariabledInput<{
        capability: DataSourceCapability,
      }>>(args: ExactArgNames<Args, {
        capability: DataSourceCapability,
      }>):$Field<"providesCapability", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              capability: "DataSourceCapability!"
            },
        args,

        
      };
      return this.$_select("providesCapability", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "DataSourceCapabilityMetadata">  {
       return this.$_select("__typename") as any
      }
}


export class DataSourceInstance extends $Base<"DataSourceInstance"> {
  constructor() {
    super("DataSourceInstance")
  }

  
      
      capabilityMetadata<Sel extends Selection<DataSourceCapabilityMetadata>>(selectorFn: (s: DataSourceCapabilityMetadata) => [...Sel]):$Field<"capabilityMetadata", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourceCapabilityMetadata)
      };
      return this.$_select("capabilityMetadata", options as any) as any
    }
  

      
      configuration<Sel extends Selection<ConfigurationValue>>(selectorFn: (s: ConfigurationValue) => [...Sel]):$Field<"configuration", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationValue)
      };
      return this.$_select("configuration", options as any) as any
    }
  

      
      dataSource<Sel extends Selection<IDataSource>>(selectorFn: (s: IDataSource) => [...Sel]):$Field<"dataSource", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataSource)
      };
      return this.$_select("dataSource", options as any) as any
    }
  

      
      get healthCheckJobId(): $Field<"healthCheckJobId", string>  {
       return this.$_select("healthCheckJobId") as any
      }

      
      get healthStatus(): $Field<"healthStatus", HealthStatus | null>  {
       return this.$_select("healthStatus") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get isUsed(): $Field<"isUsed", boolean>  {
       return this.$_select("isUsed") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get lastHealthyAt(): $Field<"lastHealthyAt", DateTime | null>  {
       return this.$_select("lastHealthyAt") as any
      }

      
      metadata<Sel extends Selection<DataSourceBasicMetadata>>(selectorFn: (s: DataSourceBasicMetadata) => [...Sel]):$Field<"metadata", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourceBasicMetadata)
      };
      return this.$_select("metadata", options as any) as any
    }
  

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      structuredDataProvider<Sel extends Selection<StructuredDataProviderType>>(selectorFn: (s: StructuredDataProviderType) => [...Sel]):$Field<"structuredDataProvider", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuredDataProviderType)
      };
      return this.$_select("structuredDataProvider", options as any) as any
    }
  

      
      subjectProvider<Sel extends Selection<SubjectProviderType>>(selectorFn: (s: SubjectProviderType) => [...Sel]):$Field<"subjectProvider", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectProviderType)
      };
      return this.$_select("subjectProvider", options as any) as any
    }
  

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "DataSourceInstance">  {
       return this.$_select("__typename") as any
      }
}


export class DataSourceInstanceDto extends $Base<"DataSourceInstanceDto"> {
  constructor() {
    super("DataSourceInstanceDto")
  }

  
      
      configuration<Sel extends Selection<ConfigurationValue>>(selectorFn: (s: ConfigurationValue) => [...Sel]):$Field<"configuration", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationValue)
      };
      return this.$_select("configuration", options as any) as any
    }
  

      
      dataSource<Sel extends Selection<IDataSource>>(selectorFn: (s: IDataSource) => [...Sel]):$Field<"dataSource", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataSource)
      };
      return this.$_select("dataSource", options as any) as any
    }
  

      
      get healthCheckJobId(): $Field<"healthCheckJobId", string>  {
       return this.$_select("healthCheckJobId") as any
      }

      
      get healthStatus(): $Field<"healthStatus", HealthStatus | null>  {
       return this.$_select("healthStatus") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get lastHealthyAt(): $Field<"lastHealthyAt", DateTime | null>  {
       return this.$_select("lastHealthyAt") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "DataSourceInstanceDto">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class DataSourceInstancesConnection extends $Base<"DataSourceInstancesConnection"> {
  constructor() {
    super("DataSourceInstancesConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<DataSourceInstancesEdge>>(selectorFn: (s: DataSourceInstancesEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourceInstancesEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<DataSourceInstance>>(selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "DataSourceInstancesConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class DataSourceInstancesEdge extends $Base<"DataSourceInstancesEdge"> {
  constructor() {
    super("DataSourceInstancesEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<DataSourceInstance>>(selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "DataSourceInstancesEdge">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class DataSourcesConnection extends $Base<"DataSourcesConnection"> {
  constructor() {
    super("DataSourcesConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<DataSourcesEdge>>(selectorFn: (s: DataSourcesEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourcesEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<IDataSource>>(selectorFn: (s: IDataSource) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataSource)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "DataSourcesConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class DataSourcesEdge extends $Base<"DataSourcesEdge"> {
  constructor() {
    super("DataSourcesEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<IDataSource>>(selectorFn: (s: IDataSource) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataSource)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "DataSourcesEdge">  {
       return this.$_select("__typename") as any
      }
}


export class DateDataTypeValue extends $Base<"DateDataTypeValue"> {
  constructor() {
    super("DateDataTypeValue")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      get value(): $Field<"value", DateOnly | null>  {
       return this.$_select("value") as any
      }

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "DateDataTypeValue">  {
       return this.$_select("__typename") as any
      }
}


export class DateDataTypeValueFilter extends $Base<"DateDataTypeValueFilter"> {
  constructor() {
    super("DateDataTypeValueFilter")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get dateValue(): $Field<"dateValue", DateOnly | null>  {
       return this.$_select("dateValue") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }

      
      get numericComparator(): $Field<"numericComparator", NumericComparator>  {
       return this.$_select("numericComparator") as any
      }

      
      get __typename(): $Field<"__typename", "DateDataTypeValueFilter">  {
       return this.$_select("__typename") as any
      }
}


export class DateTimeDataTypeValue extends $Base<"DateTimeDataTypeValue"> {
  constructor() {
    super("DateTimeDataTypeValue")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      get value(): $Field<"value", DateTime | null>  {
       return this.$_select("value") as any
      }

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "DateTimeDataTypeValue">  {
       return this.$_select("__typename") as any
      }
}


export class DateTimeDataTypeValueFilter extends $Base<"DateTimeDataTypeValueFilter"> {
  constructor() {
    super("DateTimeDataTypeValueFilter")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get dateTimeValue(): $Field<"dateTimeValue", DateTime | null>  {
       return this.$_select("dateTimeValue") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }

      
      get numericComparator(): $Field<"numericComparator", NumericComparator>  {
       return this.$_select("numericComparator") as any
      }

      
      get __typename(): $Field<"__typename", "DateTimeDataTypeValueFilter">  {
       return this.$_select("__typename") as any
      }
}


export class DecimalDataTypeValue extends $Base<"DecimalDataTypeValue"> {
  constructor() {
    super("DecimalDataTypeValue")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      get value(): $Field<"value", number | null>  {
       return this.$_select("value") as any
      }

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "DecimalDataTypeValue">  {
       return this.$_select("__typename") as any
      }
}


export class DecimalDataTypeValueFilter extends $Base<"DecimalDataTypeValueFilter"> {
  constructor() {
    super("DecimalDataTypeValueFilter")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get decimalValue(): $Field<"decimalValue", number | null>  {
       return this.$_select("decimalValue") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }

      
      get numericComparator(): $Field<"numericComparator", NumericComparator>  {
       return this.$_select("numericComparator") as any
      }

      
      get __typename(): $Field<"__typename", "DecimalDataTypeValueFilter">  {
       return this.$_select("__typename") as any
      }
}


export class DisplayAction extends $Base<"DisplayAction"> {
  constructor() {
    super("DisplayAction")
  }

  
      
      iFrame<Sel extends Selection<IFrame>>(selectorFn: (s: IFrame) => [...Sel]):$Field<"iFrame", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IFrame)
      };
      return this.$_select("iFrame", options as any) as any
    }
  

      
      get isPrimary(): $Field<"isPrimary", boolean>  {
       return this.$_select("isPrimary") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get type(): $Field<"type", DataPointActionType>  {
       return this.$_select("type") as any
      }

      
      get __typename(): $Field<"__typename", "DisplayAction">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class EntriesConnection extends $Base<"EntriesConnection"> {
  constructor() {
    super("EntriesConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<EntriesEdge>>(selectorFn: (s: EntriesEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new EntriesEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<MultiSubjectViewEntry>>(selectorFn: (s: MultiSubjectViewEntry) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new MultiSubjectViewEntry)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "EntriesConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class EntriesEdge extends $Base<"EntriesEdge"> {
  constructor() {
    super("EntriesEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<MultiSubjectViewEntry>>(selectorFn: (s: MultiSubjectViewEntry) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new MultiSubjectViewEntry)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "EntriesEdge">  {
       return this.$_select("__typename") as any
      }
}


export class FailedFilter extends $Base<"FailedFilter"> {
  constructor() {
    super("FailedFilter")
  }

  
      
      get fieldKey(): $Field<"fieldKey", string>  {
       return this.$_select("fieldKey") as any
      }

      
      get visualisationId(): $Field<"visualisationId", Uuid>  {
       return this.$_select("visualisationId") as any
      }

      
      get __typename(): $Field<"__typename", "FailedFilter">  {
       return this.$_select("__typename") as any
      }
}


export class FieldMetadata extends $Base<"FieldMetadata"> {
  constructor() {
    super("FieldMetadata")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "FieldMetadata">  {
       return this.$_select("__typename") as any
      }
}


export class FilterResult extends $Base<"FilterResult"> {
  constructor() {
    super("FilterResult")
  }

  
      
      failedFilters<Sel extends Selection<FailedFilter>>(selectorFn: (s: FailedFilter) => [...Sel]):$Field<"failedFilters", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FailedFilter)
      };
      return this.$_select("failedFilters", options as any) as any
    }
  

      
      resolvedLiquidValues<Sel extends Selection<ResolvedLiquidFilterValue>>(selectorFn: (s: ResolvedLiquidFilterValue) => [...Sel]):$Field<"resolvedLiquidValues", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ResolvedLiquidFilterValue)
      };
      return this.$_select("resolvedLiquidValues", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "FilterResult">  {
       return this.$_select("__typename") as any
      }
}


export class FilterValidationResult extends $Base<"FilterValidationResult"> {
  constructor() {
    super("FilterValidationResult")
  }

  
      
      definition<Sel extends Selection<LiquidFilterDefinition>>(selectorFn: (s: LiquidFilterDefinition) => [...Sel]):$Field<"definition", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new LiquidFilterDefinition)
      };
      return this.$_select("definition", options as any) as any
    }
  

      
      get error(): $Field<"error", string | null>  {
       return this.$_select("error") as any
      }

      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      get __typename(): $Field<"__typename", "FilterValidationResult">  {
       return this.$_select("__typename") as any
      }
}


export class FormInstance extends $Base<"FormInstance"> {
  constructor() {
    super("FormInstance")
  }

  
      
      get editLink(): $Field<"editLink", string>  {
       return this.$_select("editLink") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      subject<Sel extends Selection<Subject>>(selectorFn: (s: Subject) => [...Sel]):$Field<"subject", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Subject)
      };
      return this.$_select("subject", options as any) as any
    }
  

      
      get title(): $Field<"title", string>  {
       return this.$_select("title") as any
      }

      
      get viewLink(): $Field<"viewLink", string>  {
       return this.$_select("viewLink") as any
      }

      
      get __typename(): $Field<"__typename", "FormInstance">  {
       return this.$_select("__typename") as any
      }
}


export class FormattedDataPoint extends $Base<"FormattedDataPoint"> {
  constructor() {
    super("FormattedDataPoint")
  }

  
      
      actions<Sel extends Selection<IAction>>(selectorFn: (s: IAction) => [...Sel]):$Field<"actions", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IAction)
      };
      return this.$_select("actions", options as any) as any
    }
  

      
      get dataExtractIds(): $Field<"dataExtractIds", Readonly<Array<Uuid>>>  {
       return this.$_select("dataExtractIds") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      items<Sel extends Selection<FormattedDataPointItem>>(selectorFn: (s: FormattedDataPointItem) => [...Sel]):$Field<"items", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FormattedDataPointItem)
      };
      return this.$_select("items", options as any) as any
    }
  

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get sourceDataPointId(): $Field<"sourceDataPointId", string | null>  {
       return this.$_select("sourceDataPointId") as any
      }

      
      get subjectId(): $Field<"subjectId", Uuid | null>  {
       return this.$_select("subjectId") as any
      }

      
      get __typename(): $Field<"__typename", "FormattedDataPoint">  {
       return this.$_select("__typename") as any
      }
}


export class FormattedDataPointItem extends $Base<"FormattedDataPointItem"> {
  constructor() {
    super("FormattedDataPointItem")
  }

  
      
      get displayValue(): $Field<"displayValue", string | null>  {
       return this.$_select("displayValue") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      value<Sel extends Selection<IDataTypeValue>>(selectorFn: (s: IDataTypeValue) => [...Sel]):$Field<"value", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataTypeValue)
      };
      return this.$_select("value", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "FormattedDataPointItem">  {
       return this.$_select("__typename") as any
      }
}


export class FrameChangeResult extends $Base<"FrameChangeResult"> {
  constructor() {
    super("FrameChangeResult")
  }

  
      
      get tenantKey(): $Field<"tenantKey", string>  {
       return this.$_select("tenantKey") as any
      }

      
      get __typename(): $Field<"__typename", "FrameChangeResult">  {
       return this.$_select("__typename") as any
      }
}


export class GQLFeatureSet extends $Base<"GQLFeatureSet"> {
  constructor() {
    super("GQLFeatureSet")
  }

  
      
      get disabledFeatures(): $Field<"disabledFeatures", Readonly<Array<string>>>  {
       return this.$_select("disabledFeatures") as any
      }

      
      get enabledFeatures(): $Field<"enabledFeatures", Readonly<Array<string>>>  {
       return this.$_select("enabledFeatures") as any
      }

      
      get __typename(): $Field<"__typename", "GQLFeatureSet">  {
       return this.$_select("__typename") as any
      }
}


export class GetDataPointsType extends $Base<"GetDataPointsType"> {
  constructor() {
    super("GetDataPointsType")
  }

  
      
      dataPoints<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<DataPointsConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: DataPointsConnection) => [...Sel]):$Field<"dataPoints", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
dataPoints<Sel extends Selection<DataPointsConnection>>(selectorFn: (s: DataPointsConnection) => [...Sel]):$Field<"dataPoints", GetOutput<Sel> | undefined , GetVariables<Sel>>
dataPoints(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new DataPointsConnection)
      };
      return this.$_select("dataPoints", options as any) as any
    }
  

      
      filterResult<Sel extends Selection<FilterResult>>(selectorFn: (s: FilterResult) => [...Sel]):$Field<"filterResult", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FilterResult)
      };
      return this.$_select("filterResult", options as any) as any
    }
  

      
      get lastPossibleUpdate(): $Field<"lastPossibleUpdate", DateTime>  {
       return this.$_select("lastPossibleUpdate") as any
      }

      
      get updatePending(): $Field<"updatePending", boolean>  {
       return this.$_select("updatePending") as any
      }

      
      get __typename(): $Field<"__typename", "GetDataPointsType">  {
       return this.$_select("__typename") as any
      }
}


export class GetRenderedHtmlResult extends $Base<"GetRenderedHtmlResult"> {
  constructor() {
    super("GetRenderedHtmlResult")
  }

  
      
      get isPending(): $Field<"isPending", boolean>  {
       return this.$_select("isPending") as any
      }

      
      get lastPossibleUpdate(): $Field<"lastPossibleUpdate", DateTime>  {
       return this.$_select("lastPossibleUpdate") as any
      }

      
      get result(): $Field<"result", string | null>  {
       return this.$_select("result") as any
      }

      
      get __typename(): $Field<"__typename", "GetRenderedHtmlResult">  {
       return this.$_select("__typename") as any
      }
}


export class GraphChartDisplayOption extends $Base<"GraphChartDisplayOption"> {
  constructor() {
    super("GraphChartDisplayOption")
  }

  
      
      liquidColourFormatting<Sel extends Selection<KeyValuePairOfStringAndString>>(selectorFn: (s: KeyValuePairOfStringAndString) => [...Sel]):$Field<"liquidColourFormatting", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new KeyValuePairOfStringAndString)
      };
      return this.$_select("liquidColourFormatting", options as any) as any
    }
  

      
      get visualisationType(): $Field<"visualisationType", VisualisationType>  {
       return this.$_select("visualisationType") as any
      }

      
      get __typename(): $Field<"__typename", "GraphChartDisplayOption">  {
       return this.$_select("__typename") as any
      }
}


export class Group extends $Base<"Group"> {
  constructor() {
    super("Group")
  }

  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      structuralEntities<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<StructuralEntitiesConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: StructuralEntitiesConnection) => [...Sel]):$Field<"structuralEntities", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
structuralEntities<Sel extends Selection<StructuralEntitiesConnection>>(selectorFn: (s: StructuralEntitiesConnection) => [...Sel]):$Field<"structuralEntities", GetOutput<Sel> | undefined , GetVariables<Sel>>
structuralEntities(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new StructuralEntitiesConnection)
      };
      return this.$_select("structuralEntities", options as any) as any
    }
  

      
      get structuralEntityKeys(): $Field<"structuralEntityKeys", Readonly<Array<string>>>  {
       return this.$_select("structuralEntityKeys") as any
      }

      
      get __typename(): $Field<"__typename", "Group">  {
       return this.$_select("__typename") as any
      }
}


export class GroupDefinition extends $Base<"GroupDefinition"> {
  constructor() {
    super("GroupDefinition")
  }

  
      
      aggregators<Sel extends Selection<AggregateDefinition>>(selectorFn: (s: AggregateDefinition) => [...Sel]):$Field<"aggregators", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AggregateDefinition)
      };
      return this.$_select("aggregators", options as any) as any
    }
  

      
      get groupByKeys(): $Field<"groupByKeys", Readonly<Array<string>>>  {
       return this.$_select("groupByKeys") as any
      }

      
      get __typename(): $Field<"__typename", "GroupDefinition">  {
       return this.$_select("__typename") as any
      }
}


export class GroupStageValidationResult extends $Base<"GroupStageValidationResult"> {
  constructor() {
    super("GroupStageValidationResult")
  }

  
      
      get error(): $Field<"error", string | null>  {
       return this.$_select("error") as any
      }

      
      get groupingValid(): $Field<"groupingValid", boolean>  {
       return this.$_select("groupingValid") as any
      }

      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      outputs<Sel extends Selection<TypedField>>(selectorFn: (s: TypedField) => [...Sel]):$Field<"outputs", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TypedField)
      };
      return this.$_select("outputs", options as any) as any
    }
  

      
      steps<Sel extends Selection<StepValidationResult>>(selectorFn: (s: StepValidationResult) => [...Sel]):$Field<"steps", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StepValidationResult)
      };
      return this.$_select("steps", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "GroupStageValidationResult">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class GroupsConnection extends $Base<"GroupsConnection"> {
  constructor() {
    super("GroupsConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<GroupsEdge>>(selectorFn: (s: GroupsEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new GroupsEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<Group>>(selectorFn: (s: Group) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Group)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "GroupsConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class GroupsEdge extends $Base<"GroupsEdge"> {
  constructor() {
    super("GroupsEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<Group>>(selectorFn: (s: Group) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Group)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "GroupsEdge">  {
       return this.$_select("__typename") as any
      }
}


export class IFrame extends $Base<"IFrame"> {
  constructor() {
    super("IFrame")
  }

  
      
      get sendToken(): $Field<"sendToken", boolean>  {
       return this.$_select("sendToken") as any
      }

      
      get url(): $Field<"url", URL>  {
       return this.$_select("url") as any
      }

      
      get __typename(): $Field<"__typename", "IFrame">  {
       return this.$_select("__typename") as any
      }
}


export class IFrameWidget extends $Base<"IFrameWidget"> {
  constructor() {
    super("IFrameWidget")
  }

  
      
      get forPortal(): $Field<"forPortal", boolean>  {
       return this.$_select("forPortal") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get liquidExpressions(): $Field<"liquidExpressions", Readonly<Array<string>>>  {
       return this.$_select("liquidExpressions") as any
      }

      
      get referencedContexts(): $Field<"referencedContexts", Readonly<Array<ReferencedContext>>>  {
       return this.$_select("referencedContexts") as any
      }

      
      renderedUri<Args extends VariabledInput<{
        context: WidgetContextInput,
      }>>(args: ExactArgNames<Args, {
        context: WidgetContextInput,
      }>):$Field<"renderedUri", string | null , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              context: "WidgetContextInput!"
            },
        args,

        
      };
      return this.$_select("renderedUri", options as any) as any
    }
  

      
      get sendBearerAsAQueryParameter(): $Field<"sendBearerAsAQueryParameter", boolean>  {
       return this.$_select("sendBearerAsAQueryParameter") as any
      }

      
      get title(): $Field<"title", string>  {
       return this.$_select("title") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get uri(): $Field<"uri", string>  {
       return this.$_select("uri") as any
      }

      
      get __typename(): $Field<"__typename", "IFrameWidget">  {
       return this.$_select("__typename") as any
      }
}


export class InboundSubjectConfiguration extends $Base<"InboundSubjectConfiguration"> {
  constructor() {
    super("InboundSubjectConfiguration")
  }

  
      
      get existingSubjectOverrideLocationBehaviour(): $Field<"existingSubjectOverrideLocationBehaviour", OverrideLocationBehaviour>  {
       return this.$_select("existingSubjectOverrideLocationBehaviour") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      matchingFields<Sel extends Selection<SubjectInputField>>(selectorFn: (s: SubjectInputField) => [...Sel]):$Field<"matchingFields", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectInputField)
      };
      return this.$_select("matchingFields", options as any) as any
    }
  

      
      get newSubjectOverrideLocationBehaviour(): $Field<"newSubjectOverrideLocationBehaviour", OverrideLocationBehaviour>  {
       return this.$_select("newSubjectOverrideLocationBehaviour") as any
      }

      
      tenant<Sel extends Selection<Tenant>>(selectorFn: (s: Tenant) => [...Sel]):$Field<"tenant", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Tenant)
      };
      return this.$_select("tenant", options as any) as any
    }
  

      
      toDTO<Sel extends Selection<InboundSubjectConfigurationDTO>>(selectorFn: (s: InboundSubjectConfigurationDTO) => [...Sel]):$Field<"toDTO", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new InboundSubjectConfigurationDTO)
      };
      return this.$_select("toDTO", options as any) as any
    }
  

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      unmatchedStructuralEntity<Sel extends Selection<StructuralEntity>>(selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"unmatchedStructuralEntity", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("unmatchedStructuralEntity", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "InboundSubjectConfiguration">  {
       return this.$_select("__typename") as any
      }
}


export class InboundSubjectConfigurationDTO extends $Base<"InboundSubjectConfigurationDTO"> {
  constructor() {
    super("InboundSubjectConfigurationDTO")
  }

  
      
      get existingSubjectOverrideLocationBehaviour(): $Field<"existingSubjectOverrideLocationBehaviour", OverrideLocationBehaviour>  {
       return this.$_select("existingSubjectOverrideLocationBehaviour") as any
      }

      
      matchingFields<Sel extends Selection<SubjectInputField>>(selectorFn: (s: SubjectInputField) => [...Sel]):$Field<"matchingFields", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectInputField)
      };
      return this.$_select("matchingFields", options as any) as any
    }
  

      
      get newSubjectOverrideLocationBehaviour(): $Field<"newSubjectOverrideLocationBehaviour", OverrideLocationBehaviour>  {
       return this.$_select("newSubjectOverrideLocationBehaviour") as any
      }

      
      tenant<Sel extends Selection<Tenant>>(selectorFn: (s: Tenant) => [...Sel]):$Field<"tenant", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Tenant)
      };
      return this.$_select("tenant", options as any) as any
    }
  

      
      get unmatchedStructuralEntityKey(): $Field<"unmatchedStructuralEntityKey", string>  {
       return this.$_select("unmatchedStructuralEntityKey") as any
      }

      
      get __typename(): $Field<"__typename", "InboundSubjectConfigurationDTO">  {
       return this.$_select("__typename") as any
      }
}


export class InputDimension extends $Base<"InputDimension"> {
  constructor() {
    super("InputDimension")
  }

  
      
      collatedDimensions<Sel extends Selection<DataExtractDimension>>(selectorFn: (s: DataExtractDimension) => [...Sel]):$Field<"collatedDimensions", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataExtractDimension)
      };
      return this.$_select("collatedDimensions", options as any) as any
    }
  

      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "InputDimension">  {
       return this.$_select("__typename") as any
      }
}


export class InputField extends $Base<"InputField"> {
  constructor() {
    super("InputField")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isArray(): $Field<"isArray", boolean>  {
       return this.$_select("isArray") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get required(): $Field<"required", boolean>  {
       return this.$_select("required") as any
      }

      
      type<Sel extends Selection<IGQLFieldType>>(selectorFn: (s: IGQLFieldType) => [...Sel]):$Field<"type", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IGQLFieldType)
      };
      return this.$_select("type", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "InputField">  {
       return this.$_select("__typename") as any
      }
}


export class InputFieldValue extends $Base<"InputFieldValue"> {
  constructor() {
    super("InputFieldValue")
  }

  
      
      field<Sel extends Selection<InputField>>(selectorFn: (s: InputField) => [...Sel]):$Field<"field", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new InputField)
      };
      return this.$_select("field", options as any) as any
    }
  

      
      get hasValue(): $Field<"hasValue", boolean>  {
       return this.$_select("hasValue") as any
      }

      
      get stringValue(): $Field<"stringValue", string | null>  {
       return this.$_select("stringValue") as any
      }

      
      value<Sel extends Selection<IDataTypeValue>>(selectorFn: (s: IDataTypeValue) => [...Sel]):$Field<"value", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataTypeValue)
      };
      return this.$_select("value", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "InputFieldValue">  {
       return this.$_select("__typename") as any
      }
}


export class IntegerDataTypeValue extends $Base<"IntegerDataTypeValue"> {
  constructor() {
    super("IntegerDataTypeValue")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      get value(): $Field<"value", number | null>  {
       return this.$_select("value") as any
      }

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "IntegerDataTypeValue">  {
       return this.$_select("__typename") as any
      }
}


export class IntegerDataTypeValueFilter extends $Base<"IntegerDataTypeValueFilter"> {
  constructor() {
    super("IntegerDataTypeValueFilter")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get integerValue(): $Field<"integerValue", number | null>  {
       return this.$_select("integerValue") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }

      
      get numericComparator(): $Field<"numericComparator", NumericComparator>  {
       return this.$_select("numericComparator") as any
      }

      
      get __typename(): $Field<"__typename", "IntegerDataTypeValueFilter">  {
       return this.$_select("__typename") as any
      }
}


export class InvalidLiquidParseResult extends $Base<"InvalidLiquidParseResult"> {
  constructor() {
    super("InvalidLiquidParseResult")
  }

  
      
      get errorMessage(): $Field<"errorMessage", string>  {
       return this.$_select("errorMessage") as any
      }

      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      get __typename(): $Field<"__typename", "InvalidLiquidParseResult">  {
       return this.$_select("__typename") as any
      }
}


export class InvalidSchemaParseResult extends $Base<"InvalidSchemaParseResult"> {
  constructor() {
    super("InvalidSchemaParseResult")
  }

  
      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      get __typename(): $Field<"__typename", "InvalidSchemaParseResult">  {
       return this.$_select("__typename") as any
      }
}


export class InviteToSubjectPortalPayload extends $Base<"InviteToSubjectPortalPayload"> {
  constructor() {
    super("InviteToSubjectPortalPayload")
  }

  
      
      userPortalAccessInviteResult<Sel extends Selection<UserPortalAccessInviteResult>>(selectorFn: (s: UserPortalAccessInviteResult) => [...Sel]):$Field<"userPortalAccessInviteResult", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new UserPortalAccessInviteResult)
      };
      return this.$_select("userPortalAccessInviteResult", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "InviteToSubjectPortalPayload">  {
       return this.$_select("__typename") as any
      }
}


export class KeyValuePairOfStringAndString extends $Base<"KeyValuePairOfStringAndString"> {
  constructor() {
    super("KeyValuePairOfStringAndString")
  }

  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get value(): $Field<"value", string | null>  {
       return this.$_select("value") as any
      }

      
      get __typename(): $Field<"__typename", "KeyValuePairOfStringAndString">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class LinkedPortalUsersConnection extends $Base<"LinkedPortalUsersConnection"> {
  constructor() {
    super("LinkedPortalUsersConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<LinkedPortalUsersEdge>>(selectorFn: (s: LinkedPortalUsersEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new LinkedPortalUsersEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<SubjectAccessUser>>(selectorFn: (s: SubjectAccessUser) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectAccessUser)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "LinkedPortalUsersConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class LinkedPortalUsersEdge extends $Base<"LinkedPortalUsersEdge"> {
  constructor() {
    super("LinkedPortalUsersEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<SubjectAccessUser>>(selectorFn: (s: SubjectAccessUser) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectAccessUser)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "LinkedPortalUsersEdge">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class LinkedSubjectsConnection extends $Base<"LinkedSubjectsConnection"> {
  constructor() {
    super("LinkedSubjectsConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<LinkedSubjectsEdge>>(selectorFn: (s: LinkedSubjectsEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new LinkedSubjectsEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<Subject>>(selectorFn: (s: Subject) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Subject)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "LinkedSubjectsConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class LinkedSubjectsEdge extends $Base<"LinkedSubjectsEdge"> {
  constructor() {
    super("LinkedSubjectsEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<Subject>>(selectorFn: (s: Subject) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Subject)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "LinkedSubjectsEdge">  {
       return this.$_select("__typename") as any
      }
}


export class LiquidDisplayOption extends $Base<"LiquidDisplayOption"> {
  constructor() {
    super("LiquidDisplayOption")
  }

  
      
      get liquidHtml(): $Field<"liquidHtml", string>  {
       return this.$_select("liquidHtml") as any
      }

      
      renderHtml<Args extends VariabledInput<{
        context: WidgetContextInput,
      }>,Sel extends Selection<GetRenderedHtmlResult>>(args: ExactArgNames<Args, {
        context: WidgetContextInput,
      }>, selectorFn: (s: GetRenderedHtmlResult) => [...Sel]):$Field<"renderHtml", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              context: "WidgetContextInput!"
            },
        args,

        selection: selectorFn(new GetRenderedHtmlResult)
      };
      return this.$_select("renderHtml", options as any) as any
    }
  

      
      get visualisationType(): $Field<"visualisationType", VisualisationType>  {
       return this.$_select("visualisationType") as any
      }

      
      get __typename(): $Field<"__typename", "LiquidDisplayOption">  {
       return this.$_select("__typename") as any
      }
}


export class LiquidFilterDefinition extends $Base<"LiquidFilterDefinition"> {
  constructor() {
    super("LiquidFilterDefinition")
  }

  
      
      get expression(): $Field<"expression", string>  {
       return this.$_select("expression") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get __typename(): $Field<"__typename", "LiquidFilterDefinition">  {
       return this.$_select("__typename") as any
      }
}


export class LiquidMappingDefinition extends $Base<"LiquidMappingDefinition"> {
  constructor() {
    super("LiquidMappingDefinition")
  }

  
      
      get expression(): $Field<"expression", string>  {
       return this.$_select("expression") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get outputKey(): $Field<"outputKey", string>  {
       return this.$_select("outputKey") as any
      }

      
      get __typename(): $Field<"__typename", "LiquidMappingDefinition">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class LocationsConnection extends $Base<"LocationsConnection"> {
  constructor() {
    super("LocationsConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<LocationsEdge>>(selectorFn: (s: LocationsEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new LocationsEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<StructuralEntity>>(selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "LocationsConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class LocationsEdge extends $Base<"LocationsEdge"> {
  constructor() {
    super("LocationsEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<StructuralEntity>>(selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "LocationsEdge">  {
       return this.$_select("__typename") as any
      }
}


export class MultiSubjectViewColumn extends $Base<"MultiSubjectViewColumn"> {
  constructor() {
    super("MultiSubjectViewColumn")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get displayName(): $Field<"displayName", string>  {
       return this.$_select("displayName") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "MultiSubjectViewColumn">  {
       return this.$_select("__typename") as any
      }
}


export class MultiSubjectViewEntry extends $Base<"MultiSubjectViewEntry"> {
  constructor() {
    super("MultiSubjectViewEntry")
  }

  
      
      dataPointItems<Sel extends Selection<FormattedDataPointItem>>(selectorFn: (s: FormattedDataPointItem) => [...Sel]):$Field<"dataPointItems", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FormattedDataPointItem)
      };
      return this.$_select("dataPointItems", options as any) as any
    }
  

      
      subject<Sel extends Selection<Subject>>(selectorFn: (s: Subject) => [...Sel]):$Field<"subject", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Subject)
      };
      return this.$_select("subject", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "MultiSubjectViewEntry">  {
       return this.$_select("__typename") as any
      }
}


export class MultiSubjectViewRequest extends $Base<"MultiSubjectViewRequest"> {
  constructor() {
    super("MultiSubjectViewRequest")
  }

  
      
      columns<Sel extends Selection<MultiSubjectViewColumn>>(selectorFn: (s: MultiSubjectViewColumn) => [...Sel]):$Field<"columns", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new MultiSubjectViewColumn)
      };
      return this.$_select("columns", options as any) as any
    }
  

      
      entries<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<EntriesConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: EntriesConnection) => [...Sel]):$Field<"entries", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
entries<Sel extends Selection<EntriesConnection>>(selectorFn: (s: EntriesConnection) => [...Sel]):$Field<"entries", GetOutput<Sel> | undefined , GetVariables<Sel>>
entries(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new EntriesConnection)
      };
      return this.$_select("entries", options as any) as any
    }
  

      
      filterResult<Sel extends Selection<FilterResult>>(selectorFn: (s: FilterResult) => [...Sel]):$Field<"filterResult", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FilterResult)
      };
      return this.$_select("filterResult", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "MultiSubjectViewRequest">  {
       return this.$_select("__typename") as any
      }
}


export class MultipleChoiceFieldType extends $Base<"MultipleChoiceFieldType"> {
  constructor() {
    super("MultipleChoiceFieldType")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get key(): $Field<"key", FieldTypeKey>  {
       return this.$_select("key") as any
      }

      
      get options(): $Field<"options", Readonly<Array<string>>>  {
       return this.$_select("options") as any
      }

      
      get __typename(): $Field<"__typename", "MultipleChoiceFieldType">  {
       return this.$_select("__typename") as any
      }
}


export class Mutation extends $Base<"Mutation"> {
  constructor() {
    super("Mutation")
  }

  
      
      addBundledDataSourceInstance<Args extends VariabledInput<{
        input: BundledDataSourceInstanceInput,
      }>,Sel extends Selection<DataSourceInstance>>(args: ExactArgNames<Args, {
        input: BundledDataSourceInstanceInput,
      }>, selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"addBundledDataSourceInstance", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "BundledDataSourceInstanceInput!"
            },
        args,

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("addBundledDataSourceInstance", options as any) as any
    }
  

      
      addCustomDataSourceInstance<Args extends VariabledInput<{
        input: CustomDataSourceInstanceInput,
      }>,Sel extends Selection<DataSourceInstance>>(args: ExactArgNames<Args, {
        input: CustomDataSourceInstanceInput,
      }>, selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"addCustomDataSourceInstance", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "CustomDataSourceInstanceInput!"
            },
        args,

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("addCustomDataSourceInstance", options as any) as any
    }
  

      
      addDataExtract<Args extends VariabledInput<{
        input: DataExtractInput,
      }>,Sel extends Selection<DataExtract>>(args: ExactArgNames<Args, {
        input: DataExtractInput,
      }>, selectorFn: (s: DataExtract) => [...Sel]):$Field<"addDataExtract", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "DataExtractInput!"
            },
        args,

        selection: selectorFn(new DataExtract)
      };
      return this.$_select("addDataExtract", options as any) as any
    }
  

      
      addSubjectToStructuralEntity<Args extends VariabledInput<{
        subjectId: string
structuralEntityKey: string,
      }>,Sel extends Selection<Subject>>(args: ExactArgNames<Args, {
        subjectId: string
structuralEntityKey: string,
      }>, selectorFn: (s: Subject) => [...Sel]):$Field<"addSubjectToStructuralEntity", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              subjectId: "ID!",
structuralEntityKey: "ID!"
            },
        args,

        selection: selectorFn(new Subject)
      };
      return this.$_select("addSubjectToStructuralEntity", options as any) as any
    }
  

      
      get clearStructureCache(): $Field<"clearStructureCache", boolean>  {
       return this.$_select("clearStructureCache") as any
      }

      
      createDashboard<Args extends VariabledInput<{
        input: DashboardInput,
      }>,Sel extends Selection<Dashboard>>(args: ExactArgNames<Args, {
        input: DashboardInput,
      }>, selectorFn: (s: Dashboard) => [...Sel]):$Field<"createDashboard", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "DashboardInput!"
            },
        args,

        selection: selectorFn(new Dashboard)
      };
      return this.$_select("createDashboard", options as any) as any
    }
  

      
      createGroup<Args extends VariabledInput<{
        input: GroupInput,
      }>,Sel extends Selection<Group>>(args: ExactArgNames<Args, {
        input: GroupInput,
      }>, selectorFn: (s: Group) => [...Sel]):$Field<"createGroup", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "GroupInput!"
            },
        args,

        selection: selectorFn(new Group)
      };
      return this.$_select("createGroup", options as any) as any
    }
  

      
      createIFrame<Args extends VariabledInput<{
        input: IFrameInput,
      }>,Sel extends Selection<IFrameWidget>>(args: ExactArgNames<Args, {
        input: IFrameInput,
      }>, selectorFn: (s: IFrameWidget) => [...Sel]):$Field<"createIFrame", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "IFrameInput!"
            },
        args,

        selection: selectorFn(new IFrameWidget)
      };
      return this.$_select("createIFrame", options as any) as any
    }
  

      
      createStructuralEntity<Args extends VariabledInput<{
        input: StructuralEntityInput,
      }>,Sel extends Selection<StructuralEntity>>(args: ExactArgNames<Args, {
        input: StructuralEntityInput,
      }>, selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"createStructuralEntity", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "StructuralEntityInput!"
            },
        args,

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("createStructuralEntity", options as any) as any
    }
  

      
      createStructureType<Args extends VariabledInput<{
        input: StructureTypeInput,
      }>,Sel extends Selection<StructureType>>(args: ExactArgNames<Args, {
        input: StructureTypeInput,
      }>, selectorFn: (s: StructureType) => [...Sel]):$Field<"createStructureType", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "StructureTypeInput!"
            },
        args,

        selection: selectorFn(new StructureType)
      };
      return this.$_select("createStructureType", options as any) as any
    }
  

      
      createSubject<Args extends VariabledInput<{
        input: CreateSubjectInput,
      }>,Sel extends Selection<Subject>>(args: ExactArgNames<Args, {
        input: CreateSubjectInput,
      }>, selectorFn: (s: Subject) => [...Sel]):$Field<"createSubject", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "CreateSubjectInput!"
            },
        args,

        selection: selectorFn(new Subject)
      };
      return this.$_select("createSubject", options as any) as any
    }
  

      
      createSubjectForm<Args extends VariabledInput<{
        subjectId: string
input: CreateSubjectFormInput,
      }>,Sel extends Selection<FormInstance>>(args: ExactArgNames<Args, {
        subjectId: string
input: CreateSubjectFormInput,
      }>, selectorFn: (s: FormInstance) => [...Sel]):$Field<"createSubjectForm", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              subjectId: "ID!",
input: "CreateSubjectFormInput!"
            },
        args,

        selection: selectorFn(new FormInstance)
      };
      return this.$_select("createSubjectForm", options as any) as any
    }
  

      
      createVisualisation<Args extends VariabledInput<{
        input: VisualisationInput,
      }>,Sel extends Selection<Visualisation>>(args: ExactArgNames<Args, {
        input: VisualisationInput,
      }>, selectorFn: (s: Visualisation) => [...Sel]):$Field<"createVisualisation", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "VisualisationInput!"
            },
        args,

        selection: selectorFn(new Visualisation)
      };
      return this.$_select("createVisualisation", options as any) as any
    }
  

      
      deleteDashboard<Args extends VariabledInput<{
        dashboardId: string,
      }>>(args: ExactArgNames<Args, {
        dashboardId: string,
      }>):$Field<"deleteDashboard", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              dashboardId: "ID!"
            },
        args,

        
      };
      return this.$_select("deleteDashboard", options as any) as any
    }
  

      
      deleteDataExtract<Args extends VariabledInput<{
        dataExtractId: string,
      }>>(args: ExactArgNames<Args, {
        dataExtractId: string,
      }>):$Field<"deleteDataExtract", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              dataExtractId: "ID!"
            },
        args,

        
      };
      return this.$_select("deleteDataExtract", options as any) as any
    }
  

      
      deleteDataSourceInstance<Args extends VariabledInput<{
        id: string,
      }>>(args: ExactArgNames<Args, {
        id: string,
      }>):$Field<"deleteDataSourceInstance", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              id: "ID!"
            },
        args,

        
      };
      return this.$_select("deleteDataSourceInstance", options as any) as any
    }
  

      
      deleteGroup<Args extends VariabledInput<{
        groupKey: string,
      }>>(args: ExactArgNames<Args, {
        groupKey: string,
      }>):$Field<"deleteGroup", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              groupKey: "ID!"
            },
        args,

        
      };
      return this.$_select("deleteGroup", options as any) as any
    }
  

      
      deleteStructuralEntity<Args extends VariabledInput<{
        structuralEntityKey: string,
      }>>(args: ExactArgNames<Args, {
        structuralEntityKey: string,
      }>):$Field<"deleteStructuralEntity", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              structuralEntityKey: "ID!"
            },
        args,

        
      };
      return this.$_select("deleteStructuralEntity", options as any) as any
    }
  

      
      deleteStructureType<Args extends VariabledInput<{
        structureTypeKey: string,
      }>>(args: ExactArgNames<Args, {
        structureTypeKey: string,
      }>):$Field<"deleteStructureType", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              structureTypeKey: "ID!"
            },
        args,

        
      };
      return this.$_select("deleteStructureType", options as any) as any
    }
  

      
      get deleteTenantInboundSubjectConfiguration(): $Field<"deleteTenantInboundSubjectConfiguration", boolean>  {
       return this.$_select("deleteTenantInboundSubjectConfiguration") as any
      }

      
      deleteWidget<Args extends VariabledInput<{
        id: string,
      }>>(args: ExactArgNames<Args, {
        id: string,
      }>):$Field<"deleteWidget", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              id: "ID!"
            },
        args,

        
      };
      return this.$_select("deleteWidget", options as any) as any
    }
  

      
      get exportConfiguration(): $Field<"exportConfiguration", boolean>  {
       return this.$_select("exportConfiguration") as any
      }

      
      importConfiguration<Args extends VariabledInput<{
        version: number,
      }>>(args: ExactArgNames<Args, {
        version: number,
      }>):$Field<"importConfiguration", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              version: "Int!"
            },
        args,

        
      };
      return this.$_select("importConfiguration", options as any) as any
    }
  

      
      inviteToSubjectPortal<Args extends VariabledInput<{
        input: InviteToSubjectPortalInput,
      }>,Sel extends Selection<InviteToSubjectPortalPayload>>(args: ExactArgNames<Args, {
        input: InviteToSubjectPortalInput,
      }>, selectorFn: (s: InviteToSubjectPortalPayload) => [...Sel]):$Field<"inviteToSubjectPortal", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "InviteToSubjectPortalInput!"
            },
        args,

        selection: selectorFn(new InviteToSubjectPortalPayload)
      };
      return this.$_select("inviteToSubjectPortal", options as any) as any
    }
  

      
      performDataPointAction<Args extends VariabledInput<{
        dataVisualisationId: string
subjectId: string
dataPointId: string
action: PerformActionInput,
      }>,Sel extends Selection<PerformActionResult>>(args: ExactArgNames<Args, {
        dataVisualisationId: string
subjectId: string
dataPointId: string
action: PerformActionInput,
      }>, selectorFn: (s: PerformActionResult) => [...Sel]):$Field<"performDataPointAction", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              dataVisualisationId: "ID!",
subjectId: "ID!",
dataPointId: "ID!",
action: "PerformActionInput!"
            },
        args,

        selection: selectorFn(new PerformActionResult)
      };
      return this.$_select("performDataPointAction", options as any) as any
    }
  

      
      refreshCache<Args extends VariabledInput<{
        dataExtractId: string,
      }>>(args: ExactArgNames<Args, {
        dataExtractId: string,
      }>):$Field<"refreshCache", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              dataExtractId: "ID!"
            },
        args,

        
      };
      return this.$_select("refreshCache", options as any) as any
    }
  

      
      removeFromSubjectPortal<Args extends VariabledInput<{
        input: RemoveFromSubjectPortalInput,
      }>,Sel extends Selection<RemoveFromSubjectPortalPayload>>(args: ExactArgNames<Args, {
        input: RemoveFromSubjectPortalInput,
      }>, selectorFn: (s: RemoveFromSubjectPortalPayload) => [...Sel]):$Field<"removeFromSubjectPortal", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "RemoveFromSubjectPortalInput!"
            },
        args,

        selection: selectorFn(new RemoveFromSubjectPortalPayload)
      };
      return this.$_select("removeFromSubjectPortal", options as any) as any
    }
  

      
      removeSubjectFromStructuralEntity<Args extends VariabledInput<{
        subjectId: string
structuralEntityKey: string,
      }>,Sel extends Selection<Subject>>(args: ExactArgNames<Args, {
        subjectId: string
structuralEntityKey: string,
      }>, selectorFn: (s: Subject) => [...Sel]):$Field<"removeSubjectFromStructuralEntity", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              subjectId: "ID!",
structuralEntityKey: "ID!"
            },
        args,

        selection: selectorFn(new Subject)
      };
      return this.$_select("removeSubjectFromStructuralEntity", options as any) as any
    }
  

      
      setRepositorySettings<Args extends VariabledInput<{
        input: RepositorySettingsInput,
      }>,Sel extends Selection<RepositorySettings>>(args: ExactArgNames<Args, {
        input: RepositorySettingsInput,
      }>, selectorFn: (s: RepositorySettings) => [...Sel]):$Field<"setRepositorySettings", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "RepositorySettingsInput!"
            },
        args,

        selection: selectorFn(new RepositorySettings)
      };
      return this.$_select("setRepositorySettings", options as any) as any
    }
  

      
      setSectionConfiguration<Args extends VariabledInput<{
        input: SectionConfigurationInput,
      }>,Sel extends Selection<SectionConfiguration>>(args: ExactArgNames<Args, {
        input: SectionConfigurationInput,
      }>, selectorFn: (s: SectionConfiguration) => [...Sel]):$Field<"setSectionConfiguration", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "SectionConfigurationInput!"
            },
        args,

        selection: selectorFn(new SectionConfiguration)
      };
      return this.$_select("setSectionConfiguration", options as any) as any
    }
  

      
      updateBundledDataSourceInstance<Args extends VariabledInput<{
        id: string
input: BundledDataSourceInstanceInput,
      }>,Sel extends Selection<DataSourceInstance>>(args: ExactArgNames<Args, {
        id: string
input: BundledDataSourceInstanceInput,
      }>, selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"updateBundledDataSourceInstance", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!",
input: "BundledDataSourceInstanceInput!"
            },
        args,

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("updateBundledDataSourceInstance", options as any) as any
    }
  

      
      updateCustomDataSourceInstance<Args extends VariabledInput<{
        id: string
input: CustomDataSourceInstanceInput,
      }>,Sel extends Selection<DataSourceInstance>>(args: ExactArgNames<Args, {
        id: string
input: CustomDataSourceInstanceInput,
      }>, selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"updateCustomDataSourceInstance", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!",
input: "CustomDataSourceInstanceInput!"
            },
        args,

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("updateCustomDataSourceInstance", options as any) as any
    }
  

      
      updateDashboard<Args extends VariabledInput<{
        dashboardId: string
input: DashboardInput,
      }>,Sel extends Selection<Dashboard>>(args: ExactArgNames<Args, {
        dashboardId: string
input: DashboardInput,
      }>, selectorFn: (s: Dashboard) => [...Sel]):$Field<"updateDashboard", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              dashboardId: "ID!",
input: "DashboardInput!"
            },
        args,

        selection: selectorFn(new Dashboard)
      };
      return this.$_select("updateDashboard", options as any) as any
    }
  

      
      updateDataExtract<Args extends VariabledInput<{
        dataExtractId: string
title?: string | null | undefined
description?: string | null | undefined,
      }>,Sel extends Selection<DataExtract>>(args: ExactArgNames<Args, {
        dataExtractId: string
title?: string | null | undefined
description?: string | null | undefined,
      }>, selectorFn: (s: DataExtract) => [...Sel]):$Field<"updateDataExtract", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              dataExtractId: "ID!",
title: "String",
description: "String"
            },
        args,

        selection: selectorFn(new DataExtract)
      };
      return this.$_select("updateDataExtract", options as any) as any
    }
  

      
      updateGroup<Args extends VariabledInput<{
        groupKey: string
input: GroupInput,
      }>,Sel extends Selection<Group>>(args: ExactArgNames<Args, {
        groupKey: string
input: GroupInput,
      }>, selectorFn: (s: Group) => [...Sel]):$Field<"updateGroup", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              groupKey: "ID!",
input: "GroupInput!"
            },
        args,

        selection: selectorFn(new Group)
      };
      return this.$_select("updateGroup", options as any) as any
    }
  

      
      updateIFrame<Args extends VariabledInput<{
        id: string
input: IFrameInput,
      }>,Sel extends Selection<IFrameWidget>>(args: ExactArgNames<Args, {
        id: string
input: IFrameInput,
      }>, selectorFn: (s: IFrameWidget) => [...Sel]):$Field<"updateIFrame", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!",
input: "IFrameInput!"
            },
        args,

        selection: selectorFn(new IFrameWidget)
      };
      return this.$_select("updateIFrame", options as any) as any
    }
  

      
      updateStructuralEntity<Args extends VariabledInput<{
        input: StructuralEntityInput,
      }>,Sel extends Selection<StructuralEntity>>(args: ExactArgNames<Args, {
        input: StructuralEntityInput,
      }>, selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"updateStructuralEntity", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "StructuralEntityInput!"
            },
        args,

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("updateStructuralEntity", options as any) as any
    }
  

      
      updateStructureType<Args extends VariabledInput<{
        input: StructureTypeInput,
      }>,Sel extends Selection<StructureType>>(args: ExactArgNames<Args, {
        input: StructureTypeInput,
      }>, selectorFn: (s: StructureType) => [...Sel]):$Field<"updateStructureType", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "StructureTypeInput!"
            },
        args,

        selection: selectorFn(new StructureType)
      };
      return this.$_select("updateStructureType", options as any) as any
    }
  

      
      updateSubject<Args extends VariabledInput<{
        id: string
input: SubjectInput,
      }>,Sel extends Selection<Subject>>(args: ExactArgNames<Args, {
        id: string
input: SubjectInput,
      }>, selectorFn: (s: Subject) => [...Sel]):$Field<"updateSubject", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!",
input: "SubjectInput!"
            },
        args,

        selection: selectorFn(new Subject)
      };
      return this.$_select("updateSubject", options as any) as any
    }
  

      
      updateTenant<Args extends VariabledInput<{
        input: UpdateTenantInput,
      }>,Sel extends Selection<Tenant>>(args: ExactArgNames<Args, {
        input: UpdateTenantInput,
      }>, selectorFn: (s: Tenant) => [...Sel]):$Field<"updateTenant", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "UpdateTenantInput!"
            },
        args,

        selection: selectorFn(new Tenant)
      };
      return this.$_select("updateTenant", options as any) as any
    }
  

      
      updateTenantInboundSubjectConfiguration<Args extends VariabledInput<{
        input: InboundSubjectConfigurationInput,
      }>,Sel extends Selection<InboundSubjectConfiguration>>(args: ExactArgNames<Args, {
        input: InboundSubjectConfigurationInput,
      }>, selectorFn: (s: InboundSubjectConfiguration) => [...Sel]):$Field<"updateTenantInboundSubjectConfiguration", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "InboundSubjectConfigurationInput!"
            },
        args,

        selection: selectorFn(new InboundSubjectConfiguration)
      };
      return this.$_select("updateTenantInboundSubjectConfiguration", options as any) as any
    }
  

      
      updateTenantSubjectAccessConfiguration<Args extends VariabledInput<{
        input: SubjectAccessConfigurationInput,
      }>,Sel extends Selection<SubjectAccessConfiguration>>(args: ExactArgNames<Args, {
        input: SubjectAccessConfigurationInput,
      }>, selectorFn: (s: SubjectAccessConfiguration) => [...Sel]):$Field<"updateTenantSubjectAccessConfiguration", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "SubjectAccessConfigurationInput!"
            },
        args,

        selection: selectorFn(new SubjectAccessConfiguration)
      };
      return this.$_select("updateTenantSubjectAccessConfiguration", options as any) as any
    }
  

      
      updateUserDefaultDashboard<Args extends VariabledInput<{
        userId: string
dashboardId?: string | null | undefined
section: Section,
      }>,Sel extends Selection<UserDefaults>>(args: ExactArgNames<Args, {
        userId: string
dashboardId?: string | null | undefined
section: Section,
      }>, selectorFn: (s: UserDefaults) => [...Sel]):$Field<"updateUserDefaultDashboard", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              userId: "ID!",
dashboardId: "ID",
section: "Section!"
            },
        args,

        selection: selectorFn(new UserDefaults)
      };
      return this.$_select("updateUserDefaultDashboard", options as any) as any
    }
  

      
      updateUserGroups<Args extends VariabledInput<{
        input: UpdateUserGroupsInput,
      }>,Sel extends Selection<TenantUser>>(args: ExactArgNames<Args, {
        input: UpdateUserGroupsInput,
      }>, selectorFn: (s: TenantUser) => [...Sel]):$Field<"updateUserGroups", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "UpdateUserGroupsInput!"
            },
        args,

        selection: selectorFn(new TenantUser)
      };
      return this.$_select("updateUserGroups", options as any) as any
    }
  

      
      updateVisualisation<Args extends VariabledInput<{
        visualisationId: string
input: VisualisationInput,
      }>,Sel extends Selection<Visualisation>>(args: ExactArgNames<Args, {
        visualisationId: string
input: VisualisationInput,
      }>, selectorFn: (s: Visualisation) => [...Sel]):$Field<"updateVisualisation", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              visualisationId: "ID!",
input: "VisualisationInput!"
            },
        args,

        selection: selectorFn(new Visualisation)
      };
      return this.$_select("updateVisualisation", options as any) as any
    }
  

      
      uploadSubjectAttachment<Args extends VariabledInput<{
        subjectId: string
input: SubjectAttachmentInput,
      }>,Sel extends Selection<SubjectAttachment>>(args: ExactArgNames<Args, {
        subjectId: string
input: SubjectAttachmentInput,
      }>, selectorFn: (s: SubjectAttachment) => [...Sel]):$Field<"uploadSubjectAttachment", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              subjectId: "ID!",
input: "SubjectAttachmentInput!"
            },
        args,

        selection: selectorFn(new SubjectAttachment)
      };
      return this.$_select("uploadSubjectAttachment", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "Mutation">  {
       return this.$_select("__typename") as any
      }
}


export class NestedIdentifier extends $Base<"NestedIdentifier"> {
  constructor() {
    super("NestedIdentifier")
  }

  
      
      get allIdentifiers(): $Field<"allIdentifiers", Readonly<Array<string>>>  {
       return this.$_select("allIdentifiers") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      nestedIdentifiers<Sel extends Selection<IIdentifier>>(selectorFn: (s: IIdentifier) => [...Sel]):$Field<"nestedIdentifiers", Array<GetOutput<Sel> | undefined> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IIdentifier)
      };
      return this.$_select("nestedIdentifiers", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "NestedIdentifier">  {
       return this.$_select("__typename") as any
      }
}


export class OrFilter extends $Base<"OrFilter"> {
  constructor() {
    super("OrFilter")
  }

  
      
      or<Sel extends Selection<SingleFieldFilter>>(selectorFn: (s: SingleFieldFilter) => [...Sel]):$Field<"or", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SingleFieldFilter)
      };
      return this.$_select("or", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "OrFilter">  {
       return this.$_select("__typename") as any
      }
}


/**
 * Information about pagination in a connection.
 */
export class PageInfo extends $Base<"PageInfo"> {
  constructor() {
    super("PageInfo")
  }

  
      
/**
 * When paginating forwards, the cursor to continue.
 */
      get endCursor(): $Field<"endCursor", string | null>  {
       return this.$_select("endCursor") as any
      }

      
/**
 * Indicates whether more edges exist following the set defined by the clients arguments.
 */
      get hasNextPage(): $Field<"hasNextPage", boolean>  {
       return this.$_select("hasNextPage") as any
      }

      
/**
 * Indicates whether more edges exist prior the set defined by the clients arguments.
 */
      get hasPreviousPage(): $Field<"hasPreviousPage", boolean>  {
       return this.$_select("hasPreviousPage") as any
      }

      
/**
 * When paginating backwards, the cursor to continue.
 */
      get startCursor(): $Field<"startCursor", string | null>  {
       return this.$_select("startCursor") as any
      }

      
      get __typename(): $Field<"__typename", "PageInfo">  {
       return this.$_select("__typename") as any
      }
}


export class PerformAction extends $Base<"PerformAction"> {
  constructor() {
    super("PerformAction")
  }

  
      
      get description(): $Field<"description", string>  {
       return this.$_select("description") as any
      }

      
      inputFields<Sel extends Selection<InputField>>(selectorFn: (s: InputField) => [...Sel]):$Field<"inputFields", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new InputField)
      };
      return this.$_select("inputFields", options as any) as any
    }
  

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get type(): $Field<"type", DataPointActionType>  {
       return this.$_select("type") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "PerformAction">  {
       return this.$_select("__typename") as any
      }
}


export class PerformActionResult extends $Base<"PerformActionResult"> {
  constructor() {
    super("PerformActionResult")
  }

  
      
      iFrameToDisplay<Sel extends Selection<IFrame>>(selectorFn: (s: IFrame) => [...Sel]):$Field<"iFrameToDisplay", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IFrame)
      };
      return this.$_select("iFrameToDisplay", options as any) as any
    }
  

      
      get message(): $Field<"message", string>  {
       return this.$_select("message") as any
      }

      
      get success(): $Field<"success", boolean>  {
       return this.$_select("success") as any
      }

      
      get __typename(): $Field<"__typename", "PerformActionResult">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class PermissionsConnection extends $Base<"PermissionsConnection"> {
  constructor() {
    super("PermissionsConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<PermissionsEdge>>(selectorFn: (s: PermissionsEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PermissionsEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<AppPermission>>(selectorFn: (s: AppPermission) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AppPermission)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "PermissionsConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class PermissionsEdge extends $Base<"PermissionsEdge"> {
  constructor() {
    super("PermissionsEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<AppPermission>>(selectorFn: (s: AppPermission) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AppPermission)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "PermissionsEdge">  {
       return this.$_select("__typename") as any
      }
}


export class PieChartDisplayOption extends $Base<"PieChartDisplayOption"> {
  constructor() {
    super("PieChartDisplayOption")
  }

  
      
      liquidColourFormatting<Sel extends Selection<KeyValuePairOfStringAndString>>(selectorFn: (s: KeyValuePairOfStringAndString) => [...Sel]):$Field<"liquidColourFormatting", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new KeyValuePairOfStringAndString)
      };
      return this.$_select("liquidColourFormatting", options as any) as any
    }
  

      
      get showLabel(): $Field<"showLabel", boolean>  {
       return this.$_select("showLabel") as any
      }

      
      get showLegend(): $Field<"showLegend", boolean>  {
       return this.$_select("showLegend") as any
      }

      
      get style(): $Field<"style", PieChartStyle>  {
       return this.$_select("style") as any
      }

      
      get visualisationType(): $Field<"visualisationType", VisualisationType>  {
       return this.$_select("visualisationType") as any
      }

      
      get __typename(): $Field<"__typename", "PieChartDisplayOption">  {
       return this.$_select("__typename") as any
      }
}


export class PipelineDefinition extends $Base<"PipelineDefinition"> {
  constructor() {
    super("PipelineDefinition")
  }

  
      
      aggregators<Sel extends Selection<AggregateDefinition>>(selectorFn: (s: AggregateDefinition) => [...Sel]):$Field<"aggregators", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AggregateDefinition)
      };
      return this.$_select("aggregators", options as any) as any
    }
  

      
      filters<Sel extends Selection<LiquidFilterDefinition>>(selectorFn: (s: LiquidFilterDefinition) => [...Sel]):$Field<"filters", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new LiquidFilterDefinition)
      };
      return this.$_select("filters", options as any) as any
    }
  

      
      group<Sel extends Selection<GroupDefinition>>(selectorFn: (s: GroupDefinition) => [...Sel]):$Field<"group", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new GroupDefinition)
      };
      return this.$_select("group", options as any) as any
    }
  

      
      mappers<Sel extends Selection<LiquidMappingDefinition>>(selectorFn: (s: LiquidMappingDefinition) => [...Sel]):$Field<"mappers", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new LiquidMappingDefinition)
      };
      return this.$_select("mappers", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "PipelineDefinition">  {
       return this.$_select("__typename") as any
      }
}


export class PipelineValidationResult extends $Base<"PipelineValidationResult"> {
  constructor() {
    super("PipelineValidationResult")
  }

  
      
      filters<Sel extends Selection<FilterValidationResult>>(selectorFn: (s: FilterValidationResult) => [...Sel]):$Field<"filters", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FilterValidationResult)
      };
      return this.$_select("filters", options as any) as any
    }
  

      
      inputs<Sel extends Selection<TypedField>>(selectorFn: (s: TypedField) => [...Sel]):$Field<"inputs", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TypedField)
      };
      return this.$_select("inputs", options as any) as any
    }
  

      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      outputs<Sel extends Selection<TypedField>>(selectorFn: (s: TypedField) => [...Sel]):$Field<"outputs", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TypedField)
      };
      return this.$_select("outputs", options as any) as any
    }
  

      
      stages<Sel extends Selection<IStageValidationResult>>(selectorFn: (s: IStageValidationResult) => [...Sel]):$Field<"stages", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IStageValidationResult)
      };
      return this.$_select("stages", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "PipelineValidationResult">  {
       return this.$_select("__typename") as any
      }
}


export class Query extends $Base<"Query"> {
  constructor() {
    super("Query")
  }

  
      
      appConfig<Sel extends Selection<AppConfig>>(selectorFn: (s: AppConfig) => [...Sel]):$Field<"appConfig", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AppConfig)
      };
      return this.$_select("appConfig", options as any) as any
    }
  

      
      bundledDataSourceCapabilities<Args extends VariabledInput<{
        input: BundledDataSourceInstanceInput
refreshCache?: boolean,
      }>>(args: ExactArgNames<Args, {
        input: BundledDataSourceInstanceInput
refreshCache?: boolean,
      }>):$Field<"bundledDataSourceCapabilities", Readonly<Array<DataSourceCapability>> , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              input: "BundledDataSourceInstanceInput!",
refreshCache: "Boolean!"
            },
        args,

        
      };
      return this.$_select("bundledDataSourceCapabilities", options as any) as any
    }
  

      
      customDataSourceCapabilities<Args extends VariabledInput<{
        input: CustomDataSourceInstanceInput
refreshCache?: boolean,
      }>>(args: ExactArgNames<Args, {
        input: CustomDataSourceInstanceInput
refreshCache?: boolean,
      }>):$Field<"customDataSourceCapabilities", Readonly<Array<DataSourceCapability>> , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              input: "CustomDataSourceInstanceInput!",
refreshCache: "Boolean!"
            },
        args,

        
      };
      return this.$_select("customDataSourceCapabilities", options as any) as any
    }
  

      
      dashboard<Args extends VariabledInput<{
        id: string,
      }>,Sel extends Selection<Dashboard>>(args: ExactArgNames<Args, {
        id: string,
      }>, selectorFn: (s: Dashboard) => [...Sel]):$Field<"dashboard", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!"
            },
        args,

        selection: selectorFn(new Dashboard)
      };
      return this.$_select("dashboard", options as any) as any
    }
  

      
      dataSourceMetadata<Args extends VariabledInput<{
        endpoint: URL
clientId: string
clientSecret: string
scope: string,
      }>,Sel extends Selection<DataSourceBasicMetadata>>(args: ExactArgNames<Args, {
        endpoint: URL
clientId: string
clientSecret: string
scope: string,
      }>, selectorFn: (s: DataSourceBasicMetadata) => [...Sel]):$Field<"dataSourceMetadata", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              endpoint: "URL!",
clientId: "String!",
clientSecret: "String!",
scope: "String!"
            },
        args,

        selection: selectorFn(new DataSourceBasicMetadata)
      };
      return this.$_select("dataSourceMetadata", options as any) as any
    }
  

      
      dataSources<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<DataSourcesConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: DataSourcesConnection) => [...Sel]):$Field<"dataSources", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
dataSources<Sel extends Selection<DataSourcesConnection>>(selectorFn: (s: DataSourcesConnection) => [...Sel]):$Field<"dataSources", GetOutput<Sel> | undefined , GetVariables<Sel>>
dataSources(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new DataSourcesConnection)
      };
      return this.$_select("dataSources", options as any) as any
    }
  

      
      me<Sel extends Selection<TenantUser>>(selectorFn: (s: TenantUser) => [...Sel]):$Field<"me", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TenantUser)
      };
      return this.$_select("me", options as any) as any
    }
  

      
      renderLiquidExpression<Args extends VariabledInput<{
        expression: string
model?: Readonly<Array<KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndValueInput>> | null | undefined,
      }>>(args: ExactArgNames<Args, {
        expression: string
model?: Readonly<Array<KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndValueInput>> | null | undefined,
      }>):$Field<"renderLiquidExpression", string | null , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              expression: "String!",
model: "[KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndValueInput!]"
            },
        args,

        
      };
      return this.$_select("renderLiquidExpression", options as any) as any
    }
  

      
      secretKeys<Args extends VariabledInput<{
        scope: string,
      }>,Sel extends Selection<AireVaultValueReference>>(args: ExactArgNames<Args, {
        scope: string,
      }>, selectorFn: (s: AireVaultValueReference) => [...Sel]):$Field<"secretKeys", Array<GetOutput<Sel>> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              scope: "String!"
            },
        args,

        selection: selectorFn(new AireVaultValueReference)
      };
      return this.$_select("secretKeys", options as any) as any
    }
  

      
      structuralEntity<Args extends VariabledInput<{
        key: string,
      }>,Sel extends Selection<StructuralEntity>>(args: ExactArgNames<Args, {
        key: string,
      }>, selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"structuralEntity", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              key: "ID!"
            },
        args,

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("structuralEntity", options as any) as any
    }
  

      
      subject<Args extends VariabledInput<{
        input: GetSubjectInput,
      }>,Sel extends Selection<Subject>>(args: ExactArgNames<Args, {
        input: GetSubjectInput,
      }>, selectorFn: (s: Subject) => [...Sel]):$Field<"subject", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "GetSubjectInput!"
            },
        args,

        selection: selectorFn(new Subject)
      };
      return this.$_select("subject", options as any) as any
    }
  

      
      tenant<Sel extends Selection<Tenant>>(selectorFn: (s: Tenant) => [...Sel]):$Field<"tenant", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Tenant)
      };
      return this.$_select("tenant", options as any) as any
    }
  

      
      userDetail<Args extends VariabledInput<{
        id: string,
      }>,Sel extends Selection<TenantUser>>(args: ExactArgNames<Args, {
        id: string,
      }>, selectorFn: (s: TenantUser) => [...Sel]):$Field<"userDetail", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "String!"
            },
        args,

        selection: selectorFn(new TenantUser)
      };
      return this.$_select("userDetail", options as any) as any
    }
  

      
      validateLiquidExpression<Args extends VariabledInput<{
        expression: string
inputs?: Readonly<Array<KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndDataTypeWrapperInput>> | null | undefined,
      }>,Sel extends Selection<ILiquidParseResult>>(args: ExactArgNames<Args, {
        expression: string
inputs?: Readonly<Array<KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndDataTypeWrapperInput>> | null | undefined,
      }>, selectorFn: (s: ILiquidParseResult) => [...Sel]):$Field<"validateLiquidExpression", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              expression: "String!",
inputs: "[KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndDataTypeWrapperInput!]"
            },
        args,

        selection: selectorFn(new ILiquidParseResult)
      };
      return this.$_select("validateLiquidExpression", options as any) as any
    }
  

      
      validatePipelineDefinition<Args extends VariabledInput<{
        dataExtractId: string
pipelineDefinition: PipelineDefinitionInput,
      }>,Sel extends Selection<PipelineValidationResult>>(args: ExactArgNames<Args, {
        dataExtractId: string
pipelineDefinition: PipelineDefinitionInput,
      }>, selectorFn: (s: PipelineValidationResult) => [...Sel]):$Field<"validatePipelineDefinition", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              dataExtractId: "ID!",
pipelineDefinition: "PipelineDefinitionInput!"
            },
        args,

        selection: selectorFn(new PipelineValidationResult)
      };
      return this.$_select("validatePipelineDefinition", options as any) as any
    }
  

      
      variableKeys<Args extends VariabledInput<{
        scope: string,
      }>,Sel extends Selection<AireVaultValueReference>>(args: ExactArgNames<Args, {
        scope: string,
      }>, selectorFn: (s: AireVaultValueReference) => [...Sel]):$Field<"variableKeys", Array<GetOutput<Sel>> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              scope: "String!"
            },
        args,

        selection: selectorFn(new AireVaultValueReference)
      };
      return this.$_select("variableKeys", options as any) as any
    }
  

      
      visualisation<Args extends VariabledInput<{
        id: string,
      }>,Sel extends Selection<Visualisation>>(args: ExactArgNames<Args, {
        id: string,
      }>, selectorFn: (s: Visualisation) => [...Sel]):$Field<"visualisation", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!"
            },
        args,

        selection: selectorFn(new Visualisation)
      };
      return this.$_select("visualisation", options as any) as any
    }
  

      
      widget<Args extends VariabledInput<{
        id: string,
      }>,Sel extends Selection<Widget>>(args: ExactArgNames<Args, {
        id: string,
      }>, selectorFn: (s: Widget) => [...Sel]):$Field<"widget", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!"
            },
        args,

        selection: selectorFn(new Widget)
      };
      return this.$_select("widget", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "Query">  {
       return this.$_select("__typename") as any
      }
}


export class ReferenceValueOfString extends $Base<"ReferenceValueOfString"> {
  constructor() {
    super("ReferenceValueOfString")
  }

  
      
      get label(): $Field<"label", string>  {
       return this.$_select("label") as any
      }

      
      get value(): $Field<"value", string>  {
       return this.$_select("value") as any
      }

      
      get __typename(): $Field<"__typename", "ReferenceValueOfString">  {
       return this.$_select("__typename") as any
      }
}


export class RemoveFromSubjectPortalPayload extends $Base<"RemoveFromSubjectPortalPayload"> {
  constructor() {
    super("RemoveFromSubjectPortalPayload")
  }

  
      
      get boolean(): $Field<"boolean", boolean | null>  {
       return this.$_select("boolean") as any
      }

      
      get __typename(): $Field<"__typename", "RemoveFromSubjectPortalPayload">  {
       return this.$_select("__typename") as any
      }
}


export class RepositorySettings extends $Base<"RepositorySettings"> {
  constructor() {
    super("RepositorySettings")
  }

  
      
      get branchReference(): $Field<"branchReference", string>  {
       return this.$_select("branchReference") as any
      }

      
      get configurationDirectory(): $Field<"configurationDirectory", string>  {
       return this.$_select("configurationDirectory") as any
      }

      
      get passwordReference(): $Field<"passwordReference", string | null>  {
       return this.$_select("passwordReference") as any
      }

      
      get sshPrivateKeyReference(): $Field<"sshPrivateKeyReference", string | null>  {
       return this.$_select("sshPrivateKeyReference") as any
      }

      
      get uriReference(): $Field<"uriReference", string>  {
       return this.$_select("uriReference") as any
      }

      
      get usernameReference(): $Field<"usernameReference", string | null>  {
       return this.$_select("usernameReference") as any
      }

      
      get __typename(): $Field<"__typename", "RepositorySettings">  {
       return this.$_select("__typename") as any
      }
}


export class ResolvedLiquidFilterValue extends $Base<"ResolvedLiquidFilterValue"> {
  constructor() {
    super("ResolvedLiquidFilterValue")
  }

  
      
      get fieldId(): $Field<"fieldId", number>  {
       return this.$_select("fieldId") as any
      }

      
      get resolvedStringValue(): $Field<"resolvedStringValue", string>  {
       return this.$_select("resolvedStringValue") as any
      }

      
      get __typename(): $Field<"__typename", "ResolvedLiquidFilterValue">  {
       return this.$_select("__typename") as any
      }
}


export class SectionConfiguration extends $Base<"SectionConfiguration"> {
  constructor() {
    super("SectionConfiguration")
  }

  
      
      dashboards<Sel extends Selection<Dashboard>>(selectorFn: (s: Dashboard) => [...Sel]):$Field<"dashboards", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Dashboard)
      };
      return this.$_select("dashboards", options as any) as any
    }
  

      
      defaultDashboard<Sel extends Selection<Dashboard>>(selectorFn: (s: Dashboard) => [...Sel]):$Field<"defaultDashboard", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Dashboard)
      };
      return this.$_select("defaultDashboard", options as any) as any
    }
  

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get section(): $Field<"section", Section>  {
       return this.$_select("section") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "SectionConfiguration">  {
       return this.$_select("__typename") as any
      }
}


export class SectionDashboard extends $Base<"SectionDashboard"> {
  constructor() {
    super("SectionDashboard")
  }

  
      
      get dashboardId(): $Field<"dashboardId", Uuid>  {
       return this.$_select("dashboardId") as any
      }

      
      get section(): $Field<"section", Section>  {
       return this.$_select("section") as any
      }

      
      get __typename(): $Field<"__typename", "SectionDashboard">  {
       return this.$_select("__typename") as any
      }
}


export class SelfAccessConfiguration extends $Base<"SelfAccessConfiguration"> {
  constructor() {
    super("SelfAccessConfiguration")
  }

  
      
      emailLinkingField<Sel extends Selection<SubjectInputField>>(selectorFn: (s: SubjectInputField) => [...Sel]):$Field<"emailLinkingField", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectInputField)
      };
      return this.$_select("emailLinkingField", options as any) as any
    }
  

      
      get enabled(): $Field<"enabled", boolean>  {
       return this.$_select("enabled") as any
      }

      
      get __typename(): $Field<"__typename", "SelfAccessConfiguration">  {
       return this.$_select("__typename") as any
      }
}


export class Series extends $Base<"Series"> {
  constructor() {
    super("Series")
  }

  
      
      get chartType(): $Field<"chartType", ChartType | null>  {
       return this.$_select("chartType") as any
      }

      
      get colour(): $Field<"colour", string | null>  {
       return this.$_select("colour") as any
      }

      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get hidden(): $Field<"hidden", boolean>  {
       return this.$_select("hidden") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get liquidFormatter(): $Field<"liquidFormatter", string | null>  {
       return this.$_select("liquidFormatter") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get order(): $Field<"order", number>  {
       return this.$_select("order") as any
      }

      
      get renderedName(): $Field<"renderedName", string | null>  {
       return this.$_select("renderedName") as any
      }

      
      get __typename(): $Field<"__typename", "Series">  {
       return this.$_select("__typename") as any
      }
}


export class SeriesWithReferenceValue extends $Base<"SeriesWithReferenceValue"> {
  constructor() {
    super("SeriesWithReferenceValue")
  }

  
      
      get chartType(): $Field<"chartType", ChartType | null>  {
       return this.$_select("chartType") as any
      }

      
      get colour(): $Field<"colour", string | null>  {
       return this.$_select("colour") as any
      }

      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get hidden(): $Field<"hidden", boolean>  {
       return this.$_select("hidden") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get liquidFormatter(): $Field<"liquidFormatter", string | null>  {
       return this.$_select("liquidFormatter") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get order(): $Field<"order", number>  {
       return this.$_select("order") as any
      }

      
      referenceValues<Sel extends Selection<ReferenceValueOfString>>(selectorFn: (s: ReferenceValueOfString) => [...Sel]):$Field<"referenceValues", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ReferenceValueOfString)
      };
      return this.$_select("referenceValues", options as any) as any
    }
  

      
      get renderedName(): $Field<"renderedName", string | null>  {
       return this.$_select("renderedName") as any
      }

      
      get __typename(): $Field<"__typename", "SeriesWithReferenceValue">  {
       return this.$_select("__typename") as any
      }
}


export class SimpleFieldType extends $Base<"SimpleFieldType"> {
  constructor() {
    super("SimpleFieldType")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get key(): $Field<"key", FieldTypeKey>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "SimpleFieldType">  {
       return this.$_select("__typename") as any
      }
}


export class SingleFieldFilter extends $Base<"SingleFieldFilter"> {
  constructor() {
    super("SingleFieldFilter")
  }

  
      
      get fieldKey(): $Field<"fieldKey", string>  {
       return this.$_select("fieldKey") as any
      }

      
      filter<Sel extends Selection<IDataTypeValueFilter>>(selectorFn: (s: IDataTypeValueFilter) => [...Sel]):$Field<"filter", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataTypeValueFilter)
      };
      return this.$_select("filter", options as any) as any
    }
  

      
      get visualisationId(): $Field<"visualisationId", Uuid>  {
       return this.$_select("visualisationId") as any
      }

      
      get __typename(): $Field<"__typename", "SingleFieldFilter">  {
       return this.$_select("__typename") as any
      }
}


export class SingleIdentifier extends $Base<"SingleIdentifier"> {
  constructor() {
    super("SingleIdentifier")
  }

  
      
      get allIdentifiers(): $Field<"allIdentifiers", Readonly<Array<string>>>  {
       return this.$_select("allIdentifiers") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "SingleIdentifier">  {
       return this.$_select("__typename") as any
      }
}


export class StageValidationResult extends $Base<"StageValidationResult"> {
  constructor() {
    super("StageValidationResult")
  }

  
      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      outputs<Sel extends Selection<TypedField>>(selectorFn: (s: TypedField) => [...Sel]):$Field<"outputs", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TypedField)
      };
      return this.$_select("outputs", options as any) as any
    }
  

      
      steps<Sel extends Selection<StepValidationResult>>(selectorFn: (s: StepValidationResult) => [...Sel]):$Field<"steps", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StepValidationResult)
      };
      return this.$_select("steps", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "StageValidationResult">  {
       return this.$_select("__typename") as any
      }
}


export class StepValidationResult extends $Base<"StepValidationResult"> {
  constructor() {
    super("StepValidationResult")
  }

  
      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      outputs<Sel extends Selection<TypedField>>(selectorFn: (s: TypedField) => [...Sel]):$Field<"outputs", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TypedField)
      };
      return this.$_select("outputs", options as any) as any
    }
  

      
      transforms<Sel extends Selection<TransformValidationResult>>(selectorFn: (s: TransformValidationResult) => [...Sel]):$Field<"transforms", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TransformValidationResult)
      };
      return this.$_select("transforms", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "StepValidationResult">  {
       return this.$_select("__typename") as any
      }
}


export class SteppedAxis extends $Base<"SteppedAxis"> {
  constructor() {
    super("SteppedAxis")
  }

  
      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get label(): $Field<"label", string>  {
       return this.$_select("label") as any
      }

      
      get max(): $Field<"max", string | null>  {
       return this.$_select("max") as any
      }

      
      get min(): $Field<"min", string | null>  {
       return this.$_select("min") as any
      }

      
      series<Sel extends Selection<Series>>(selectorFn: (s: Series) => [...Sel]):$Field<"series", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Series)
      };
      return this.$_select("series", options as any) as any
    }
  

      
      get step(): $Field<"step", string | null>  {
       return this.$_select("step") as any
      }

      
      get type(): $Field<"type", AxisType>  {
       return this.$_select("type") as any
      }

      
      get __typename(): $Field<"__typename", "SteppedAxis">  {
       return this.$_select("__typename") as any
      }
}


export class StringDataTypeValue extends $Base<"StringDataTypeValue"> {
  constructor() {
    super("StringDataTypeValue")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      get value(): $Field<"value", string | null>  {
       return this.$_select("value") as any
      }

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "StringDataTypeValue">  {
       return this.$_select("__typename") as any
      }
}


export class StringDataTypeValueFilter extends $Base<"StringDataTypeValueFilter"> {
  constructor() {
    super("StringDataTypeValueFilter")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }

      
      get stringComparator(): $Field<"stringComparator", StringComparator>  {
       return this.$_select("stringComparator") as any
      }

      
      get stringValue(): $Field<"stringValue", string | null>  {
       return this.$_select("stringValue") as any
      }

      
      get __typename(): $Field<"__typename", "StringDataTypeValueFilter">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class StructuralEntitiesConnection extends $Base<"StructuralEntitiesConnection"> {
  constructor() {
    super("StructuralEntitiesConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<StructuralEntitiesEdge>>(selectorFn: (s: StructuralEntitiesEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntitiesEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<StructuralEntity>>(selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "StructuralEntitiesConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class StructuralEntitiesEdge extends $Base<"StructuralEntitiesEdge"> {
  constructor() {
    super("StructuralEntitiesEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<StructuralEntity>>(selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "StructuralEntitiesEdge">  {
       return this.$_select("__typename") as any
      }
}


export class StructuralEntity extends $Base<"StructuralEntity"> {
  constructor() {
    super("StructuralEntity")
  }

  
      
      customFieldValues<Sel extends Selection<InputFieldValue>>(selectorFn: (s: InputFieldValue) => [...Sel]):$Field<"customFieldValues", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new InputFieldValue)
      };
      return this.$_select("customFieldValues", options as any) as any
    }
  

      
      get depth(): $Field<"depth", number>  {
       return this.$_select("depth") as any
      }

      
      get displayName(): $Field<"displayName", string>  {
       return this.$_select("displayName") as any
      }

      
      get hasChildren(): $Field<"hasChildren", boolean>  {
       return this.$_select("hasChildren") as any
      }

      
      get hasSubjectsAssigned(): $Field<"hasSubjectsAssigned", boolean>  {
       return this.$_select("hasSubjectsAssigned") as any
      }

      
      get isUsedByInboundSubjectConfiguration(): $Field<"isUsedByInboundSubjectConfiguration", boolean>  {
       return this.$_select("isUsedByInboundSubjectConfiguration") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      parent<Sel extends Selection<StructuralEntity>>(selectorFn: (s: StructuralEntity) => [...Sel]):$Field<"parent", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructuralEntity)
      };
      return this.$_select("parent", options as any) as any
    }
  

      
      get parentKey(): $Field<"parentKey", string | null>  {
       return this.$_select("parentKey") as any
      }

      
      structureType<Sel extends Selection<StructureType>>(selectorFn: (s: StructureType) => [...Sel]):$Field<"structureType", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructureType)
      };
      return this.$_select("structureType", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "StructuralEntity">  {
       return this.$_select("__typename") as any
      }
}


export class StructureType extends $Base<"StructureType"> {
  constructor() {
    super("StructureType")
  }

  
      
      customFields<Sel extends Selection<InputField>>(selectorFn: (s: InputField) => [...Sel]):$Field<"customFields", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new InputField)
      };
      return this.$_select("customFields", options as any) as any
    }
  

      
      get isUsed(): $Field<"isUsed", boolean>  {
       return this.$_select("isUsed") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get subjectAssignable(): $Field<"subjectAssignable", boolean>  {
       return this.$_select("subjectAssignable") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "StructureType">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class StructureTypesConnection extends $Base<"StructureTypesConnection"> {
  constructor() {
    super("StructureTypesConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<StructureTypesEdge>>(selectorFn: (s: StructureTypesEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructureTypesEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<StructureType>>(selectorFn: (s: StructureType) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructureType)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "StructureTypesConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class StructureTypesEdge extends $Base<"StructureTypesEdge"> {
  constructor() {
    super("StructureTypesEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<StructureType>>(selectorFn: (s: StructureType) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new StructureType)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "StructureTypesEdge">  {
       return this.$_select("__typename") as any
      }
}


export class StructuredDataCapabilityMetadata extends $Base<"StructuredDataCapabilityMetadata"> {
  constructor() {
    super("StructuredDataCapabilityMetadata")
  }

  
      
      get capability(): $Field<"capability", DataSourceCapability>  {
       return this.$_select("capability") as any
      }

      
      get schemaProviderType(): $Field<"schemaProviderType", SchemaProviderType>  {
       return this.$_select("schemaProviderType") as any
      }

      
      get __typename(): $Field<"__typename", "StructuredDataCapabilityMetadata">  {
       return this.$_select("__typename") as any
      }
}


export class StructuredDataProviderType extends $Base<"StructuredDataProviderType"> {
  constructor() {
    super("StructuredDataProviderType")
  }

  
      
      get jsonSchema(): $Field<"jsonSchema", JSON | null>  {
       return this.$_select("jsonSchema") as any
      }

      
      parseProviderJson<Args extends VariabledInput<{
        jsonDocument: string,
      }>,Sel extends Selection<ISchemaParseResult>>(args: ExactArgNames<Args, {
        jsonDocument: string,
      }>, selectorFn: (s: ISchemaParseResult) => [...Sel]):$Field<"parseProviderJson", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              jsonDocument: "String!"
            },
        args,

        selection: selectorFn(new ISchemaParseResult)
      };
      return this.$_select("parseProviderJson", options as any) as any
    }
  

      
      get schemaProviderType(): $Field<"schemaProviderType", SchemaProviderType>  {
       return this.$_select("schemaProviderType") as any
      }

      
      get __typename(): $Field<"__typename", "StructuredDataProviderType">  {
       return this.$_select("__typename") as any
      }
}


export class Subject extends $Base<"Subject"> {
  constructor() {
    super("Subject")
  }

  
      
      get avatarHash(): $Field<"avatarHash", string | null>  {
       return this.$_select("avatarHash") as any
      }

      
      customFieldValues<Sel extends Selection<SubjectCustomFieldValue>>(selectorFn: (s: SubjectCustomFieldValue) => [...Sel]):$Field<"customFieldValues", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectCustomFieldValue)
      };
      return this.$_select("customFieldValues", options as any) as any
    }
  

      
      get hasHiddenLocations(): $Field<"hasHiddenLocations", boolean>  {
       return this.$_select("hasHiddenLocations") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      linkedPortalUsers<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
inviteMethod?: InviteMethod | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<LinkedPortalUsersConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
inviteMethod?: InviteMethod | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: LinkedPortalUsersConnection) => [...Sel]):$Field<"linkedPortalUsers", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
linkedPortalUsers<Sel extends Selection<LinkedPortalUsersConnection>>(selectorFn: (s: LinkedPortalUsersConnection) => [...Sel]):$Field<"linkedPortalUsers", GetOutput<Sel> | undefined , GetVariables<Sel>>
linkedPortalUsers(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
inviteMethod: "InviteMethod",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new LinkedPortalUsersConnection)
      };
      return this.$_select("linkedPortalUsers", options as any) as any
    }
  

      
      locations<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<LocationsConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: LocationsConnection) => [...Sel]):$Field<"locations", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
locations<Sel extends Selection<LocationsConnection>>(selectorFn: (s: LocationsConnection) => [...Sel]):$Field<"locations", GetOutput<Sel> | undefined , GetVariables<Sel>>
locations(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new LocationsConnection)
      };
      return this.$_select("locations", options as any) as any
    }
  

      
      primaryFieldValue<Sel extends Selection<SubjectCustomFieldSingleValue>>(selectorFn: (s: SubjectCustomFieldSingleValue) => [...Sel]):$Field<"primaryFieldValue", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectCustomFieldSingleValue)
      };
      return this.$_select("primaryFieldValue", options as any) as any
    }
  

      
      subjectAccessInviteStatus<Sel extends Selection<SubjectSelfAccessStatus>>(selectorFn: (s: SubjectSelfAccessStatus) => [...Sel]):$Field<"subjectAccessInviteStatus", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectSelfAccessStatus)
      };
      return this.$_select("subjectAccessInviteStatus", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "Subject">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectAccessConfiguration extends $Base<"SubjectAccessConfiguration"> {
  constructor() {
    super("SubjectAccessConfiguration")
  }

  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      selfAccessConfiguration<Sel extends Selection<SelfAccessConfiguration>>(selectorFn: (s: SelfAccessConfiguration) => [...Sel]):$Field<"selfAccessConfiguration", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SelfAccessConfiguration)
      };
      return this.$_select("selfAccessConfiguration", options as any) as any
    }
  

      
      get subjectNotLinkedErrorMessage(): $Field<"subjectNotLinkedErrorMessage", string>  {
       return this.$_select("subjectNotLinkedErrorMessage") as any
      }

      
      tenant<Sel extends Selection<Tenant>>(selectorFn: (s: Tenant) => [...Sel]):$Field<"tenant", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Tenant)
      };
      return this.$_select("tenant", options as any) as any
    }
  

      
      thirdPartyAccessConfiguration<Sel extends Selection<ThirdPartyAccessConfiguration>>(selectorFn: (s: ThirdPartyAccessConfiguration) => [...Sel]):$Field<"thirdPartyAccessConfiguration", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ThirdPartyAccessConfiguration)
      };
      return this.$_select("thirdPartyAccessConfiguration", options as any) as any
    }
  

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectAccessConfiguration">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectAccessUser extends $Base<"SubjectAccessUser"> {
  constructor() {
    super("SubjectAccessUser")
  }

  
      
      get displayName(): $Field<"displayName", string>  {
       return this.$_select("displayName") as any
      }

      
      get email(): $Field<"email", string | null>  {
       return this.$_select("email") as any
      }

      
      get id(): $Field<"id", string>  {
       return this.$_select("id") as any
      }

      
      get inviteMethod(): $Field<"inviteMethod", InviteMethod>  {
       return this.$_select("inviteMethod") as any
      }

      
      get lastSessionStartTime(): $Field<"lastSessionStartTime", DateTime | null>  {
       return this.$_select("lastSessionStartTime") as any
      }

      
      get subjectId(): $Field<"subjectId", Uuid>  {
       return this.$_select("subjectId") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectAccessUser">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectAttachment extends $Base<"SubjectAttachment"> {
  constructor() {
    super("SubjectAttachment")
  }

  
      
      get createdAt(): $Field<"createdAt", DateTime>  {
       return this.$_select("createdAt") as any
      }

      
      createdBy<Sel extends Selection<UserIdentifier>>(selectorFn: (s: UserIdentifier) => [...Sel]):$Field<"createdBy", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new UserIdentifier)
      };
      return this.$_select("createdBy", options as any) as any
    }
  

      
      get effectiveDate(): $Field<"effectiveDate", DateTime>  {
       return this.$_select("effectiveDate") as any
      }

      
      get id(): $Field<"id", string>  {
       return this.$_select("id") as any
      }

      
      get internalSubjectId(): $Field<"internalSubjectId", Uuid>  {
       return this.$_select("internalSubjectId") as any
      }

      
      get status(): $Field<"status", RecordItemStatus>  {
       return this.$_select("status") as any
      }

      
      get title(): $Field<"title", string>  {
       return this.$_select("title") as any
      }

      
      get updatedAt(): $Field<"updatedAt", DateTime>  {
       return this.$_select("updatedAt") as any
      }

      
      get url(): $Field<"url", string>  {
       return this.$_select("url") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectAttachment">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectComputedField extends $Base<"SubjectComputedField"> {
  constructor() {
    super("SubjectComputedField")
  }

  
      
      get canModify(): $Field<"canModify", boolean>  {
       return this.$_select("canModify") as any
      }

      
      clone<Args extends VariabledInput<{
        id: Uuid,
      }>,Sel extends Selection<ISubjectCustomField>>(args: ExactArgNames<Args, {
        id: Uuid,
      }>, selectorFn: (s: ISubjectCustomField) => [...Sel]):$Field<"clone", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "Uuid!"
            },
        args,

        selection: selectorFn(new ISubjectCustomField)
      };
      return this.$_select("clone", options as any) as any
    }
  

      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get isArray(): $Field<"isArray", boolean>  {
       return this.$_select("isArray") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get liquidMapping(): $Field<"liquidMapping", string>  {
       return this.$_select("liquidMapping") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectComputedField">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectCustomFieldArrayValue extends $Base<"SubjectCustomFieldArrayValue"> {
  constructor() {
    super("SubjectCustomFieldArrayValue")
  }

  
      
      customField<Sel extends Selection<ISubjectCustomField>>(selectorFn: (s: ISubjectCustomField) => [...Sel]):$Field<"customField", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ISubjectCustomField)
      };
      return this.$_select("customField", options as any) as any
    }
  

      
      values<Sel extends Selection<IDataTypeValue>>(selectorFn: (s: IDataTypeValue) => [...Sel]):$Field<"values", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataTypeValue)
      };
      return this.$_select("values", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "SubjectCustomFieldArrayValue">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectCustomFieldMapping extends $Base<"SubjectCustomFieldMapping"> {
  constructor() {
    super("SubjectCustomFieldMapping")
  }

  
      
      customField<Sel extends Selection<SubjectInputField>>(selectorFn: (s: SubjectInputField) => [...Sel]):$Field<"customField", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectInputField)
      };
      return this.$_select("customField", options as any) as any
    }
  

      
      get mapping(): $Field<"mapping", string>  {
       return this.$_select("mapping") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectCustomFieldMapping">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectCustomFieldSingleValue extends $Base<"SubjectCustomFieldSingleValue"> {
  constructor() {
    super("SubjectCustomFieldSingleValue")
  }

  
      
      customField<Sel extends Selection<ISubjectCustomField>>(selectorFn: (s: ISubjectCustomField) => [...Sel]):$Field<"customField", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ISubjectCustomField)
      };
      return this.$_select("customField", options as any) as any
    }
  

      
      value<Sel extends Selection<IDataTypeValue>>(selectorFn: (s: IDataTypeValue) => [...Sel]):$Field<"value", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDataTypeValue)
      };
      return this.$_select("value", options as any) as any
    }
  

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectCustomFieldSingleValue">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectIdentifierConfiguration extends $Base<"SubjectIdentifierConfiguration"> {
  constructor() {
    super("SubjectIdentifierConfiguration")
  }

  
      
      get customFieldKey(): $Field<"customFieldKey", string | null>  {
       return this.$_select("customFieldKey") as any
      }

      
      get useExternalId(): $Field<"useExternalId", boolean>  {
       return this.$_select("useExternalId") as any
      }

      
      get useInternalId(): $Field<"useInternalId", boolean>  {
       return this.$_select("useInternalId") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectIdentifierConfiguration">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectInputField extends $Base<"SubjectInputField"> {
  constructor() {
    super("SubjectInputField")
  }

  
      
      get canModify(): $Field<"canModify", boolean>  {
       return this.$_select("canModify") as any
      }

      
      clone<Args extends VariabledInput<{
        id: Uuid,
      }>,Sel extends Selection<ISubjectCustomField>>(args: ExactArgNames<Args, {
        id: Uuid,
      }>, selectorFn: (s: ISubjectCustomField) => [...Sel]):$Field<"clone", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "Uuid!"
            },
        args,

        selection: selectorFn(new ISubjectCustomField)
      };
      return this.$_select("clone", options as any) as any
    }
  

      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get formPositionIndex(): $Field<"formPositionIndex", number>  {
       return this.$_select("formPositionIndex") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      get isArray(): $Field<"isArray", boolean>  {
       return this.$_select("isArray") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get required(): $Field<"required", boolean>  {
       return this.$_select("required") as any
      }

      
      type<Sel extends Selection<IGQLFieldType>>(selectorFn: (s: IGQLFieldType) => [...Sel]):$Field<"type", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IGQLFieldType)
      };
      return this.$_select("type", options as any) as any
    }
  

      
      get unique(): $Field<"unique", boolean>  {
       return this.$_select("unique") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectInputField">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectMapping extends $Base<"SubjectMapping"> {
  constructor() {
    super("SubjectMapping")
  }

  
      
      customFieldMappings<Sel extends Selection<SubjectCustomFieldMapping>>(selectorFn: (s: SubjectCustomFieldMapping) => [...Sel]):$Field<"customFieldMappings", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectCustomFieldMapping)
      };
      return this.$_select("customFieldMappings", options as any) as any
    }
  

      
      get externalIdMapping(): $Field<"externalIdMapping", string>  {
       return this.$_select("externalIdMapping") as any
      }

      
      get liquidLookupExpression(): $Field<"liquidLookupExpression", string>  {
       return this.$_select("liquidLookupExpression") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectMapping">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectProviderConfiguration extends $Base<"SubjectProviderConfiguration"> {
  constructor() {
    super("SubjectProviderConfiguration")
  }

  
      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      subjectMapping<Sel extends Selection<SubjectMapping>>(selectorFn: (s: SubjectMapping) => [...Sel]):$Field<"subjectMapping", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectMapping)
      };
      return this.$_select("subjectMapping", options as any) as any
    }
  

      
      tenant<Sel extends Selection<Tenant>>(selectorFn: (s: Tenant) => [...Sel]):$Field<"tenant", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Tenant)
      };
      return this.$_select("tenant", options as any) as any
    }
  

      
      get tenantDataSourceId(): $Field<"tenantDataSourceId", Uuid>  {
       return this.$_select("tenantDataSourceId") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectProviderConfiguration">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectProviderType extends $Base<"SubjectProviderType"> {
  constructor() {
    super("SubjectProviderType")
  }

  
      
      get requiresSubjectFieldMapping(): $Field<"requiresSubjectFieldMapping", boolean>  {
       return this.$_select("requiresSubjectFieldMapping") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectProviderType">  {
       return this.$_select("__typename") as any
      }
}


export class SubjectSelfAccessStatus extends $Base<"SubjectSelfAccessStatus"> {
  constructor() {
    super("SubjectSelfAccessStatus")
  }

  
      
      get flag(): $Field<"flag", SubjectSelfAccessStatusFlag>  {
       return this.$_select("flag") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectSelfAccessStatus">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class SubjectsConnection extends $Base<"SubjectsConnection"> {
  constructor() {
    super("SubjectsConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<SubjectsEdge>>(selectorFn: (s: SubjectsEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectsEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<Subject>>(selectorFn: (s: Subject) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Subject)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "SubjectsConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class SubjectsEdge extends $Base<"SubjectsEdge"> {
  constructor() {
    super("SubjectsEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<Subject>>(selectorFn: (s: Subject) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Subject)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "SubjectsEdge">  {
       return this.$_select("__typename") as any
      }
}


export class Subscription extends $Base<"Subscription"> {
  constructor() {
    super("Subscription")
  }

  
      
      configExported<Sel extends Selection<ConfigurationExportResult>>(selectorFn: (s: ConfigurationExportResult) => [...Sel]):$Field<"configExported", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationExportResult)
      };
      return this.$_select("configExported", options as any) as any
    }
  

      
      configImported<Sel extends Selection<ConfigurationImportResult>>(selectorFn: (s: ConfigurationImportResult) => [...Sel]):$Field<"configImported", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationImportResult)
      };
      return this.$_select("configImported", options as any) as any
    }
  

      
      formCreated<Sel extends Selection<FormInstance>>(selectorFn: (s: FormInstance) => [...Sel]):$Field<"formCreated", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FormInstance)
      };
      return this.$_select("formCreated", options as any) as any
    }
  

      
      iFrameChanges<Sel extends Selection<FrameChangeResult>>(selectorFn: (s: FrameChangeResult) => [...Sel]):$Field<"iFrameChanges", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FrameChangeResult)
      };
      return this.$_select("iFrameChanges", options as any) as any
    }
  

      
      multiSubjectViewDataUpdated<Args extends VariabledInput<{
        dashboardId: string
structuralEntityKey?: string | null | undefined,
      }>,Sel extends Selection<VisualisationDataUpdatedMessage>>(args: ExactArgNames<Args, {
        dashboardId: string
structuralEntityKey?: string | null | undefined,
      }>, selectorFn: (s: VisualisationDataUpdatedMessage) => [...Sel]):$Field<"multiSubjectViewDataUpdated", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              dashboardId: "ID!",
structuralEntityKey: "ID"
            },
        args,

        selection: selectorFn(new VisualisationDataUpdatedMessage)
      };
      return this.$_select("multiSubjectViewDataUpdated", options as any) as any
    }
  

      
      visualisationDataUpdated<Args extends VariabledInput<{
        visualisationId: string
context: WidgetContextInput,
      }>,Sel extends Selection<VisualisationDataUpdatedMessage>>(args: ExactArgNames<Args, {
        visualisationId: string
context: WidgetContextInput,
      }>, selectorFn: (s: VisualisationDataUpdatedMessage) => [...Sel]):$Field<"visualisationDataUpdated", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              visualisationId: "ID!",
context: "WidgetContextInput!"
            },
        args,

        selection: selectorFn(new VisualisationDataUpdatedMessage)
      };
      return this.$_select("visualisationDataUpdated", options as any) as any
    }
  

      
      visualisationPendingUpdate<Args extends VariabledInput<{
        visualisationId: string
context: WidgetContextInput,
      }>,Sel extends Selection<VisualisationPendingUpdateMessage>>(args: ExactArgNames<Args, {
        visualisationId: string
context: WidgetContextInput,
      }>, selectorFn: (s: VisualisationPendingUpdateMessage) => [...Sel]):$Field<"visualisationPendingUpdate", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              visualisationId: "ID!",
context: "WidgetContextInput!"
            },
        args,

        selection: selectorFn(new VisualisationPendingUpdateMessage)
      };
      return this.$_select("visualisationPendingUpdate", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "Subscription">  {
       return this.$_select("__typename") as any
      }
}


export class TableDisplayOption extends $Base<"TableDisplayOption"> {
  constructor() {
    super("TableDisplayOption")
  }

  
      
      get showColumnHeadings(): $Field<"showColumnHeadings", boolean>  {
       return this.$_select("showColumnHeadings") as any
      }

      
      get visualisationType(): $Field<"visualisationType", VisualisationType>  {
       return this.$_select("visualisationType") as any
      }

      
      get __typename(): $Field<"__typename", "TableDisplayOption">  {
       return this.$_select("__typename") as any
      }
}


export class Tenant extends $Base<"Tenant"> {
  constructor() {
    super("Tenant")
  }

  
      
      availableConfigurations<Sel extends Selection<ConfigurationFile>>(selectorFn: (s: ConfigurationFile) => [...Sel]):$Field<"availableConfigurations", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ConfigurationFile)
      };
      return this.$_select("availableConfigurations", options as any) as any
    }
  

      
      availableForms<Sel extends Selection<AvailableForm>>(selectorFn: (s: AvailableForm) => [...Sel]):$Field<"availableForms", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AvailableForm)
      };
      return this.$_select("availableForms", options as any) as any
    }
  

      
      get avatarIsEnabled(): $Field<"avatarIsEnabled", boolean>  {
       return this.$_select("avatarIsEnabled") as any
      }

      
      get createdAt(): $Field<"createdAt", DateTime>  {
       return this.$_select("createdAt") as any
      }

      
      customFields<Sel extends Selection<TenantCustomFields>>(selectorFn: (s: TenantCustomFields) => [...Sel]):$Field<"customFields", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TenantCustomFields)
      };
      return this.$_select("customFields", options as any) as any
    }
  

      
      dashboard<Args extends VariabledInput<{
        id: string,
      }>,Sel extends Selection<Dashboard>>(args: ExactArgNames<Args, {
        id: string,
      }>, selectorFn: (s: Dashboard) => [...Sel]):$Field<"dashboard", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!"
            },
        args,

        selection: selectorFn(new Dashboard)
      };
      return this.$_select("dashboard", options as any) as any
    }
  

      
      dashboards<Args extends VariabledInput<{
        filter?: DashboardFilterInput | null | undefined,
      }>,Sel extends Selection<Dashboard>>(args: ExactArgNames<Args, {
        filter?: DashboardFilterInput | null | undefined,
      }>, selectorFn: (s: Dashboard) => [...Sel]):$Field<"dashboards", Array<GetOutput<Sel>> , GetVariables<Sel, Args>>
dashboards<Sel extends Selection<Dashboard>>(selectorFn: (s: Dashboard) => [...Sel]):$Field<"dashboards", Array<GetOutput<Sel>> , GetVariables<Sel>>
dashboards(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              filter: "DashboardFilterInput"
            },
        args,

        selection: selectorFn(new Dashboard)
      };
      return this.$_select("dashboards", options as any) as any
    }
  

      
      dataExtract<Args extends VariabledInput<{
        id: string,
      }>,Sel extends Selection<DataExtract>>(args: ExactArgNames<Args, {
        id: string,
      }>, selectorFn: (s: DataExtract) => [...Sel]):$Field<"dataExtract", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!"
            },
        args,

        selection: selectorFn(new DataExtract)
      };
      return this.$_select("dataExtract", options as any) as any
    }
  

      
      dataExtracts<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
dataExtractFilter?: DataExtractFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<DataExtractsConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
dataExtractFilter?: DataExtractFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: DataExtractsConnection) => [...Sel]):$Field<"dataExtracts", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
dataExtracts<Sel extends Selection<DataExtractsConnection>>(selectorFn: (s: DataExtractsConnection) => [...Sel]):$Field<"dataExtracts", GetOutput<Sel> | undefined , GetVariables<Sel>>
dataExtracts(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
dataExtractFilter: "DataExtractFilterInput",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new DataExtractsConnection)
      };
      return this.$_select("dataExtracts", options as any) as any
    }
  

      
      dataSourceInstance<Args extends VariabledInput<{
        id: string,
      }>,Sel extends Selection<DataSourceInstance>>(args: ExactArgNames<Args, {
        id: string,
      }>, selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"dataSourceInstance", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              id: "ID!"
            },
        args,

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("dataSourceInstance", options as any) as any
    }
  

      
      dataSourceInstances<Args extends VariabledInput<{
        capabilities?: Readonly<Array<DataSourceCapability | null>> | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<DataSourceInstancesConnection>>(args: ExactArgNames<Args, {
        capabilities?: Readonly<Array<DataSourceCapability | null>> | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: DataSourceInstancesConnection) => [...Sel]):$Field<"dataSourceInstances", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
dataSourceInstances<Sel extends Selection<DataSourceInstancesConnection>>(selectorFn: (s: DataSourceInstancesConnection) => [...Sel]):$Field<"dataSourceInstances", GetOutput<Sel> | undefined , GetVariables<Sel>>
dataSourceInstances(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              capabilities: "[DataSourceCapability]",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new DataSourceInstancesConnection)
      };
      return this.$_select("dataSourceInstances", options as any) as any
    }
  

      
      dataSources<Args extends VariabledInput<{
        onlyAddable?: boolean | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<DataSourcesConnection>>(args: ExactArgNames<Args, {
        onlyAddable?: boolean | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: DataSourcesConnection) => [...Sel]):$Field<"dataSources", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
dataSources<Sel extends Selection<DataSourcesConnection>>(selectorFn: (s: DataSourcesConnection) => [...Sel]):$Field<"dataSources", GetOutput<Sel> | undefined , GetVariables<Sel>>
dataSources(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              onlyAddable: "Boolean",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new DataSourcesConnection)
      };
      return this.$_select("dataSources", options as any) as any
    }
  

      
      get displayName(): $Field<"displayName", string>  {
       return this.$_select("displayName") as any
      }

      
      get environmentKey(): $Field<"environmentKey", string>  {
       return this.$_select("environmentKey") as any
      }

      
      features<Args extends VariabledInput<{
        tenantFeatureKeys?: Readonly<Array<TenantFeatureKey>> | null | undefined,
      }>,Sel extends Selection<TenantFeature>>(args: ExactArgNames<Args, {
        tenantFeatureKeys?: Readonly<Array<TenantFeatureKey>> | null | undefined,
      }>, selectorFn: (s: TenantFeature) => [...Sel]):$Field<"features", Array<GetOutput<Sel>> , GetVariables<Sel, Args>>
features<Sel extends Selection<TenantFeature>>(selectorFn: (s: TenantFeature) => [...Sel]):$Field<"features", Array<GetOutput<Sel>> , GetVariables<Sel>>
features(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              tenantFeatureKeys: "[TenantFeatureKey!]"
            },
        args,

        selection: selectorFn(new TenantFeature)
      };
      return this.$_select("features", options as any) as any
    }
  

      
      group<Args extends VariabledInput<{
        key: string,
      }>,Sel extends Selection<Group>>(args: ExactArgNames<Args, {
        key: string,
      }>, selectorFn: (s: Group) => [...Sel]):$Field<"group", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              key: "ID!"
            },
        args,

        selection: selectorFn(new Group)
      };
      return this.$_select("group", options as any) as any
    }
  

      
      groups<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: GroupFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<GroupsConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: GroupFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: GroupsConnection) => [...Sel]):$Field<"groups", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
groups<Sel extends Selection<GroupsConnection>>(selectorFn: (s: GroupsConnection) => [...Sel]):$Field<"groups", GetOutput<Sel> | undefined , GetVariables<Sel>>
groups(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
filter: "GroupFilterInput",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new GroupsConnection)
      };
      return this.$_select("groups", options as any) as any
    }
  

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      inboundSubjectConfiguration<Sel extends Selection<InboundSubjectConfiguration>>(selectorFn: (s: InboundSubjectConfiguration) => [...Sel]):$Field<"inboundSubjectConfiguration", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new InboundSubjectConfiguration)
      };
      return this.$_select("inboundSubjectConfiguration", options as any) as any
    }
  

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get locationName(): $Field<"locationName", string>  {
       return this.$_select("locationName") as any
      }

      
      multiSubjectView<Args extends VariabledInput<{
        input: MultiSubjectViewInput,
      }>,Sel extends Selection<MultiSubjectViewRequest>>(args: ExactArgNames<Args, {
        input: MultiSubjectViewInput,
      }>, selectorFn: (s: MultiSubjectViewRequest) => [...Sel]):$Field<"multiSubjectView", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "MultiSubjectViewInput!"
            },
        args,

        selection: selectorFn(new MultiSubjectViewRequest)
      };
      return this.$_select("multiSubjectView", options as any) as any
    }
  

      
      repositorySettings<Sel extends Selection<RepositorySettings>>(selectorFn: (s: RepositorySettings) => [...Sel]):$Field<"repositorySettings", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new RepositorySettings)
      };
      return this.$_select("repositorySettings", options as any) as any
    }
  

      
      sectionConfiguration<Args extends VariabledInput<{
        section: Section,
      }>,Sel extends Selection<SectionConfiguration>>(args: ExactArgNames<Args, {
        section: Section,
      }>, selectorFn: (s: SectionConfiguration) => [...Sel]):$Field<"sectionConfiguration", GetOutput<Sel> , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              section: "Section!"
            },
        args,

        selection: selectorFn(new SectionConfiguration)
      };
      return this.$_select("sectionConfiguration", options as any) as any
    }
  

      
      get showRoles(): $Field<"showRoles", boolean>  {
       return this.$_select("showRoles") as any
      }

      
      structuralEntities<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: StructuralEntityFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<StructuralEntitiesConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: StructuralEntityFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: StructuralEntitiesConnection) => [...Sel]):$Field<"structuralEntities", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
structuralEntities<Sel extends Selection<StructuralEntitiesConnection>>(selectorFn: (s: StructuralEntitiesConnection) => [...Sel]):$Field<"structuralEntities", GetOutput<Sel> | undefined , GetVariables<Sel>>
structuralEntities(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
filter: "StructuralEntityFilterInput",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new StructuralEntitiesConnection)
      };
      return this.$_select("structuralEntities", options as any) as any
    }
  

      
      structureType<Args extends VariabledInput<{
        key: string,
      }>,Sel extends Selection<StructureType>>(args: ExactArgNames<Args, {
        key: string,
      }>, selectorFn: (s: StructureType) => [...Sel]):$Field<"structureType", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              key: "ID!"
            },
        args,

        selection: selectorFn(new StructureType)
      };
      return this.$_select("structureType", options as any) as any
    }
  

      
      structureTypes<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<StructureTypesConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: StructureTypesConnection) => [...Sel]):$Field<"structureTypes", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
structureTypes<Sel extends Selection<StructureTypesConnection>>(selectorFn: (s: StructureTypesConnection) => [...Sel]):$Field<"structureTypes", GetOutput<Sel> | undefined , GetVariables<Sel>>
structureTypes(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new StructureTypesConnection)
      };
      return this.$_select("structureTypes", options as any) as any
    }
  

      
      subjectAccessConfiguration<Sel extends Selection<SubjectAccessConfiguration>>(selectorFn: (s: SubjectAccessConfiguration) => [...Sel]):$Field<"subjectAccessConfiguration", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectAccessConfiguration)
      };
      return this.$_select("subjectAccessConfiguration", options as any) as any
    }
  

      
      get subjectName(): $Field<"subjectName", string>  {
       return this.$_select("subjectName") as any
      }

      
      get subjectNotLinkedErrorMessage(): $Field<"subjectNotLinkedErrorMessage", string>  {
       return this.$_select("subjectNotLinkedErrorMessage") as any
      }

      
      subjects<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: SubjectFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<SubjectsConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: SubjectFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: SubjectsConnection) => [...Sel]):$Field<"subjects", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
subjects<Sel extends Selection<SubjectsConnection>>(selectorFn: (s: SubjectsConnection) => [...Sel]):$Field<"subjects", GetOutput<Sel> | undefined , GetVariables<Sel>>
subjects(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
filter: "SubjectFilterInput",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new SubjectsConnection)
      };
      return this.$_select("subjects", options as any) as any
    }
  

      
      tenantSubjectProvider<Sel extends Selection<TenantSubjectProvider>>(selectorFn: (s: TenantSubjectProvider) => [...Sel]):$Field<"tenantSubjectProvider", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TenantSubjectProvider)
      };
      return this.$_select("tenantSubjectProvider", options as any) as any
    }
  

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      user<Args extends VariabledInput<{
        userId: string,
      }>,Sel extends Selection<TenantUser>>(args: ExactArgNames<Args, {
        userId: string,
      }>, selectorFn: (s: TenantUser) => [...Sel]):$Field<"user", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              userId: "ID!"
            },
        args,

        selection: selectorFn(new TenantUser)
      };
      return this.$_select("user", options as any) as any
    }
  

      
      users<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: UserFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<UsersConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: UserFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: UsersConnection) => [...Sel]):$Field<"users", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
users<Sel extends Selection<UsersConnection>>(selectorFn: (s: UsersConnection) => [...Sel]):$Field<"users", GetOutput<Sel> | undefined , GetVariables<Sel>>
users(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
filter: "UserFilterInput",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new UsersConnection)
      };
      return this.$_select("users", options as any) as any
    }
  

      
      widgets<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: WidgetFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<WidgetsConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter?: WidgetFilterInput | null | undefined
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: WidgetsConnection) => [...Sel]):$Field<"widgets", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
widgets<Sel extends Selection<WidgetsConnection>>(selectorFn: (s: WidgetsConnection) => [...Sel]):$Field<"widgets", GetOutput<Sel> | undefined , GetVariables<Sel>>
widgets(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
filter: "WidgetFilterInput",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new WidgetsConnection)
      };
      return this.$_select("widgets", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "Tenant">  {
       return this.$_select("__typename") as any
      }
}


export class TenantCustomFieldGroup extends $Base<"TenantCustomFieldGroup"> {
  constructor() {
    super("TenantCustomFieldGroup")
  }

  
      
      get bannerPositionIndex(): $Field<"bannerPositionIndex", number>  {
       return this.$_select("bannerPositionIndex") as any
      }

      
      customFields<Sel extends Selection<CustomField>>(selectorFn: (s: CustomField) => [...Sel]):$Field<"customFields", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new CustomField)
      };
      return this.$_select("customFields", options as any) as any
    }
  

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get name(): $Field<"name", string>  {
       return this.$_select("name") as any
      }

      
      get showByDefault(): $Field<"showByDefault", boolean>  {
       return this.$_select("showByDefault") as any
      }

      
      get __typename(): $Field<"__typename", "TenantCustomFieldGroup">  {
       return this.$_select("__typename") as any
      }
}


export class TenantCustomFields extends $Base<"TenantCustomFields"> {
  constructor() {
    super("TenantCustomFields")
  }

  
      
      groups<Sel extends Selection<TenantCustomFieldGroup>>(selectorFn: (s: TenantCustomFieldGroup) => [...Sel]):$Field<"groups", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TenantCustomFieldGroup)
      };
      return this.$_select("groups", options as any) as any
    }
  

      
      hiddenFields<Sel extends Selection<CustomField>>(selectorFn: (s: CustomField) => [...Sel]):$Field<"hiddenFields", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new CustomField)
      };
      return this.$_select("hiddenFields", options as any) as any
    }
  

      
      primaryField<Sel extends Selection<CustomField>>(selectorFn: (s: CustomField) => [...Sel]):$Field<"primaryField", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new CustomField)
      };
      return this.$_select("primaryField", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "TenantCustomFields">  {
       return this.$_select("__typename") as any
      }
}


export class TenantFeature extends $Base<"TenantFeature"> {
  constructor() {
    super("TenantFeature")
  }

  
      
      get featureKey(): $Field<"featureKey", TenantFeatureKey>  {
       return this.$_select("featureKey") as any
      }

      
      get isEnabled(): $Field<"isEnabled", boolean>  {
       return this.$_select("isEnabled") as any
      }

      
      get __typename(): $Field<"__typename", "TenantFeature">  {
       return this.$_select("__typename") as any
      }
}


export class TenantSubjectProvider extends $Base<"TenantSubjectProvider"> {
  constructor() {
    super("TenantSubjectProvider")
  }

  
      
      configuration<Sel extends Selection<SubjectProviderConfiguration>>(selectorFn: (s: SubjectProviderConfiguration) => [...Sel]):$Field<"configuration", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectProviderConfiguration)
      };
      return this.$_select("configuration", options as any) as any
    }
  

      
      dataSource<Sel extends Selection<DataSourceInstance>>(selectorFn: (s: DataSourceInstance) => [...Sel]):$Field<"dataSource", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataSourceInstance)
      };
      return this.$_select("dataSource", options as any) as any
    }
  

      
      subjectProvider<Sel extends Selection<SubjectProviderType>>(selectorFn: (s: SubjectProviderType) => [...Sel]):$Field<"subjectProvider", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SubjectProviderType)
      };
      return this.$_select("subjectProvider", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "TenantSubjectProvider">  {
       return this.$_select("__typename") as any
      }
}


export class TenantUser extends $Base<"TenantUser"> {
  constructor() {
    super("TenantUser")
  }

  
      
      accessibleStructuralEntities<Args extends VariabledInput<{
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter: StructuralEntityFilterInput
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<AccessibleStructuralEntitiesConnection>>(args: ExactArgNames<Args, {
        orderBy?: string | null | undefined
orderDirection?: ListSortDirection | null | undefined
filter: StructuralEntityFilterInput
first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: AccessibleStructuralEntitiesConnection) => [...Sel]):$Field<"accessibleStructuralEntities", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              orderBy: "String",
orderDirection: "ListSortDirection",
filter: "StructuralEntityFilterInput!",
first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new AccessibleStructuralEntitiesConnection)
      };
      return this.$_select("accessibleStructuralEntities", options as any) as any
    }
  

      
      get displayName(): $Field<"displayName", string>  {
       return this.$_select("displayName") as any
      }

      
      get email(): $Field<"email", string | null>  {
       return this.$_select("email") as any
      }

      
      get groupKeys(): $Field<"groupKeys", Readonly<Array<string>>>  {
       return this.$_select("groupKeys") as any
      }

      
      groups<Args extends VariabledInput<{
        first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<GroupsConnection>>(args: ExactArgNames<Args, {
        first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: GroupsConnection) => [...Sel]):$Field<"groups", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
groups<Sel extends Selection<GroupsConnection>>(selectorFn: (s: GroupsConnection) => [...Sel]):$Field<"groups", GetOutput<Sel> | undefined , GetVariables<Sel>>
groups(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new GroupsConnection)
      };
      return this.$_select("groups", options as any) as any
    }
  

      
      hasAccessToSubject<Args extends VariabledInput<{
        subjectId: string,
      }>>(args: ExactArgNames<Args, {
        subjectId: string,
      }>):$Field<"hasAccessToSubject", boolean , GetVariables<[], Args>> {
      
      const options = {
        argTypes: {
              subjectId: "ID!"
            },
        args,

        
      };
      return this.$_select("hasAccessToSubject", options as any) as any
    }
  

      
      get hasGlobalGroup(): $Field<"hasGlobalGroup", boolean>  {
       return this.$_select("hasGlobalGroup") as any
      }

      
      get hasSubjectPortalAccess(): $Field<"hasSubjectPortalAccess", boolean>  {
       return this.$_select("hasSubjectPortalAccess") as any
      }

      
      get id(): $Field<"id", string>  {
       return this.$_select("id") as any
      }

      
      get isEnabled(): $Field<"isEnabled", boolean>  {
       return this.$_select("isEnabled") as any
      }

      
      get isSystemUser(): $Field<"isSystemUser", boolean>  {
       return this.$_select("isSystemUser") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get lastSessionStartTime(): $Field<"lastSessionStartTime", DateTime | null>  {
       return this.$_select("lastSessionStartTime") as any
      }

      
      linkedSubjects<Args extends VariabledInput<{
        first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>,Sel extends Selection<LinkedSubjectsConnection>>(args: ExactArgNames<Args, {
        first?: number | null | undefined
after?: string | null | undefined
last?: number | null | undefined
before?: string | null | undefined,
      }>, selectorFn: (s: LinkedSubjectsConnection) => [...Sel]):$Field<"linkedSubjects", GetOutput<Sel> | undefined , GetVariables<Sel, Args>>
linkedSubjects<Sel extends Selection<LinkedSubjectsConnection>>(selectorFn: (s: LinkedSubjectsConnection) => [...Sel]):$Field<"linkedSubjects", GetOutput<Sel> | undefined , GetVariables<Sel>>
linkedSubjects(arg1: any, arg2?: any) {
      const { args, selectorFn } = !arg2 ? { args: {}, selectorFn: arg1 } : { args: arg1, selectorFn: arg2 };

      const options = {
        argTypes: {
              first: "Int",
after: "String",
last: "Int",
before: "String"
            },
        args,

        selection: selectorFn(new LinkedSubjectsConnection)
      };
      return this.$_select("linkedSubjects", options as any) as any
    }
  

      
      permissions<Sel extends Selection<AppPermission>>(selectorFn: (s: AppPermission) => [...Sel]):$Field<"permissions", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new AppPermission)
      };
      return this.$_select("permissions", options as any) as any
    }
  

      
      get tenantId(): $Field<"tenantId", Uuid>  {
       return this.$_select("tenantId") as any
      }

      
      get typeName(): $Field<"typeName", string>  {
       return this.$_select("typeName") as any
      }

      
      userDefaults<Sel extends Selection<UserDefaults>>(selectorFn: (s: UserDefaults) => [...Sel]):$Field<"userDefaults", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new UserDefaults)
      };
      return this.$_select("userDefaults", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "TenantUser">  {
       return this.$_select("__typename") as any
      }
}


export class ThirdPartyAccessConfiguration extends $Base<"ThirdPartyAccessConfiguration"> {
  constructor() {
    super("ThirdPartyAccessConfiguration")
  }

  
      
      get enabled(): $Field<"enabled", boolean>  {
       return this.$_select("enabled") as any
      }

      
      get __typename(): $Field<"__typename", "ThirdPartyAccessConfiguration">  {
       return this.$_select("__typename") as any
      }
}


export class TimeDataTypeValue extends $Base<"TimeDataTypeValue"> {
  constructor() {
    super("TimeDataTypeValue")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      get value(): $Field<"value", TimeOnly | null>  {
       return this.$_select("value") as any
      }

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "TimeDataTypeValue">  {
       return this.$_select("__typename") as any
      }
}


export class TimeDataTypeValueFilter extends $Base<"TimeDataTypeValueFilter"> {
  constructor() {
    super("TimeDataTypeValueFilter")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }

      
      get numericComparator(): $Field<"numericComparator", NumericComparator>  {
       return this.$_select("numericComparator") as any
      }

      
      get timeValue(): $Field<"timeValue", TimeOnly | null>  {
       return this.$_select("timeValue") as any
      }

      
      get __typename(): $Field<"__typename", "TimeDataTypeValueFilter">  {
       return this.$_select("__typename") as any
      }
}


export class TransformValidationResult extends $Base<"TransformValidationResult"> {
  constructor() {
    super("TransformValidationResult")
  }

  
      
      definition<Sel extends Selection<ITransformDefinition>>(selectorFn: (s: ITransformDefinition) => [...Sel]):$Field<"definition", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ITransformDefinition)
      };
      return this.$_select("definition", options as any) as any
    }
  

      
      get error(): $Field<"error", string | null>  {
       return this.$_select("error") as any
      }

      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      outputs<Sel extends Selection<TypedField>>(selectorFn: (s: TypedField) => [...Sel]):$Field<"outputs", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TypedField)
      };
      return this.$_select("outputs", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "TransformValidationResult">  {
       return this.$_select("__typename") as any
      }
}


export class TypedField extends $Base<"TypedField"> {
  constructor() {
    super("TypedField")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      get __typename(): $Field<"__typename", "TypedField">  {
       return this.$_select("__typename") as any
      }
}


export class User extends $Base<"User"> {
  constructor() {
    super("User")
  }

  
      
      get displayName(): $Field<"displayName", string>  {
       return this.$_select("displayName") as any
      }

      
      get email(): $Field<"email", string | null>  {
       return this.$_select("email") as any
      }

      
      get id(): $Field<"id", string>  {
       return this.$_select("id") as any
      }

      
      get isSystemUser(): $Field<"isSystemUser", boolean>  {
       return this.$_select("isSystemUser") as any
      }

      
      get __typename(): $Field<"__typename", "User">  {
       return this.$_select("__typename") as any
      }
}


export class UserDefaults extends $Base<"UserDefaults"> {
  constructor() {
    super("UserDefaults")
  }

  
      
      get id(): $Field<"id", string>  {
       return this.$_select("id") as any
      }

      
      sectionDashboards<Sel extends Selection<SectionDashboard>>(selectorFn: (s: SectionDashboard) => [...Sel]):$Field<"sectionDashboards", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new SectionDashboard)
      };
      return this.$_select("sectionDashboards", options as any) as any
    }
  

      
      get tenantId(): $Field<"tenantId", Uuid>  {
       return this.$_select("tenantId") as any
      }

      
      user<Sel extends Selection<User>>(selectorFn: (s: User) => [...Sel]):$Field<"user", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new User)
      };
      return this.$_select("user", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "UserDefaults">  {
       return this.$_select("__typename") as any
      }
}


export class UserIdentifier extends $Base<"UserIdentifier"> {
  constructor() {
    super("UserIdentifier")
  }

  
      
      get identifier(): $Field<"identifier", string>  {
       return this.$_select("identifier") as any
      }

      
      get userIdentifierType(): $Field<"userIdentifierType", UserIdentifierType>  {
       return this.$_select("userIdentifierType") as any
      }

      
      get __typename(): $Field<"__typename", "UserIdentifier">  {
       return this.$_select("__typename") as any
      }
}


export class UserIdentifierDataTypeValue extends $Base<"UserIdentifierDataTypeValue"> {
  constructor() {
    super("UserIdentifierDataTypeValue")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get isNull(): $Field<"isNull", boolean>  {
       return this.$_select("isNull") as any
      }

      
      value<Sel extends Selection<UserIdentifier>>(selectorFn: (s: UserIdentifier) => [...Sel]):$Field<"value", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new UserIdentifier)
      };
      return this.$_select("value", options as any) as any
    }
  

      
      get valueText(): $Field<"valueText", string | null>  {
       return this.$_select("valueText") as any
      }

      
      get __typename(): $Field<"__typename", "UserIdentifierDataTypeValue">  {
       return this.$_select("__typename") as any
      }
}


export class UserIdentifierDataTypeValueFilter extends $Base<"UserIdentifierDataTypeValueFilter"> {
  constructor() {
    super("UserIdentifierDataTypeValueFilter")
  }

  
      
      get dataType(): $Field<"dataType", DataType>  {
       return this.$_select("dataType") as any
      }

      
      get liquidExpression(): $Field<"liquidExpression", string | null>  {
       return this.$_select("liquidExpression") as any
      }

      
      userIdentifierValue<Sel extends Selection<UserIdentifier>>(selectorFn: (s: UserIdentifier) => [...Sel]):$Field<"userIdentifierValue", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new UserIdentifier)
      };
      return this.$_select("userIdentifierValue", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "UserIdentifierDataTypeValueFilter">  {
       return this.$_select("__typename") as any
      }
}


export class UserPortalAccessInviteResult extends $Base<"UserPortalAccessInviteResult"> {
  constructor() {
    super("UserPortalAccessInviteResult")
  }

  
      
      get registrationUri(): $Field<"registrationUri", URL | null>  {
       return this.$_select("registrationUri") as any
      }

      
      get subjectId(): $Field<"subjectId", string>  {
       return this.$_select("subjectId") as any
      }

      
      get userId(): $Field<"userId", string>  {
       return this.$_select("userId") as any
      }

      
      get __typename(): $Field<"__typename", "UserPortalAccessInviteResult">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class UsersConnection extends $Base<"UsersConnection"> {
  constructor() {
    super("UsersConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<UsersEdge>>(selectorFn: (s: UsersEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new UsersEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<TenantUser>>(selectorFn: (s: TenantUser) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TenantUser)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "UsersConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class UsersEdge extends $Base<"UsersEdge"> {
  constructor() {
    super("UsersEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<TenantUser>>(selectorFn: (s: TenantUser) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new TenantUser)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "UsersEdge">  {
       return this.$_select("__typename") as any
      }
}


export class ValidLiquidParseResult extends $Base<"ValidLiquidParseResult"> {
  constructor() {
    super("ValidLiquidParseResult")
  }

  
      
      identifierSet<Sel extends Selection<IIdentifier>>(selectorFn: (s: IIdentifier) => [...Sel]):$Field<"identifierSet", Array<GetOutput<Sel> | undefined> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IIdentifier)
      };
      return this.$_select("identifierSet", options as any) as any
    }
  

      
      get inputArguments(): $Field<"inputArguments", Readonly<Array<string>>>  {
       return this.$_select("inputArguments") as any
      }

      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      get outputIsArray(): $Field<"outputIsArray", boolean>  {
       return this.$_select("outputIsArray") as any
      }

      
      get outputType(): $Field<"outputType", DataType>  {
       return this.$_select("outputType") as any
      }

      
      get __typename(): $Field<"__typename", "ValidLiquidParseResult">  {
       return this.$_select("__typename") as any
      }
}


export class ValidSchemaParseResult extends $Base<"ValidSchemaParseResult"> {
  constructor() {
    super("ValidSchemaParseResult")
  }

  
      
      availableFields<Sel extends Selection<FieldMetadata>>(selectorFn: (s: FieldMetadata) => [...Sel]):$Field<"availableFields", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new FieldMetadata)
      };
      return this.$_select("availableFields", options as any) as any
    }
  

      
      get expectedSubjectIdentifier(): $Field<"expectedSubjectIdentifier", SubjectIdentifier>  {
       return this.$_select("expectedSubjectIdentifier") as any
      }

      
      get isValid(): $Field<"isValid", boolean>  {
       return this.$_select("isValid") as any
      }

      
      get __typename(): $Field<"__typename", "ValidSchemaParseResult">  {
       return this.$_select("__typename") as any
      }
}


export class Visualisation extends $Base<"Visualisation"> {
  constructor() {
    super("Visualisation")
  }

  
      
      axes<Sel extends Selection<IAxis>>(selectorFn: (s: IAxis) => [...Sel]):$Field<"axes", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IAxis)
      };
      return this.$_select("axes", options as any) as any
    }
  

      
      get dataExtractIds(): $Field<"dataExtractIds", Readonly<Array<Uuid>>>  {
       return this.$_select("dataExtractIds") as any
      }

      
      dataPoints<Args extends VariabledInput<{
        input: GetDataPointsInput,
      }>,Sel extends Selection<GetDataPointsType>>(args: ExactArgNames<Args, {
        input: GetDataPointsInput,
      }>, selectorFn: (s: GetDataPointsType) => [...Sel]):$Field<"dataPoints", GetOutput<Sel> | undefined , GetVariables<Sel, Args>> {
      
      const options = {
        argTypes: {
              input: "GetDataPointsInput!"
            },
        args,

        selection: selectorFn(new GetDataPointsType)
      };
      return this.$_select("dataPoints", options as any) as any
    }
  

      
      defaultFilter<Sel extends Selection<DataPointFilter>>(selectorFn: (s: DataPointFilter) => [...Sel]):$Field<"defaultFilter", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataPointFilter)
      };
      return this.$_select("defaultFilter", options as any) as any
    }
  

      
      defaultOrdering<Sel extends Selection<DataOrdering>>(selectorFn: (s: DataOrdering) => [...Sel]):$Field<"defaultOrdering", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new DataOrdering)
      };
      return this.$_select("defaultOrdering", options as any) as any
    }
  

      
      displayOptions<Sel extends Selection<IDisplayOptions>>(selectorFn: (s: IDisplayOptions) => [...Sel]):$Field<"displayOptions", GetOutput<Sel> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new IDisplayOptions)
      };
      return this.$_select("displayOptions", options as any) as any
    }
  

      
      get forPortal(): $Field<"forPortal", boolean>  {
       return this.$_select("forPortal") as any
      }

      
      get id(): $Field<"id", Uuid>  {
       return this.$_select("id") as any
      }

      
      inputDimensions<Sel extends Selection<InputDimension>>(selectorFn: (s: InputDimension) => [...Sel]):$Field<"inputDimensions", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new InputDimension)
      };
      return this.$_select("inputDimensions", options as any) as any
    }
  

      
      get isCacheable(): $Field<"isCacheable", boolean>  {
       return this.$_select("isCacheable") as any
      }

      
      get isCategorical(): $Field<"isCategorical", boolean>  {
       return this.$_select("isCategorical") as any
      }

      
      get key(): $Field<"key", string>  {
       return this.$_select("key") as any
      }

      
      pipelineDefinition<Sel extends Selection<PipelineDefinition>>(selectorFn: (s: PipelineDefinition) => [...Sel]):$Field<"pipelineDefinition", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PipelineDefinition)
      };
      return this.$_select("pipelineDefinition", options as any) as any
    }
  

      
      get referencedContexts(): $Field<"referencedContexts", Readonly<Array<ReferencedContext>>>  {
       return this.$_select("referencedContexts") as any
      }

      
      series<Sel extends Selection<ISeries>>(selectorFn: (s: ISeries) => [...Sel]):$Field<"series", Array<GetOutput<Sel>> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new ISeries)
      };
      return this.$_select("series", options as any) as any
    }
  

      
      get title(): $Field<"title", string>  {
       return this.$_select("title") as any
      }

      
      get type(): $Field<"type", VisualisationType>  {
       return this.$_select("type") as any
      }

      
      get __typename(): $Field<"__typename", "Visualisation">  {
       return this.$_select("__typename") as any
      }
}


export class VisualisationDataUpdatedMessage extends $Base<"VisualisationDataUpdatedMessage"> {
  constructor() {
    super("VisualisationDataUpdatedMessage")
  }

  
      
      get subjectId(): $Field<"subjectId", Uuid | null>  {
       return this.$_select("subjectId") as any
      }

      
      get visualisationId(): $Field<"visualisationId", Uuid>  {
       return this.$_select("visualisationId") as any
      }

      
      get __typename(): $Field<"__typename", "VisualisationDataUpdatedMessage">  {
       return this.$_select("__typename") as any
      }
}


export class VisualisationPendingUpdateMessage extends $Base<"VisualisationPendingUpdateMessage"> {
  constructor() {
    super("VisualisationPendingUpdateMessage")
  }

  
      
      get isPending(): $Field<"isPending", boolean>  {
       return this.$_select("isPending") as any
      }

      
      get __typename(): $Field<"__typename", "VisualisationPendingUpdateMessage">  {
       return this.$_select("__typename") as any
      }
}


/**
 * A connection to a list of items.
 */
export class WidgetsConnection extends $Base<"WidgetsConnection"> {
  constructor() {
    super("WidgetsConnection")
  }

  
      
/**
 * A list of edges.
 */
      edges<Sel extends Selection<WidgetsEdge>>(selectorFn: (s: WidgetsEdge) => [...Sel]):$Field<"edges", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new WidgetsEdge)
      };
      return this.$_select("edges", options as any) as any
    }
  

      
/**
 * A flattened list of the nodes.
 */
      nodes<Sel extends Selection<Widget>>(selectorFn: (s: Widget) => [...Sel]):$Field<"nodes", Array<GetOutput<Sel>> | undefined , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Widget)
      };
      return this.$_select("nodes", options as any) as any
    }
  

      
/**
 * Information to aid in pagination.
 */
      pageInfo<Sel extends Selection<PageInfo>>(selectorFn: (s: PageInfo) => [...Sel]):$Field<"pageInfo", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new PageInfo)
      };
      return this.$_select("pageInfo", options as any) as any
    }
  

      
/**
 * Identifies the total count of items in the connection.
 */
      get totalCount(): $Field<"totalCount", number>  {
       return this.$_select("totalCount") as any
      }

      
      get __typename(): $Field<"__typename", "WidgetsConnection">  {
       return this.$_select("__typename") as any
      }
}


/**
 * An edge in a connection.
 */
export class WidgetsEdge extends $Base<"WidgetsEdge"> {
  constructor() {
    super("WidgetsEdge")
  }

  
      
/**
 * A cursor for use in pagination.
 */
      get cursor(): $Field<"cursor", string>  {
       return this.$_select("cursor") as any
      }

      
/**
 * The item at the end of the edge.
 */
      node<Sel extends Selection<Widget>>(selectorFn: (s: Widget) => [...Sel]):$Field<"node", GetOutput<Sel> , GetVariables<Sel>> {
      
      const options = {
        
        

        selection: selectorFn(new Widget)
      };
      return this.$_select("node", options as any) as any
    }
  

      
      get __typename(): $Field<"__typename", "WidgetsEdge">  {
       return this.$_select("__typename") as any
      }
}


export type AggregateDefinitionInput = {
  id: Uuid,
inputKeys: Readonly<Array<string>>,
outputKey: string,
type: AggregateType
}
    


export type AxisDTOInput = {
  id: Uuid,
label: string,
maxValue?: string | null | undefined,
minValue?: string | null | undefined,
series: Readonly<Array<AxisSeriesDTOInput>>,
stepValue?: string | null | undefined,
type: AxisType
}
    


export type AxisSeriesDTOInput = {
  key: string
}
    


export type BooleanDataTypeValueFilterInput = {
  booleanValue?: boolean | null | undefined,
liquidExpression?: string | null | undefined
}
    


export type BooleanValueInput = {
  value?: boolean | null | undefined
}
    


export type BundledDataSourceInstanceInput = {
  configurationValueReferences: Readonly<Array<KeyValuePairOfStringAndStringInput | null>>,
dataSourceKey: string,
key: string,
name: string
}
    


export type CreateSubjectFormInput = {
  formKey: string,
formVersion?: number | null | undefined
}
    


export type CreateSubjectInput = {
  customFields: Readonly<Array<CustomFieldInput>>,
structuralEntityKeys: Readonly<Array<string>>
}
    


export type CustomDataSourceInstanceInput = {
  clientId: string,
clientSecretReference: string,
configurationValueReferences: Readonly<Array<KeyValuePairOfStringAndStringInput | null>>,
endpoint: URL,
key: string,
name: string,
scope: string
}
    


export type CustomFieldGroupInput = {
  bannerPositionIndex: number,
customFields: Readonly<Array<SubjectBannerItemInput>>,
key: string,
name: string,
showByDefault: boolean
}
    


export type CustomFieldInput = {
  fieldKey: string,
value: CustomFieldValueInput
}
    


export type CustomFieldValueInput = {
  arrayValue?: Readonly<Array<ValueInput>> | null | undefined,
singleValue?: ValueInput | null | undefined
}
    


export type DashboardFilterInput = {
  forPortal?: boolean | null | undefined,
ids?: Readonly<Array<Uuid>> | null | undefined
}
    


export type DashboardInput = {
  forPortal?: boolean | null | undefined,
items: Readonly<Array<DashboardItemSettingsInput>>,
key: string,
title: string
}
    


export type DashboardItemSettingsInput = {
  id: Uuid,
positionIndex: number
}
    


export type DataExtractDimensionInput = {
  dataExtractId: Uuid,
key: string
}
    


export type DataExtractFilterInput = {
  dataSourceInstanceIds?: Readonly<Array<Uuid>> | null | undefined,
searchTerm?: string | null | undefined
}
    


export type DataExtractInput = {
  dataSourceId: Uuid,
description?: string | null | undefined,
key: string,
providerJSON: string,
subjectIdentifierConfiguration?: SubjectIdentifierConfigurationInput | null | undefined,
title: string
}
    


export type DataOrderingInput = {
  key: string,
orderDirection: ListSortDirection
}
    


export type DataPointFilterInput = {
  and: Readonly<Array<OrFilterInput>>
}
    


export type DataTypeWrapperInput = {
  dataType: DataType
}
    


export type DateDataTypeValueFilterInput = {
  dateValue?: DateOnly | null | undefined,
liquidExpression?: string | null | undefined,
numericComparator: NumericComparator
}
    


export type DateTimeDataTypeValueFilterInput = {
  dateTimeValue?: DateTime | null | undefined,
liquidExpression?: string | null | undefined,
numericComparator: NumericComparator
}
    


export type DateTimeValueInput = {
  value?: DateTime | null | undefined
}
    


export type DateValueInput = {
  value?: DateOnly | null | undefined
}
    


export type DecimalDataTypeValueFilterInput = {
  decimalValue?: number | null | undefined,
liquidExpression?: string | null | undefined,
numericComparator: NumericComparator
}
    


export type DecimalValueInput = {
  value?: number | null | undefined
}
    


export type DisplayOptionsInput = {
  graphChartDisplayOptions?: GraphChartDisplayOptionsInput | null | undefined,
liquidDisplayOptions?: LiquidDisplayOptionsInput | null | undefined,
pieChartDisplayOptions?: PieChartDisplayOptionsInput | null | undefined,
tableDisplayOptions?: TableDisplayOptionsInput | null | undefined
}
    


export type FilterInput = {
  boolean?: BooleanDataTypeValueFilterInput | null | undefined,
date?: DateDataTypeValueFilterInput | null | undefined,
dateTime?: DateTimeDataTypeValueFilterInput | null | undefined,
decimal?: DecimalDataTypeValueFilterInput | null | undefined,
fieldId: number,
fieldKey: string,
integer?: IntegerDataTypeValueFilterInput | null | undefined,
string?: StringDataTypeValueFilterInput | null | undefined,
time?: TimeDataTypeValueFilterInput | null | undefined,
userIdentifier?: UserIdentifierDataTypeValueFilterInput | null | undefined,
visualisationId?: Uuid | null | undefined
}
    


export type GetDataPointsInput = {
  after?: string | null | undefined,
before?: string | null | undefined,
context: WidgetContextInput,
filter?: DataPointFilterInput | null | undefined,
first?: number | null | undefined,
last?: number | null | undefined,
orderBy?: string | null | undefined,
orderDirection?: ListSortDirection | null | undefined
}
    


export type GetSubjectInput = {
  externalId?: string | null | undefined,
id?: string | null | undefined
}
    


export type GraphChartDisplayOptionsInput = {
  liquidColourFormatting: Readonly<Array<KeyValuePairOfStringAndStringInput>>
}
    


export type GroupDefinitionInput = {
  aggregators: Readonly<Array<AggregateDefinitionInput>>,
groupByKeys: Readonly<Array<string>>
}
    


export type GroupFilterInput = {
  searchTerm: string
}
    


export type GroupInput = {
  key: string,
name: string,
structuralEntityKeys: Readonly<Array<string>>
}
    


export type IFrameInput = {
  forPortal?: boolean | null | undefined,
key: string,
sendBearerAsAQueryParameter?: boolean | null | undefined,
title: string,
uri: string
}
    


export type InboundSubjectConfigurationInput = {
  existingSubjectOverrideLocationBehaviour: OverrideLocationBehaviour,
matchingInputFieldIds: Readonly<Array<Uuid>>,
newSubjectOverrideLocationBehaviour: OverrideLocationBehaviour,
unmatchedStructuralEntityKey: string
}
    


export type InputDimensionInput = {
  collatedDimensions: Readonly<Array<DataExtractDimensionInput>>,
dataType: DataType,
key: string
}
    


export type InputFieldInput = {
  formPositionIndex: number,
isArray?: boolean,
key: string,
name: string,
options?: Readonly<Array<string>> | null | undefined,
required: boolean,
typeKey: FieldTypeKey
}
    


export type InputFieldResponseInput = {
  key: string,
name: string,
required: boolean,
type: FieldTypeKey,
value: ValueInput
}
    


export type IntegerDataTypeValueFilterInput = {
  integerValue?: number | null | undefined,
liquidExpression?: string | null | undefined,
numericComparator: NumericComparator
}
    


export type IntegerValueInput = {
  value?: number | null | undefined
}
    


export type InviteToSubjectPortalInput = {
  input: PortalInviteInput,
subjectId: string
}
    


export type KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndDataTypeWrapperInput = {
  key: string,
value: NestedKeyValuePairValueOfStringAndDataTypeWrapperInput
}
    


export type KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndValueInput = {
  key: string,
value: NestedKeyValuePairValueOfStringAndValueInput
}
    


export type KeyValuePairOfStringAndStringInput = {
  key: string,
value?: string | null | undefined
}
    


export type KeyValuePairOfStringAndValueInput = {
  key: string,
value: ValueInput
}
    


export type LiquidDisplayOptionsInput = {
  liquidHtml: string
}
    


export type LiquidFilterDefinitionInput = {
  expression: string,
id: Uuid
}
    


export type LiquidMappingDefinitionInput = {
  expression: string,
id: Uuid,
outputKey: string
}
    


export type MultiSubjectViewInput = {
  dashboardId?: string | null | undefined,
dataPointFilterInput?: DataPointFilterInput | null | undefined,
subjectFilterInput?: SubjectFilterInput | null | undefined
}
    


export type NestedKeyValuePairValueOfStringAndDataTypeWrapperInput = {
  nestedValue?: Readonly<Array<KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndDataTypeWrapperInput>> | null | undefined,
value?: DataTypeWrapperInput | null | undefined
}
    


export type NestedKeyValuePairValueOfStringAndValueInput = {
  nestedValue?: Readonly<Array<KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndValueInput>> | null | undefined,
value?: ValueInput | null | undefined
}
    


export type OrFilterInput = {
  or: Readonly<Array<FilterInput>>
}
    


export type PerformActionInput = {
  description: string,
inputFields: Readonly<Array<InputFieldResponseInput>>,
key: string,
name: string,
type: DataPointActionType
}
    


export type PieChartDisplayOptionsInput = {
  liquidColourFormatting: Readonly<Array<KeyValuePairOfStringAndStringInput>>,
showLabel: boolean,
showLegend: boolean,
style: PieChartStyle
}
    


export type PipelineDefinitionInput = {
  aggregators: Readonly<Array<AggregateDefinitionInput>>,
filters: Readonly<Array<LiquidFilterDefinitionInput>>,
group?: GroupDefinitionInput | null | undefined,
mappers: Readonly<Array<LiquidMappingDefinitionInput>>
}
    


export type PortalInviteInput = {
  selfAccess?: SelfAccessInput | null | undefined,
thirdPartyUser?: ThirdPartyInput | null | undefined
}
    


export type ReferenceValueDTOInput = {
  label: string,
value: string
}
    


export type RemoveFromSubjectPortalInput = {
  subjectId: string,
userId: string
}
    


export type RepositorySettingsInput = {
  branchReference: string,
configurationDirectory: string,
passwordReference?: string | null | undefined,
sshPrivateKeyReference?: string | null | undefined,
uriReference: string,
usernameReference?: string | null | undefined
}
    


export type SectionConfigurationInput = {
  dashboardIds: Readonly<Array<Uuid>>,
defaultDashboardId?: Uuid | null | undefined,
section: Section
}
    


export type SelfAccessConfigurationInput = {
  emailLinkingFieldId?: Uuid | null | undefined,
enabled: boolean
}
    


export type SelfAccessInput = {
  customFieldKey?: string | null | undefined
}
    


export type SeriesDTOInput = {
  chartType?: ChartType | null | undefined,
colour?: string | null | undefined,
dataType: DataType,
hidden: boolean,
key: string,
liquidFormatter?: string | null | undefined,
name: string,
order: number,
referenceValues: Readonly<Array<ReferenceValueDTOInput>>
}
    


export type StringDataTypeValueFilterInput = {
  liquidExpression?: string | null | undefined,
stringComparator: StringComparator,
stringValue?: string | null | undefined
}
    


export type StringValueInput = {
  value?: string | null | undefined
}
    


export type StructuralEntityFilterInput = {
  depth?: number | null | undefined,
parentKey?: string | null | undefined,
searchTerm?: string | null | undefined,
structureTypeKey?: string | null | undefined,
subjectAssignable?: boolean | null | undefined
}
    


export type StructuralEntityInput = {
  customFieldValues?: Readonly<Array<KeyValuePairOfStringAndValueInput>> | null | undefined,
displayName: string,
key: string,
parentKey?: string | null | undefined,
structureTypeKey: string
}
    


export type StructureTypeInput = {
  customFields: Readonly<Array<InputFieldInput>>,
key: string,
name: string,
subjectAssignable: boolean
}
    


export type SubjectAccessConfigurationInput = {
  selfAccessConfiguration: SelfAccessConfigurationInput,
subjectNotLinkedErrorMessage: string,
thirdPartyConfiguration: ThirdPartyConfigurationInput
}
    


export type SubjectAttachmentInput = {
  effectiveDate: DateTime,
fileId: string,
title: string
}
    


export type SubjectBannerItemInput = {
  customFieldKey: string,
positionIndex?: number | null | undefined
}
    


export type SubjectFilterInput = {
  searchTerm?: string | null | undefined,
structuralEntityKey?: string | null | undefined
}
    


export type SubjectIdentifierConfigurationInput = {
  customFieldKey?: string | null | undefined,
useExternalId: boolean,
useInternalId: boolean
}
    


export type SubjectInput = {
  customFields: Readonly<Array<CustomFieldInput>>
}
    


export type TableDisplayOptionsInput = {
  showColumnHeadings: boolean
}
    


export type TenantComputedFieldInput = {
  id?: Uuid | null | undefined,
key: string,
liquidMapping: string,
name: string
}
    


export type TenantInputFieldInput = {
  formPositionIndex: number,
id?: Uuid | null | undefined,
isArray?: boolean,
key: string,
mapping?: string | null | undefined,
name: string,
options?: Readonly<Array<string>> | null | undefined,
required: boolean,
typeKey: FieldTypeKey,
unique: boolean
}
    


export type ThirdPartyConfigurationInput = {
  enabled: boolean
}
    


export type ThirdPartyInput = {
  email: string
}
    


export type TimeDataTypeValueFilterInput = {
  liquidExpression?: string | null | undefined,
numericComparator: NumericComparator,
timeValue?: TimeOnly | null | undefined
}
    


export type TimeValueInput = {
  value?: TimeOnly | null | undefined
}
    


export type UpdateTenantInput = {
  avatarIsEnabled: boolean,
computedFields: Readonly<Array<TenantComputedFieldInput>>,
customFieldGroups: Readonly<Array<CustomFieldGroupInput>>,
externalIdMapping?: string | null | undefined,
inputFields: Readonly<Array<TenantInputFieldInput>>,
liquidLookupExpression?: string | null | undefined,
locationName: string,
primaryFieldKey: string,
showRoles: boolean,
subjectDataSourceId: Uuid,
subjectName: string
}
    


export type UpdateUserGroupsInput = {
  groupKeysToAdd: Readonly<Array<string>>,
groupKeysToRemove: Readonly<Array<string>>,
userId: string
}
    


export type UserFilterInput = {
  includeInactive?: boolean,
searchTerm?: string | null | undefined,
withAccessToSubjectId?: Uuid | null | undefined
}
    


export type UserIdentifierDataTypeValueFilterInput = {
  liquidExpression?: string | null | undefined,
userIdentifierValue?: UserIdentifierInput | null | undefined
}
    


export type UserIdentifierInput = {
  identifier: string,
userIdentifierType: UserIdentifierType
}
    


export type UserIdentifierValueInput = {
  value?: UserIdentifierInput | null | undefined
}
    


export type ValueInput = {
  boolean?: BooleanValueInput | null | undefined,
date?: DateValueInput | null | undefined,
dateTime?: DateTimeValueInput | null | undefined,
decimal?: DecimalValueInput | null | undefined,
integer?: IntegerValueInput | null | undefined,
string?: StringValueInput | null | undefined,
time?: TimeValueInput | null | undefined,
userIdentifier?: UserIdentifierValueInput | null | undefined
}
    


export type VisualisationInput = {
  axes: Readonly<Array<AxisDTOInput>>,
defaultFilter?: DataPointFilterInput | null | undefined,
defaultOrdering: DataOrderingInput,
displayOptions?: DisplayOptionsInput | null | undefined,
forPortal?: boolean | null | undefined,
inputDimensions: Readonly<Array<InputDimensionInput>>,
key: string,
pipelineDefinition: PipelineDefinitionInput,
series: Readonly<Array<SeriesDTOInput>>,
title: string,
type: VisualisationType
}
    


export type WidgetContextInput = {
  structuralEntityKey?: string | null | undefined,
subjectId?: string | null | undefined
}
    


export type WidgetFilterInput = {
  forPortal?: boolean | null | undefined,
ids?: Readonly<Array<Uuid>> | null | undefined,
searchTerm?: string | null | undefined
}
    

  
export enum ActionType {
  
  LIST = "LIST",

  VIEW = "VIEW",

  CREATE = "CREATE",

  EDIT = "EDIT",

  DELETE = "DELETE",

  EXECUTE = "EXECUTE",

  ACCESS = "ACCESS",

  SET = "SET"
}
  

  
export enum AggregateType {
  
  COUNT = "COUNT",

  SUM = "SUM",

  MEAN = "MEAN",

  MEDIAN = "MEDIAN",

  MODE = "MODE",

  MAX = "MAX",

  MIN = "MIN",

  MIN_BY = "MIN_BY",

  MAX_BY = "MAX_BY"
}
  

  
/**
 * Defines when a policy shall be executed.
 */
export enum ApplyPolicy {
  
/**
 * Before the resolver was executed.
 */
  BEFORE_RESOLVER = "BEFORE_RESOLVER",

/**
 * After the resolver was executed.
 */
  AFTER_RESOLVER = "AFTER_RESOLVER",

/**
 * The policy is applied in the validation step before the execution.
 */
  VALIDATION = "VALIDATION"
}
  

  
export enum AxisType {
  
  X = "X",

  Y = "Y",

  Z = "Z",

  RADIAL = "RADIAL"
}
  

  
export enum ChartType {
  
  LINE = "LINE",

  BAR = "BAR",

  STACKED_BAR = "STACKED_BAR",

  PIE = "PIE",

  SCATTER = "SCATTER"
}
  

  
export enum DataPointActionType {
  
  CREATE = "CREATE",

  READ = "READ",

  UPDATE = "UPDATE",

  DELETE = "DELETE"
}
  

  
export enum DataSourceCapability {
  
  SUBJECT_WRITE = "SUBJECT_WRITE",

  STRUCTURED_DATA = "STRUCTURED_DATA",

  DATA_CHANGE_NOTIFICATION = "DATA_CHANGE_NOTIFICATION",

  SUBJECT_READ = "SUBJECT_READ",

  SUBJECT_CUSTOM_FIELD_ARRAY_VALUES = "SUBJECT_CUSTOM_FIELD_ARRAY_VALUES"
}
  

  
export enum DataType {
  
  STRING = "STRING",

  DECIMAL = "DECIMAL",

  INTEGER = "INTEGER",

  DATE_TIME = "DATE_TIME",

  DATE = "DATE",

  TIME = "TIME",

  BOOLEAN = "BOOLEAN",

  USER_IDENTIFIER = "USER_IDENTIFIER"
}
  

  
export enum EntityType {
  
  SUBJECT = "SUBJECT",

  SUBJECT_CUSTOM_FIELD = "SUBJECT_CUSTOM_FIELD",

  SUBJECT_ACCESS_INVITE = "SUBJECT_ACCESS_INVITE",

  SUBJECT_RECORD_ITEM = "SUBJECT_RECORD_ITEM",

  SUBJECT_LOCATION = "SUBJECT_LOCATION",

  AUDIT = "AUDIT",

  SUBJECT_CONFIGURATION = "SUBJECT_CONFIGURATION",

  USER = "USER",

  ROLE = "ROLE",

  STRUCTURAL_ENTITY = "STRUCTURAL_ENTITY",

  GROUP = "GROUP",

  DATA_SOURCE = "DATA_SOURCE",

  DATA_SET = "DATA_SET",

  DASHBOARD = "DASHBOARD",

  EXPORT_CONFIGURATION = "EXPORT_CONFIGURATION",

  IMPORT_CONFIGURATION = "IMPORT_CONFIGURATION",

  REPOSITORY_SETTINGS = "REPOSITORY_SETTINGS",

  SUBJECT_FORM = "SUBJECT_FORM",

  WIDGET = "WIDGET",

  USER_GROUP = "USER_GROUP"
}
  

  
export enum FieldTypeKey {
  
  DATE = "DATE",

  DATE_TIME = "DATE_TIME",

  EMAIL = "EMAIL",

  PHONE_NUMBER = "PHONE_NUMBER",

  TEXT = "TEXT",

  NUMBER = "NUMBER",

  TIME = "TIME",

  USER_IDENTIFIER = "USER_IDENTIFIER",

  CONSTRAINED_VALUES = "CONSTRAINED_VALUES",

  SEX = "SEX"
}
  

  
export enum HealthStatus {
  
  HEALTHY = "HEALTHY",

  UNHEALTHY = "UNHEALTHY"
}
  

  
export enum InviteMethod {
  
  SELF_ACCESS = "SELF_ACCESS",

  THIRD_PARTY = "THIRD_PARTY"
}
  

  
export enum ListSortDirection {
  
  ASCENDING = "ASCENDING",

  DESCENDING = "DESCENDING"
}
  

  
export enum NumericComparator {
  
  EQUALS = "EQUALS",

  GREATER_THAN = "GREATER_THAN",

  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",

  LESS_THAN = "LESS_THAN",

  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL"
}
  

  
export enum OverrideLocationBehaviour {
  
  IGNORE = "IGNORE",

  ADD = "ADD",

  REPLACE = "REPLACE"
}
  

  
export enum PieChartStyle {
  
  FULL = "FULL",

  DOUGHNUT = "DOUGHNUT"
}
  

  
export enum RecordItemStatus {
  
  COMPLETE = "COMPLETE"
}
  

  
export enum ReferencedContext {
  
  SUBJECT = "SUBJECT",

  STRUCTURAL_ENTITY = "STRUCTURAL_ENTITY",

  TENANT = "TENANT"
}
  

  
export enum SchemaProviderType {
  
  JSON = "JSON"
}
  

  
export enum Section {
  
  LOCATION = "LOCATION",

  SUBJECT_LIST = "SUBJECT_LIST",

  SUBJECT_PREVIEW = "SUBJECT_PREVIEW",

  SUBJECT_RECORD = "SUBJECT_RECORD",

  SUBJECT_SELF_ACCESS = "SUBJECT_SELF_ACCESS"
}
  

  
export enum StringComparator {
  
  EQUALS = "EQUALS",

  NOT_EQUALS = "NOT_EQUALS",

  CONTAINS = "CONTAINS",

  NOT_CONTAINS = "NOT_CONTAINS",

  STARTS_WITH = "STARTS_WITH",

  NOT_STARTS_WITH = "NOT_STARTS_WITH",

  ENDS_WITH = "ENDS_WITH",

  NOT_ENDS_WITH = "NOT_ENDS_WITH"
}
  

  
export enum SubjectIdentifier {
  
  INTERNAL = "INTERNAL",

  CUSTOM = "CUSTOM",

  EXTERNAL = "EXTERNAL"
}
  

  
export enum SubjectSelfAccessStatusFlag {
  
  NOT_INVITED = "NOT_INVITED",

  INVITED = "INVITED",

  MISSING_MATCHING_FIELD_VALUES = "MISSING_MATCHING_FIELD_VALUES"
}
  

  
export enum TenantFeatureKey {
  
  SUBJECT_ACCESS = "SUBJECT_ACCESS",

  SUBJECT_WRITE = "SUBJECT_WRITE",

  SUBJECT_READ = "SUBJECT_READ",

  SUBJECT_ARRAY_CUSTOM_FIELDS = "SUBJECT_ARRAY_CUSTOM_FIELDS",

  SUBJECT_SELF_ACCESS = "SUBJECT_SELF_ACCESS",

  SUBJECT_THIRD_PARTY_ACCESS = "SUBJECT_THIRD_PARTY_ACCESS"
}
  

  
export enum UserIdentifierType {
  
  UNKNOWN = "UNKNOWN",

  USER = "USER",

  SUBJECT = "SUBJECT",

  EXTERNAL = "EXTERNAL"
}
  

  
export enum VisualisationType {
  
  LIQUID = "LIQUID",

  TABLE = "TABLE",

  GRAPH = "GRAPH",

  PIE_CHART = "PIE_CHART"
}
  


/**
 * Represents a date in the ISO-8601 format ('yyyy-MM-dd').
 */
export type DateOnly = string



/**
 * The `DateTime` scalar represents an ISO-8601 compliant date time type.
 */
export type DateTime = string



export type JSON = string



/**
 * Represents a time in the ISO-8601 format ('HH:mm:ss').
 */
export type TimeOnly = string



export type URL = string



export type Uuid = string


  const $Root = {
    query: Query,
mutation: Mutation,
subscription: Subscription
  }

  namespace $RootTypes {
    export type query = Query
export type mutation = Mutation
export type subscription = Subscription
  }
  

export function query<Sel extends Selection<$RootTypes.query>>(
  name: string,
  selectFn: (q: $RootTypes.query) => [...Sel]
): TypedDocumentNode<GetOutput<Sel>, GetVariables<Sel>>
export function query<Sel extends Selection<$RootTypes.query>>(
  selectFn: (q: $RootTypes.query) => [...Sel]
): TypedDocumentNode<GetOutput<Sel>, Simplify<GetVariables<Sel>>>
export function query<Sel extends Selection<$RootTypes.query>>(name: any, selectFn?: any) {
  if (!selectFn) {
    selectFn = name
    name = ''
  }
  let field = new $Field<'query', GetOutput<Sel>, GetVariables<Sel>>('query', {
    selection: selectFn(new $Root.query()),
  })
  const str = fieldToQuery(`query ${name}`, field)

  return gql(str) as any
}


export function mutation<Sel extends Selection<$RootTypes.mutation>>(
  name: string,
  selectFn: (q: $RootTypes.mutation) => [...Sel]
): TypedDocumentNode<GetOutput<Sel>, GetVariables<Sel>>
export function mutation<Sel extends Selection<$RootTypes.mutation>>(
  selectFn: (q: $RootTypes.mutation) => [...Sel]
): TypedDocumentNode<GetOutput<Sel>, Simplify<GetVariables<Sel>>>
export function mutation<Sel extends Selection<$RootTypes.query>>(name: any, selectFn?: any) {
  if (!selectFn) {
    selectFn = name
    name = ''
  }
  let field = new $Field<'mutation', GetOutput<Sel>, GetVariables<Sel>>('mutation', {
    selection: selectFn(new $Root.mutation()),
  })
  const str = fieldToQuery(`mutation ${name}`, field)

  return gql(str) as any
}


export function subscription<Sel extends Selection<$RootTypes.subscription>>(
  name: string,
  selectFn: (q: $RootTypes.subscription) => [...Sel]
): TypedDocumentNode<GetOutput<Sel>, GetVariables<Sel>>
export function subscription<Sel extends Selection<$RootTypes.subscription>>(
  selectFn: (q: $RootTypes.subscription) => [...Sel]
): TypedDocumentNode<GetOutput<Sel>, Simplify<GetVariables<Sel>>>
export function subscription<Sel extends Selection<$RootTypes.query>>(name: any, selectFn?: any) {
  if (!selectFn) {
    selectFn = name
    name = ''
  }
  let field = new $Field<'subscription', GetOutput<Sel>, GetVariables<Sel>>('subscription', {
    selection: selectFn(new $Root.subscription()),
  })
  const str = fieldToQuery(`subscription ${name}`, field)

  return gql(str) as any
}


const $InputTypes: {[key: string]: {[key: string]: string}} = {
    AggregateDefinitionInput: {
    id: "Uuid!",
inputKeys: "[String!]!",
outputKey: "String!",
type: "AggregateType!"
  },
  AxisDTOInput: {
    id: "Uuid!",
label: "String!",
maxValue: "String",
minValue: "String",
series: "[AxisSeriesDTOInput!]!",
stepValue: "String",
type: "AxisType!"
  },
  AxisSeriesDTOInput: {
    key: "String!"
  },
  BooleanDataTypeValueFilterInput: {
    booleanValue: "Boolean",
liquidExpression: "String"
  },
  BooleanValueInput: {
    value: "Boolean"
  },
  BundledDataSourceInstanceInput: {
    configurationValueReferences: "[KeyValuePairOfStringAndStringInput]!",
dataSourceKey: "String!",
key: "String!",
name: "String!"
  },
  CreateSubjectFormInput: {
    formKey: "String!",
formVersion: "Int"
  },
  CreateSubjectInput: {
    customFields: "[CustomFieldInput!]!",
structuralEntityKeys: "[String!]!"
  },
  CustomDataSourceInstanceInput: {
    clientId: "String!",
clientSecretReference: "String!",
configurationValueReferences: "[KeyValuePairOfStringAndStringInput]!",
endpoint: "URL!",
key: "String!",
name: "String!",
scope: "String!"
  },
  CustomFieldGroupInput: {
    bannerPositionIndex: "Int!",
customFields: "[SubjectBannerItemInput!]!",
key: "String!",
name: "String!",
showByDefault: "Boolean!"
  },
  CustomFieldInput: {
    fieldKey: "String!",
value: "CustomFieldValueInput!"
  },
  CustomFieldValueInput: {
    arrayValue: "[ValueInput!]",
singleValue: "ValueInput"
  },
  DashboardFilterInput: {
    forPortal: "Boolean",
ids: "[Uuid!]"
  },
  DashboardInput: {
    forPortal: "Boolean",
items: "[DashboardItemSettingsInput!]!",
key: "String!",
title: "String!"
  },
  DashboardItemSettingsInput: {
    id: "Uuid!",
positionIndex: "Int!"
  },
  DataExtractDimensionInput: {
    dataExtractId: "Uuid!",
key: "String!"
  },
  DataExtractFilterInput: {
    dataSourceInstanceIds: "[Uuid!]",
searchTerm: "String"
  },
  DataExtractInput: {
    dataSourceId: "Uuid!",
description: "String",
key: "String!",
providerJSON: "String!",
subjectIdentifierConfiguration: "SubjectIdentifierConfigurationInput",
title: "String!"
  },
  DataOrderingInput: {
    key: "String!",
orderDirection: "ListSortDirection!"
  },
  DataPointFilterInput: {
    and: "[OrFilterInput!]!"
  },
  DataTypeWrapperInput: {
    dataType: "DataType!"
  },
  DateDataTypeValueFilterInput: {
    dateValue: "DateOnly",
liquidExpression: "String",
numericComparator: "NumericComparator!"
  },
  DateTimeDataTypeValueFilterInput: {
    dateTimeValue: "DateTime",
liquidExpression: "String",
numericComparator: "NumericComparator!"
  },
  DateTimeValueInput: {
    value: "DateTime"
  },
  DateValueInput: {
    value: "DateOnly"
  },
  DecimalDataTypeValueFilterInput: {
    decimalValue: "Float",
liquidExpression: "String",
numericComparator: "NumericComparator!"
  },
  DecimalValueInput: {
    value: "Float"
  },
  DisplayOptionsInput: {
    graphChartDisplayOptions: "GraphChartDisplayOptionsInput",
liquidDisplayOptions: "LiquidDisplayOptionsInput",
pieChartDisplayOptions: "PieChartDisplayOptionsInput",
tableDisplayOptions: "TableDisplayOptionsInput"
  },
  FilterInput: {
    boolean: "BooleanDataTypeValueFilterInput",
date: "DateDataTypeValueFilterInput",
dateTime: "DateTimeDataTypeValueFilterInput",
decimal: "DecimalDataTypeValueFilterInput",
fieldId: "Int!",
fieldKey: "String!",
integer: "IntegerDataTypeValueFilterInput",
string: "StringDataTypeValueFilterInput",
time: "TimeDataTypeValueFilterInput",
userIdentifier: "UserIdentifierDataTypeValueFilterInput",
visualisationId: "Uuid"
  },
  GetDataPointsInput: {
    after: "String",
before: "String",
context: "WidgetContextInput!",
filter: "DataPointFilterInput",
first: "Int",
last: "Int",
orderBy: "String",
orderDirection: "ListSortDirection"
  },
  GetSubjectInput: {
    externalId: "ID",
id: "ID"
  },
  GraphChartDisplayOptionsInput: {
    liquidColourFormatting: "[KeyValuePairOfStringAndStringInput!]!"
  },
  GroupDefinitionInput: {
    aggregators: "[AggregateDefinitionInput!]!",
groupByKeys: "[String!]!"
  },
  GroupFilterInput: {
    searchTerm: "String!"
  },
  GroupInput: {
    key: "String!",
name: "String!",
structuralEntityKeys: "[String!]!"
  },
  IFrameInput: {
    forPortal: "Boolean",
key: "String!",
sendBearerAsAQueryParameter: "Boolean",
title: "String!",
uri: "String!"
  },
  InboundSubjectConfigurationInput: {
    existingSubjectOverrideLocationBehaviour: "OverrideLocationBehaviour!",
matchingInputFieldIds: "[Uuid!]!",
newSubjectOverrideLocationBehaviour: "OverrideLocationBehaviour!",
unmatchedStructuralEntityKey: "String!"
  },
  InputDimensionInput: {
    collatedDimensions: "[DataExtractDimensionInput!]!",
dataType: "DataType!",
key: "String!"
  },
  InputFieldInput: {
    formPositionIndex: "Int!",
isArray: "Boolean!",
key: "String!",
name: "String!",
options: "[String!]",
required: "Boolean!",
typeKey: "FieldTypeKey!"
  },
  InputFieldResponseInput: {
    key: "String!",
name: "String!",
required: "Boolean!",
type: "FieldTypeKey!",
value: "ValueInput!"
  },
  IntegerDataTypeValueFilterInput: {
    integerValue: "Int",
liquidExpression: "String",
numericComparator: "NumericComparator!"
  },
  IntegerValueInput: {
    value: "Int"
  },
  InviteToSubjectPortalInput: {
    input: "PortalInviteInput!",
subjectId: "ID!"
  },
  KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndDataTypeWrapperInput: {
    key: "String!",
value: "NestedKeyValuePairValueOfStringAndDataTypeWrapperInput!"
  },
  KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndValueInput: {
    key: "String!",
value: "NestedKeyValuePairValueOfStringAndValueInput!"
  },
  KeyValuePairOfStringAndStringInput: {
    key: "String!",
value: "String"
  },
  KeyValuePairOfStringAndValueInput: {
    key: "String!",
value: "ValueInput!"
  },
  LiquidDisplayOptionsInput: {
    liquidHtml: "String!"
  },
  LiquidFilterDefinitionInput: {
    expression: "String!",
id: "Uuid!"
  },
  LiquidMappingDefinitionInput: {
    expression: "String!",
id: "Uuid!",
outputKey: "String!"
  },
  MultiSubjectViewInput: {
    dashboardId: "ID",
dataPointFilterInput: "DataPointFilterInput",
subjectFilterInput: "SubjectFilterInput"
  },
  NestedKeyValuePairValueOfStringAndDataTypeWrapperInput: {
    nestedValue: "[KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndDataTypeWrapperInput!]",
value: "DataTypeWrapperInput"
  },
  NestedKeyValuePairValueOfStringAndValueInput: {
    nestedValue: "[KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndValueInput!]",
value: "ValueInput"
  },
  OrFilterInput: {
    or: "[FilterInput!]!"
  },
  PerformActionInput: {
    description: "String!",
inputFields: "[InputFieldResponseInput!]!",
key: "String!",
name: "String!",
type: "DataPointActionType!"
  },
  PieChartDisplayOptionsInput: {
    liquidColourFormatting: "[KeyValuePairOfStringAndStringInput!]!",
showLabel: "Boolean!",
showLegend: "Boolean!",
style: "PieChartStyle!"
  },
  PipelineDefinitionInput: {
    aggregators: "[AggregateDefinitionInput!]!",
filters: "[LiquidFilterDefinitionInput!]!",
group: "GroupDefinitionInput",
mappers: "[LiquidMappingDefinitionInput!]!"
  },
  PortalInviteInput: {
    selfAccess: "SelfAccessInput",
thirdPartyUser: "ThirdPartyInput"
  },
  ReferenceValueDTOInput: {
    label: "String!",
value: "String!"
  },
  RemoveFromSubjectPortalInput: {
    subjectId: "ID!",
userId: "ID!"
  },
  RepositorySettingsInput: {
    branchReference: "String!",
configurationDirectory: "String!",
passwordReference: "String",
sshPrivateKeyReference: "String",
uriReference: "String!",
usernameReference: "String"
  },
  SectionConfigurationInput: {
    dashboardIds: "[Uuid!]!",
defaultDashboardId: "Uuid",
section: "Section!"
  },
  SelfAccessConfigurationInput: {
    emailLinkingFieldId: "Uuid",
enabled: "Boolean!"
  },
  SelfAccessInput: {
    customFieldKey: "String"
  },
  SeriesDTOInput: {
    chartType: "ChartType",
colour: "String",
dataType: "DataType!",
hidden: "Boolean!",
key: "String!",
liquidFormatter: "String",
name: "String!",
order: "Int!",
referenceValues: "[ReferenceValueDTOInput!]!"
  },
  StringDataTypeValueFilterInput: {
    liquidExpression: "String",
stringComparator: "StringComparator!",
stringValue: "String"
  },
  StringValueInput: {
    value: "String"
  },
  StructuralEntityFilterInput: {
    depth: "Int",
parentKey: "String",
searchTerm: "String",
structureTypeKey: "String",
subjectAssignable: "Boolean"
  },
  StructuralEntityInput: {
    customFieldValues: "[KeyValuePairOfStringAndValueInput!]",
displayName: "String!",
key: "String!",
parentKey: "String",
structureTypeKey: "String!"
  },
  StructureTypeInput: {
    customFields: "[InputFieldInput!]!",
key: "String!",
name: "String!",
subjectAssignable: "Boolean!"
  },
  SubjectAccessConfigurationInput: {
    selfAccessConfiguration: "SelfAccessConfigurationInput!",
subjectNotLinkedErrorMessage: "String!",
thirdPartyConfiguration: "ThirdPartyConfigurationInput!"
  },
  SubjectAttachmentInput: {
    effectiveDate: "DateTime!",
fileId: "ID!",
title: "String!"
  },
  SubjectBannerItemInput: {
    customFieldKey: "String!",
positionIndex: "Int"
  },
  SubjectFilterInput: {
    searchTerm: "String",
structuralEntityKey: "ID"
  },
  SubjectIdentifierConfigurationInput: {
    customFieldKey: "String",
useExternalId: "Boolean!",
useInternalId: "Boolean!"
  },
  SubjectInput: {
    customFields: "[CustomFieldInput!]!"
  },
  TableDisplayOptionsInput: {
    showColumnHeadings: "Boolean!"
  },
  TenantComputedFieldInput: {
    id: "Uuid",
key: "String!",
liquidMapping: "String!",
name: "String!"
  },
  TenantInputFieldInput: {
    formPositionIndex: "Int!",
id: "Uuid",
isArray: "Boolean!",
key: "String!",
mapping: "String",
name: "String!",
options: "[String!]",
required: "Boolean!",
typeKey: "FieldTypeKey!",
unique: "Boolean!"
  },
  ThirdPartyConfigurationInput: {
    enabled: "Boolean!"
  },
  ThirdPartyInput: {
    email: "String!"
  },
  TimeDataTypeValueFilterInput: {
    liquidExpression: "String",
numericComparator: "NumericComparator!",
timeValue: "TimeOnly"
  },
  TimeValueInput: {
    value: "TimeOnly"
  },
  UpdateTenantInput: {
    avatarIsEnabled: "Boolean!",
computedFields: "[TenantComputedFieldInput!]!",
customFieldGroups: "[CustomFieldGroupInput!]!",
externalIdMapping: "String",
inputFields: "[TenantInputFieldInput!]!",
liquidLookupExpression: "String",
locationName: "String!",
primaryFieldKey: "String!",
showRoles: "Boolean!",
subjectDataSourceId: "Uuid!",
subjectName: "String!"
  },
  UpdateUserGroupsInput: {
    groupKeysToAdd: "[String!]!",
groupKeysToRemove: "[String!]!",
userId: "ID!"
  },
  UserFilterInput: {
    includeInactive: "Boolean!",
searchTerm: "String",
withAccessToSubjectId: "Uuid"
  },
  UserIdentifierDataTypeValueFilterInput: {
    liquidExpression: "String",
userIdentifierValue: "UserIdentifierInput"
  },
  UserIdentifierInput: {
    identifier: "String!",
userIdentifierType: "UserIdentifierType!"
  },
  UserIdentifierValueInput: {
    value: "UserIdentifierInput"
  },
  ValueInput: {
    boolean: "BooleanValueInput",
date: "DateValueInput",
dateTime: "DateTimeValueInput",
decimal: "DecimalValueInput",
integer: "IntegerValueInput",
string: "StringValueInput",
time: "TimeValueInput",
userIdentifier: "UserIdentifierValueInput"
  },
  VisualisationInput: {
    axes: "[AxisDTOInput!]!",
defaultFilter: "DataPointFilterInput",
defaultOrdering: "DataOrderingInput!",
displayOptions: "DisplayOptionsInput",
forPortal: "Boolean",
inputDimensions: "[InputDimensionInput!]!",
key: "String!",
pipelineDefinition: "PipelineDefinitionInput!",
series: "[SeriesDTOInput!]!",
title: "String!",
type: "VisualisationType!"
  },
  WidgetContextInput: {
    structuralEntityKey: "ID",
subjectId: "ID"
  },
  WidgetFilterInput: {
    forPortal: "Boolean",
ids: "[Uuid!]",
searchTerm: "String"
  }
}

