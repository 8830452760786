import { getHumanizedTime } from '@aireframe/shared-types';
import WarningIcon from '@mui/icons-material/WarningAmber';
import { Grid, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { useMemo } from 'react';

type Props = {
  lastPossibleUpdate: dayjs.Dayjs;
};

// Account for possible transmission/render delay
const BufferSeconds = 30;

const StaleIndicator: React.FC<Props> = ({ lastPossibleUpdate }) => {
  const isStale = useMemo(
    () => lastPossibleUpdate < dayjs().subtract(BufferSeconds, 'seconds'),
    [lastPossibleUpdate]
  );

  if (!isStale) {
    return null;
  }

  return (
    <Grid item sx={{ mt: 1 }}>
      <Tooltip
        title={`This information could be outdated. The most recent update occurred ${getHumanizedTime(lastPossibleUpdate)}.`}>
        <WarningIcon color="warning" data-testid="warning-icon" />
      </Tooltip>
    </Grid>
  );
};

export default StaleIndicator;
