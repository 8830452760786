import BackButton from '@mui/icons-material/ArrowBackIos';
import DescedingIcon from '@mui/icons-material/ArrowDownward';
import ForwardIcon from '@mui/icons-material/ArrowForwardIos';
import AscendingIcon from '@mui/icons-material/ArrowUpward';
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  styled,
  svgIconClasses,
  Tooltip,
  Typography
} from '@mui/material';
import React, { Fragment, useMemo, useRef, useState } from 'react';
import { PopupMenu } from '../PopupMenu';
import { CursorPagination, CursorPaginationState } from './PaginationTypes';
import { ConnectionPageInfo, OrderDirection } from '@aireframe/graphql';

export type SortingOption = { key: string; displayName: string };
const SortingOptions: React.FC<{
  orderByOptions: SortingOption[];
  orderBy?: CursorPaginationState['orderBy'];
  setOrderBy: (orderBy: CursorPaginationState['orderBy']) => void;
}> = ({ orderByOptions, orderBy, setOrderBy }) => {
  const orderByMenuRef = useRef(null);
  const [showOrderByMenu, setShowOrderByMenu] = useState(false);

  const currentOption = useMemo(
    () => orderByOptions.find(option => option.key === orderBy),
    [orderByOptions, orderBy]
  );
  const otherOptions = useMemo(
    () => orderByOptions.filter(option => option.key !== orderBy),
    [orderByOptions, orderBy]
  );

  if (orderByOptions.length === 1) {
    return <Typography>{currentOption?.displayName || orderBy}</Typography>;
  } else {
    return (
      <Fragment>
        <Button
          ref={orderByMenuRef}
          color="secondary"
          onClick={() => setShowOrderByMenu(true)}
          aria-label="Order by">
          <Typography>{currentOption?.displayName || 'Default'}</Typography>
        </Button>
        {showOrderByMenu && (
          <PopupMenu
            title="Order By"
            popoverProps={{
              anchorEl: orderByMenuRef.current
            }}
            onClose={() => setShowOrderByMenu(false)}>
            <List>
              {currentOption && (
                <ListItem disablePadding>
                  <ListItemButton disabled>
                    <ListItemText>{currentOption.displayName}</ListItemText>
                  </ListItemButton>
                </ListItem>
              )}
              {otherOptions?.map((option, index) => (
                <ListItem
                  disablePadding
                  key={index}
                  onClick={() => {
                    setOrderBy(option.key);
                    setShowOrderByMenu(false);
                  }}>
                  <ListItemButton>
                    <ListItemText>{option.displayName}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              <ListItem
                disablePadding
                onClick={() => {
                  setOrderBy(null);
                  setShowOrderByMenu(false);
                }}>
                <ListItemButton disabled={!orderBy}>Default</ListItemButton>
              </ListItem>
            </List>
          </PopupMenu>
        )}
      </Fragment>
    );
  }
};

export type PaginationProps = {
  value: CursorPagination;
  setValue: (value: CursorPagination) => void;
  totalCount?: number;
  orderByOptions?: Array<SortingOption>;
  showOrdering?: boolean;
  pageInfo: Omit<ConnectionPageInfo, '__typename'>;
  pageSizeOptions?: number[];
  contained?: boolean;
};

const StyledIconButton = styled(IconButton)(({ disabled }) => ({
  color: disabled ? 'grey !important' : 'inherited',
  padding: 0
}));

const StyledGrid = styled(Grid, {
  shouldForwardProp: prop => prop !== 'contained'
})<{ contained: boolean }>(({ theme, contained }) => {
  if (contained) {
    return {
      borderBottom: `1px solid ${theme.palette.divider}`,
      color: 'black',
      height: 41,
      padding: theme.spacing(0.5)
    };
  }

  return {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: '0 0 10px 10px',
    height: 41,
    padding: theme.spacing(0.5)
  };
});

export const Pagination: React.FC<PaginationProps> = ({
  value,
  setValue,
  orderByOptions = [],
  showOrdering = true,
  pageInfo,
  totalCount,
  pageSizeOptions = [10, 20, 50],
  contained
}) => {
  return (
    <StyledGrid
      container
      alignItems="center"
      justifyContent="space-between"
      contained={contained ?? false}>
      <Grid item style={{ display: 'flex' }} alignItems="center">
        {showOrdering && (
          <>
            {value.orderDirection === OrderDirection.ASCENDING && (
              <Tooltip title="Ascending">
                <IconButton
                  size="small"
                  color={contained ? 'primary' : 'secondary'}
                  aria-label="Order direction ascending"
                  onClick={() => {
                    setValue(
                      new CursorPagination({ ...value, orderDirection: OrderDirection.DESCENDING })
                    );
                  }}>
                  <AscendingIcon />
                </IconButton>
              </Tooltip>
            )}
            {value.orderDirection === OrderDirection.DESCENDING && (
              <Tooltip title="Descending">
                <IconButton
                  size="small"
                  color={contained ? 'primary' : 'secondary'}
                  aria-label="Order direction descending"
                  onClick={() => {
                    setValue(
                      new CursorPagination({ ...value, orderDirection: OrderDirection.ASCENDING })
                    );
                  }}>
                  <DescedingIcon />
                </IconButton>
              </Tooltip>
            )}
            <SortingOptions
              orderByOptions={orderByOptions}
              orderBy={value.orderBy}
              setOrderBy={orderBy =>
                setValue(
                  new CursorPagination({
                    orderDirection: value.orderDirection,
                    pageSize: value.pageSize,
                    orderBy
                  })
                )
              }
            />
          </>
        )}
      </Grid>
      <Grid item alignItems="center" style={{ display: 'flex' }}>
        {value.pageSize && totalCount !== undefined && totalCount > 0 && (
          <Typography sx={{ mr: 2 }} component="span">
            <Select
              disableUnderline
              variant="standard"
              sx={({
                palette: {
                  primary: { contrastText }
                }
              }) => ({
                color: contained ? 'primary' : contrastText,
                [`& .${svgIconClasses.root}`]: { color: contained ? 'primary' : contrastText }
              })}
              value={value.pageSize}
              onChange={e =>
                setValue(
                  new CursorPagination({
                    pageSize: e.target.value as number,
                    orderBy: value.orderBy,
                    orderDirection: value.orderDirection
                  })
                )
              }>
              {pageSizeOptions.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
              {!pageSizeOptions.includes(value.pageSize) && (
                <MenuItem value={value.pageSize}>{value.pageSize}</MenuItem>
              )}
            </Select>
            Per Page | {totalCount} Total
          </Typography>
        )}
        {(pageInfo.hasNextPage || pageInfo.hasPreviousPage) && (
          <>
            <StyledIconButton
              size="small"
              color="secondary"
              onClick={() => setValue(value.pageBackwards(pageInfo))}
              disabled={!pageInfo.hasPreviousPage}
              aria-label="Previous page">
              <BackButton />
            </StyledIconButton>
            <StyledIconButton
              size="small"
              color="secondary"
              onClick={() => setValue(value.pageForwards(pageInfo))}
              disabled={!pageInfo.hasNextPage}
              aria-label="Next page">
              <ForwardIcon />
            </StyledIconButton>
          </>
        )}
      </Grid>
    </StyledGrid>
  );
};
