import { ExcludeNullable } from '@aireframe/shared-types';
import { $, $$, query } from '../../api.gen';
import { ResultOf } from '@graphql-typed-document-node/core';

export const UserWithAccessToSubjectQuery = (includeAccessToSubject: boolean) =>
  query('UserWithAccessToSubject', q => [
    q.tenant(t => [
      t.__typename,
      t.id,
      t.user({ userId: $$('id') }, u => [
        u.__typename,
        u.id,
        u.displayName,
        u.isEnabled,
        ...(includeAccessToSubject
          ? [u.hasAccessToSubject({ subjectId: $$('withAccessToSubjectId') })]
          : [])
      ])
    ])
  ]);

export const UserDetailQuery = query('UserDetail', q => [
  q.tenant(t => [
    t.__typename,
    t.id,
    t.user({ userId: $$('id') }, u => [
      u.__typename,
      u.id,
      u.displayName,
      u.email,
      u.hasSubjectPortalAccess,
      u.groups(g => [g.__typename, g.nodes(n => [n.__typename, n.key, n.name])])
    ])
  ])
]);

export type UserDetail = ExcludeNullable<ResultOf<typeof UserDetailQuery>['tenant']['user']>;

export const UserLinkedSubjectsQuery = query('UserLinkedSubjects', q => [
  q.tenant(t => [
    t.__typename,
    t.id,
    t.user({ userId: $$('id') }, u => [
      u.__typename,
      u.id,
      u.linkedSubjects(
        { after: $('after'), before: $('before'), first: $('first'), last: $('last') },
        s => [
          s.__typename,
          s.totalCount,
          s.pageInfo(p => [
            p.__typename,
            p.startCursor,
            p.endCursor,
            p.hasNextPage,
            p.hasPreviousPage
          ]),
          s.edges(e => [
            e.__typename,
            e.node(n => [
              n.__typename,
              n.id,
              n.primaryFieldValue(pfv => [pfv.__typename, pfv.valueText]),
              n.avatarHash
            ])
          ])
        ]
      )
    ])
  ])
]);
