import { ErrorParser, Subject, SubjectQuery } from '@aireframe/graphql';
import { ApolloError, useQuery } from '@apollo/client';
import { useMemo } from 'react';

export type UseSubjectResult = {
  loading: boolean;
  subject: Subject | null;
  error:
    | {
        cannotAccess: boolean;
        other: ApolloError;
      }
    | undefined;
};

export const useSubject = (input: { id: string } | { externalId: string }): UseSubjectResult => {
  const { loading, data, error } = useQuery(SubjectQuery, {
    variables: {
      input
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network'
  });

  return useMemo(() => {
    const parsedErrors = ErrorParser.parse(error);

    const errors = parsedErrors
      ? {
          cannotAccess: parsedErrors.some(e => e.key === 'Auth'),
          other: error!
        }
      : undefined;

    return {
      loading,
      error: errors,
      subject: data?.subject ?? null
    };
  }, [data?.subject, error, loading]);
};
