import { VariablesOf } from '@graphql-typed-document-node/core';
import { $$, mutation } from '../../api.gen';
import { BaseTenantFragment } from '../Tenant';

export const UpdateTenantSubjectConfigurationMutation = mutation(
  'UpdateTenantSubjectConfiguration',
  m => [
    m.updateTenant({ input: $$('input') }, t => [
      ...BaseTenantFragment,
      t.tenantSubjectProvider(tsp => [
        tsp.__typename,
        tsp.dataSource(d => [d.__typename, d.id, d.name]),
        tsp.configuration(c => [
          c.__typename,
          c.subjectMapping(sm => [
            sm.__typename,
            sm.customFieldMappings(m => [
              m.__typename,
              m.mapping,
              m.customField(f => [f.__typename, f.id, f.key])
            ])
          ])
        ])
      ])
    ])
  ]
);

export type UpdateTenantSubjectConfigurationMutationInput = VariablesOf<
  typeof UpdateTenantSubjectConfigurationMutation
>['input'];
