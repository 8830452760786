import { ChartDisplayOptions, Series, valueOrNullIfEmpty } from '@aireframe/shared-types';
import { ZRColor } from 'echarts/types/dist/shared';
import { useCallback, useEffect, useState } from 'react';
import { EchartsDataSet } from '../DataVisualisationHelpers';
import { LiquidEngine } from '../../../Liquid';

export const PredefinedColours = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc'
];

export type DataSetItemValue = string | number | boolean | Date;
export const useChartColours = (
  displayOptions: Pick<Exclude<ChartDisplayOptions, null>, 'liquidColourFormatting'> | null,
  dataset: EchartsDataSet
): {
  loading: boolean;
  colourMapper: (series: Series, value: DataSetItemValue | null, dataIndex: number) => ZRColor;
} => {
  const [valueToColorMap, setValueToColorMap] = useState<{
    [seriesKey: string]: {
      [stringValue: string]: string;
    };
  }>();

  useEffect(() => {
    if (
      !displayOptions ||
      displayOptions.liquidColourFormatting === null ||
      displayOptions.liquidColourFormatting.length === 0
    ) {
      setValueToColorMap({});
      return;
    }

    const uniqueValuesBySeries = dataset.source.reduce<{
      [seriesKey: string]: Set<DataSetItemValue>;
    }>((dict, dataPoint) => {
      Object.entries(dataPoint).forEach(([key, value]) => {
        if (value === null) {
          return;
        }

        if (key in dict) {
          dict[key].add(value);
        } else {
          dict[key] = new Set([value]);
        }
      });

      return dict;
    }, {});

    const fetchValueColours = async () => {
      const colours = await Promise.all(
        Object.entries(uniqueValuesBySeries).map(async ([seriesKey, seriesValues]) => {
          const liquidFormatter = displayOptions.liquidColourFormatting?.find(
            x => x.key === seriesKey
          )?.value;
          if (!liquidFormatter || valueOrNullIfEmpty(liquidFormatter) === null) {
            return null;
          }

          const template = LiquidEngine.parse(liquidFormatter);
          const results = await Promise.all(
            Array.from(seriesValues, async value => {
              const color = valueOrNullIfEmpty(
                await LiquidEngine.render(template, { value })
              )?.trim();
              return { value, color };
            })
          );

          return {
            seriesKey,
            colourResults: results.reduce<{ [value: string]: string }>((dict, { value, color }) => {
              if (color) {
                dict[value.toString()] = color;
              }
              return dict;
            }, {})
          };
        })
      );

      setValueToColorMap(
        colours.reduce<{ [seriesKey: string]: { [stringValue: string]: string } }>((dict, item) => {
          if (item) {
            dict[item.seriesKey] = item.colourResults;
          }
          return dict;
        }, {})
      );
    };

    fetchValueColours();
  }, [displayOptions, dataset.source]);

  const colourMapper = useCallback(
    (series: Series, value: DataSetItemValue | null, dataIndex: number): ZRColor => {
      if (
        valueToColorMap &&
        value &&
        series.key in valueToColorMap &&
        value.toString() in valueToColorMap[series.key]
      ) {
        return valueToColorMap[series.key][value.toString()];
      }

      return PredefinedColours[dataIndex % PredefinedColours.length];
    },
    [valueToColorMap]
  );

  return { loading: !valueToColorMap, colourMapper };
};
