import {
  FieldType,
  InputField,
  InputFieldType,
  isMultipleChoiceInputFieldType
} from '@aireframe/graphql';
import { assertUnreachable } from '@aireframe/shared-types';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import {
  FormControl,
  FormHelperText,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import pluralize from 'pluralize';
import React, { Fragment } from 'react';
import {
  Controller,
  FieldError,
  FieldErrors,
  useFieldArray,
  useFormContext,
  useFormState
} from 'react-hook-form';
import { TopRightIconButton } from '../FloatingButtons';
import CustomDateField from './CustomDateField';
import PhoneNumberField from './PhoneNumberField';
import UserIdentifierLookUpField from './UserIdentifier/UserIdentifierLookupField';

interface CustomFieldProps {
  disabled?: boolean;
  customField: Omit<InputField, '__typename'>;
  subjectId?: string;
  inputNamePrefix?: string;
}

const getNestedError = (
  fullKey: string,
  errors: FieldErrors | undefined
): FieldError | undefined => {
  function isFieldError(errors: FieldErrors | FieldError): errors is FieldError {
    return (errors as FieldError).message !== undefined;
  }

  let currentError = errors;

  for (const key of fullKey.split('.')) {
    if (currentError === undefined || currentError[key] === undefined) {
      break;
    }

    if (isFieldError(currentError[key])) {
      return currentError[key];
    }

    currentError = currentError[key];
  }

  return undefined;
};

const InputFieldComponent: React.FC<{
  rhfFieldKey: string;
  name: string;
  fieldKey: string;
  type: InputFieldType;
  required: boolean;
  disabled?: boolean;
  subjectId: string | undefined;
}> = ({ rhfFieldKey, type: fieldType, required, disabled, name, fieldKey: key, subjectId }) => {
  const { register, control } = useFormContext();
  const { errors } = useFormState({
    control,
    name: rhfFieldKey
  });

  const inputFieldName = `${key}${rhfFieldKey.split(key)[1]}`;

  if (fieldType.key === FieldType.PHONE_NUMBER) {
    return (
      <PhoneNumberField
        required={required}
        label={name}
        fieldName={inputFieldName}
        disabled={disabled}
      />
    );
  }

  if (fieldType.key === FieldType.USER_IDENTIFIER) {
    return (
      <Controller
        control={control}
        name={inputFieldName}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <UserIdentifierLookUpField
            value={value}
            onChange={onChange}
            autoCompleteProps={{
              fullWidth: true,
              label: name,
              helperText: error?.message,
              required,
              disabled,
              error: error !== undefined
            }}
            includeInactive={false}
            withAccessToSubjectId={subjectId}
          />
        )}
      />
    );
  }

  const error = getNestedError(rhfFieldKey, errors);
  const props = {
    name: key,
    error: error !== undefined,
    fullWidth: true,
    required,
    disabled
  };

  if (fieldType.key === FieldType.CONSTRAINED_VALUES || fieldType.key === FieldType.SEX) {
    if (!isMultipleChoiceInputFieldType(fieldType)) {
      throw new Error('Field type is not a multiple choice input field type');
    }

    return (
      <FormControl {...props} variant="standard">
        <InputLabel>{name}</InputLabel>
        <Controller
          render={({ field }) => (
            <Select
              native
              {...props}
              inputProps={{ 'aria-label': name }}
              {...field}
              value={field.value ?? ''}>
              <Fragment>
                {<option value=""></option>}
                {fieldType.options.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </Fragment>
            </Select>
          )}
          name={inputFieldName}
          control={control}
        />
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    );
  }

  const textFieldProps = {
    ...props,
    helperText: error?.message,
    InputLabelProps: {
      required,
      shrink: true
    },
    inputProps: {
      ...register(rhfFieldKey)
    },
    label: name
  };

  switch (fieldType.key) {
    case FieldType.TEXT:
    case FieldType.EMAIL:
      return <TextField {...textFieldProps} />;
    case FieldType.NUMBER:
      return <TextField {...textFieldProps} type="number" />;
    case FieldType.DATE:
      return (
        <TextField
          type="date"
          {...textFieldProps}
          slotProps={{
            input: { inputComponent: CustomDateField },
            htmlInput: { ...textFieldProps.inputProps, 'aria-label': name }
          }}
        />
      );
    case FieldType.TIME:
      return (
        <TextField
          type="time"
          {...textFieldProps}
          slotProps={{
            htmlInput: { ...textFieldProps.inputProps, 'aria-label': name }
          }}
        />
      );
    case FieldType.DATE_TIME:
      return (
        <TextField
          type="datetime-local"
          {...textFieldProps}
          slotProps={{
            htmlInput: { ...textFieldProps.inputProps, 'aria-label': name }
          }}
        />
      );
    default:
      return assertUnreachable(fieldType.key);
  }
};

const ArrayInputFieldComponent: React.FC<{
  rhfFieldKey: string;
  name: string;
  fieldKey: string;
  type: InputFieldType;
  required: boolean;
  disabled?: boolean;
  subjectId: string | undefined;
}> = ({ rhfFieldKey, name, fieldKey: key, type, required, disabled, subjectId }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: rhfFieldKey });
  const { errors, isSubmitted } = useFormState({
    control,
    name: rhfFieldKey
  });

  const missingValue =
    required && !!errors[rhfFieldKey]?.root && fields.length === 0 && isSubmitted;

  return (
    <Grid container spacing={2} direction="column" sx={{ mt: 2 }}>
      <Grid size={{ xs: 12 }} style={{ position: 'relative' }} sx={{ mb: 2 }}>
        <Typography variant="h6" color={missingValue ? 'error' : undefined}>
          {pluralize(name)} {required && '*'}
        </Typography>
        <Tooltip title={`Add ${pluralize.singular(name)}`}>
          <TopRightIconButton
            sx={{ p: 0.5, mt: 0.75 }}
            size="large"
            onClick={() => append({ value: null })}>
            <AddIcon />
          </TopRightIconButton>
        </Tooltip>
      </Grid>
      {fields.map((field, index) => (
        <Grid container key={field.id} alignItems="center">
          <Grid size={{ xs: 11 }}>
            <InputFieldComponent
              fieldKey={key}
              rhfFieldKey={`${rhfFieldKey}.${index}.value`}
              name={`${pluralize.singular(name)} (${index + 1})`}
              type={type}
              required={required}
              disabled={disabled}
              subjectId={subjectId}
            />
          </Grid>
          <Grid size={{ xs: 1 }}>
            <Tooltip title={`Remove ${pluralize.singular(name)}`}>
              <IconButton onClick={() => remove(index)} size="large" sx={{ p: 0.5 }}>
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ))}
      {missingValue && (
        <Grid size={{ xs: 12 }}>
          <FormHelperText error style={{ textAlign: 'center' }}>
            Required arrays must have at least one value
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  );
};

const CustomFieldInput: React.FC<CustomFieldProps> = ({
  disabled,
  customField: { key, name, type, required, isArray },
  subjectId,
  inputNamePrefix
}) => {
  const fullKey = `${inputNamePrefix ?? ''}${key}`;

  if (!isArray) {
    return (
      <InputFieldComponent
        fieldKey={key}
        name={name}
        type={type}
        required={required}
        disabled={disabled}
        rhfFieldKey={fullKey}
        subjectId={subjectId}
      />
    );
  }

  return (
    <ArrayInputFieldComponent
      fieldKey={key}
      name={name}
      type={type}
      required={required}
      disabled={disabled}
      rhfFieldKey={fullKey}
      subjectId={subjectId}
    />
  );
};

export default CustomFieldInput;
