import { Snackbar, Alert, AlertProps } from '@mui/material';
import { useContext, createContext, PropsWithChildren, useState } from 'react';

export type SnackbarOptions = {
  severity: AlertProps['severity'];
  message: string;
};

const SnackbarAlertContext = createContext<(options: SnackbarOptions) => void>(() => {
  return undefined;
});

export const SnackbarAlertProvider = ({ children }: PropsWithChildren) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [options, setOptions] = useState<SnackbarOptions | null>(null);

  const openSnackbar = (options: SnackbarOptions) => {
    setOptions(options);
    setShowSnackbar(true);
  };

  const onClose = () => {
    setShowSnackbar(false);
    setOptions(null);
  };

  return (
    <>
      <SnackbarAlertContext.Provider value={openSnackbar}>{children}</SnackbarAlertContext.Provider>

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={onClose}>
        <Alert onClose={onClose} severity={options?.severity}>
          {options?.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export const useSnackbarAlert = () => useContext(SnackbarAlertContext);
