import { gql } from '@apollo/client';
import { ISubjectForm } from '../Common';
import { Subject } from '../Subject';

export type FormCreatedSubscriptionData = {
  formCreated: ISubjectForm & {
    title: string;
    subject: Subject;
  };
};

export const FORM_CREATED_SUBSCRIPTION = gql`
  subscription FormCreated {
    formCreated {
      id
      title
      editLink
      subject {
        id
        customFieldValues {
          ... on SubjectCustomFieldSingleValue {
            valueText
          }
          customField {
            id
            key
          }
        }
      }
    }
  }
`;
