import { FieldType, InputField, UserIdentifierType, ValueInput } from '@aireframe/graphql';
import { assertUnreachable, UserIdentifier, valueOrNullIfEmpty } from '@aireframe/shared-types';
import { CustomFieldFormValue, CustomFieldFormValues } from './CustomFieldValidators';

const isStringOrNull = (value: CustomFieldFormValue): value is string | null => {
  return value === null || typeof value === 'string';
};

const isUserIdentifier = (value: CustomFieldFormValue): value is UserIdentifier | null => {
  return (value as UserIdentifier).userIdentifierType !== undefined;
};

export const MapCustomFieldFormValueToValueInput = (
  formValues: CustomFieldFormValues,
  inputField: Pick<InputField, 'isArray' | 'key' | 'type'>
): ValueInput | ValueInput[] => {
  const value = formValues[inputField.key];

  if (inputField.isArray) {
    if (!Array.isArray(value)) {
      throw new Error('Value is not an array, but input field is an array');
    }

    return value.map(v => convertStringValueToValueInput(v.value, inputField.type.key));
  }

  if (Array.isArray(value)) {
    throw new Error('Value is an array, but input field is not an array');
  }

  return convertStringValueToValueInput(value, inputField.type.key);
};

const convertStringValueToValueInput = (
  value: Exclude<CustomFieldFormValues[string], []>,
  fieldType: FieldType
): ValueInput => {
  switch (fieldType) {
    case FieldType.DATE_TIME: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }
      return {
        dateTime: { value: valueOrNullIfEmpty(value) }
      };
    }
    case FieldType.DATE: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }
      return {
        date: { value: valueOrNullIfEmpty(value) }
      };
    }
    case FieldType.TIME: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }
      return {
        time: { value: valueOrNullIfEmpty(value) }
      };
    }
    case FieldType.TEXT:
    case FieldType.EMAIL:
    case FieldType.PHONE_NUMBER:
    case FieldType.SEX:
    case FieldType.CONSTRAINED_VALUES: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }
      return {
        string: { value: valueOrNullIfEmpty(value) }
      };
    }
    case FieldType.NUMBER: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }

      const normalisedValue = valueOrNullIfEmpty(value);
      if (normalisedValue !== null && isNaN(Number(value))) {
        throw new Error('Value is not a string representation of a number');
      }

      return {
        decimal: { value: normalisedValue === null ? null : Number(normalisedValue) }
      };
    }
    case FieldType.USER_IDENTIFIER: {
      if (!isUserIdentifier(value)) {
        throw new Error('Value is not a UserIdentifier or null');
      }
      return {
        userIdentifier: {
          value: value
            ? {
                identifier: value.identifier,
                userIdentifierType:
                  value.userIdentifierType === 'USER'
                    ? UserIdentifierType.USER
                    : value.userIdentifierType === 'SUBJECT'
                      ? UserIdentifierType.SUBJECT
                      : UserIdentifierType.UNKNOWN
              }
            : null
        }
      };
    }
    default:
      return assertUnreachable(fieldType);
  }
};
