import { useLazyQuery } from '@apollo/client';
import {
  AccessibleStructureQueryData,
  AccessibleStructureQueryVariables,
  ACCESSIBLE_STRUCTURE_QUERY,
  TenantQuery
} from '@aireframe/graphql';

export const useTenantQuery = () => {
  const [query, { data, loading }] = useLazyQuery(TenantQuery);

  return { query, data: data?.tenant, loading };
};

export const useStructureQuery = () => {
  const [query, { data, loading, fetchMore }] = useLazyQuery<
    AccessibleStructureQueryData,
    AccessibleStructureQueryVariables
  >(ACCESSIBLE_STRUCTURE_QUERY);

  return { query, data: data?.me, loading, fetchMore };
};
