import { DataType, FieldFilter, StringComparator } from '@aireframe/shared-types';
import {
  FormControl,
  FormHelperText,
  Grid2 as Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  TextFieldProps
} from '@mui/material';
import FilterHeader from '../FilterHeader';
import { useRef, useState } from 'react';
import { LiquidEditor } from '../../Monaco';
import { ValueMode } from '../../ValueModeSwitch/ValueMode';
import ValueModeSwitch from '../../ValueModeSwitch/ValueModeSwitch';

const StringFilter = ({
  title,
  value: filter,
  onChange,
  onRemove,
  textFieldProps,
  resolvedStringValue
}: {
  title: string;
  value: FieldFilter<DataType.STRING>;
  onChange: (value: FieldFilter<DataType.STRING>) => void;
  onRemove: () => void;
  textFieldProps?: Omit<TextFieldProps, 'type' | 'defaultValue' | 'onBlur'>;
  resolvedStringValue?: string;
}) => {
  const [valueMode, setValueMode] = useState<ValueMode>(
    filter.liquidExpression ? 'liquid' : 'exact'
  );

  const onValueModeChange = (mode: ValueMode) => {
    if (mode === 'liquid') {
      const modified = {
        ...filter,
        value: {
          ...filter.value,
          stringValue: null
        }
      };
      onChange(modified);
    } else {
      const modified = {
        ...filter,
        liquidExpression: null
      };
      onChange(modified);
    }
    setValueMode(mode);
  };

  const adornmentRef = useRef<HTMLDivElement>(null);
  const slotProps = {
    input: {
      endAdornment: (
        <InputAdornment ref={adornmentRef} position="end">
          <ValueModeSwitch mode={valueMode} onModeChange={onValueModeChange} />
        </InputAdornment>
      )
    }
  };

  return (
    <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
      <Grid>
        <FilterHeader title={title} onClear={onRemove} />
      </Grid>
      <Grid>
        <FormControl fullWidth>
          <Select
            inputProps={{ 'aria-label': 'Filter type' }}
            value={filter.comparator}
            onChange={e => onChange({ ...filter, comparator: e.target.value as StringComparator })}
            style={{ textAlign: 'left' }}>
            <MenuItem value={StringComparator.Contains}>Contains</MenuItem>
            <MenuItem value={StringComparator.Equals}>Equals</MenuItem>
            <MenuItem value={StringComparator.StartsWith}>Starts with</MenuItem>
            <MenuItem value={StringComparator.EndsWith}>Ends With</MenuItem>
            <MenuItem value={StringComparator.NotContains}>Does Not Contain</MenuItem>
            <MenuItem value={StringComparator.NotEquals}>Does Not Equal</MenuItem>
            <MenuItem value={StringComparator.NotStartsWith}>Does Not Start With</MenuItem>
            <MenuItem value={StringComparator.NotEndsWith}>Does Not End With</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid>
        {valueMode === 'exact' && (
          <TextField
            label={title}
            {...textFieldProps}
            type="text"
            defaultValue={filter.value.stringValue}
            onBlur={event =>
              onChange({
                ...filter,
                value: {
                  dataType: DataType.STRING,
                  stringValue: event.target.value === '' ? null : event.target.value
                }
              })
            }
            slotProps={slotProps}
          />
        )}
        {valueMode === 'liquid' && (
          <>
            <LiquidEditor
              label="Liquid Expression"
              {...textFieldProps}
              type="text"
              fullWidth
              value={filter.liquidExpression ?? undefined}
              onChange={value =>
                !value || value === ''
                  ? onChange({ ...filter, liquidExpression: null })
                  : onChange({
                      ...filter,
                      liquidExpression: value
                    })
              }
              slotProps={slotProps}
              adornmentRef={adornmentRef}
            />
            {resolvedStringValue && (
              <FormHelperText>
                Resolved value: <b>{resolvedStringValue}</b>
              </FormHelperText>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default StringFilter;
