import { SubjectAvatar, useStructureContext } from '@aireframe/components';
import { Card, CardActionArea, CardContent, Grid2 as Grid, Typography } from '@mui/material';
import React from 'react';
import { LinkedSubject } from './LinkedSubjectQuery';
import { sortBy } from 'lodash-es';

export const SubjectSelector: React.FC<{
  subjects: Array<LinkedSubject>;
  onSelect: (subject: LinkedSubject) => void;
}> = ({ subjects, onSelect }) => {
  const { activeTenant } = useStructureContext();

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 0 }}>
          Select a {activeTenant?.subjectName.toLocaleLowerCase()}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12 }}>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          You have access to more than one {activeTenant?.subjectName.toLocaleLowerCase()}. Select
          one to continue.
        </Typography>
      </Grid>
      {sortBy(subjects, s => s.primaryFieldValue?.valueText).map(subject => (
        <Grid size={{ xs: 12, sm: subjects.length > 2 ? 4 : 6 }} key={subject.id}>
          <Card style={{ height: '100%' }}>
            <CardActionArea onClick={() => onSelect(subject)} style={{ height: '100%' }}>
              <CardContent>
                <Grid container spacing={2}>
                  {activeTenant?.avatarIsEnabled && (
                    <Grid container justifyContent="center" size={{ xs: 12 }}>
                      <Grid>
                        <SubjectAvatar size="large" subject={subject} />
                      </Grid>
                    </Grid>
                  )}
                  {subject.primaryFieldValue && (
                    <Grid size={{ xs: 12 }}>
                      <Typography variant="h6">{subject.primaryFieldValue.valueText}</Typography>
                    </Grid>
                  )}
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
