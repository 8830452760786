import { TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';
import React, { useState } from 'react';

export const DateFilter = ({
  value,
  onChange,
  endAdornment
}: {
  value: string | null;
  onChange: (value: string | null) => void;
  endAdornment?: React.ReactNode;
} & Omit<
  TextFieldProps,
  'type' | 'defaultValue' | 'onBlur' | 'value' | 'onChange' | 'slotProps'
>) => {
  const handleChangeAbsolute = (newValue: Dayjs | null) => {
    if (newValue !== null && newValue.isValid()) {
      onChange(newValue.format('YYYY-MM-DD'));
    } else {
      onChange(null);
    }
  };

  const [open, setOpen] = useState(false);
  const slotProps = {
    textField: {
      InputProps: {
        endAdornment: endAdornment
      },
      onClick: () => setOpen(true)
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DatePicker
        sx={{ width: '100%' }}
        open={open}
        onClose={() => setOpen(false)}
        slotProps={slotProps}
        label="Value"
        defaultValue={value ? dayjs(value) : null}
        onChange={handleChangeAbsolute}
      />
    </LocalizationProvider>
  );
};
