import {
  ActionableDataPoint,
  ConnectionPageInfo,
  convertWidgetContextToInput,
  DataPointAction,
  FilterResult,
  MappedDataPoint,
  OrderDirection,
  Section,
  useVisualisationPendingUpdateSubscription,
  WidgetContext
} from '@aireframe/graphql';
import {
  ActiveVisualisationFilter,
  LogLevel,
  VisualisationRenderType,
  VisualisationType
} from '@aireframe/shared-types';
import { Card, Divider, Grid, styled } from '@mui/material';
import React, { Fragment, useMemo, useRef, useState } from 'react';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { FieldsFilter } from '../../../Filter/FieldsFilter';
import { Pagination, CursorPagination } from '../../../Pagination';
import WidgetHeader from '../../WidgetHeader';
import { ActionContainer } from '../Actions/ActionContainer';
import Chart from '../Chart/Chart';
import { isActionableDataPoint } from '../DataVisualisationHelpers';
import Liquid from '../Liquid/Liquid';
import DataTable from '../Table/DataTable';
import { shouldShowOrdering } from '../Utils';
import { VisualisationContext } from '../VisualisationContext';
import { getSortingOptions } from './DataVisualisationFuncs';
import DataVisualisationHeaderContent from './Header/DataVisualisationHeaderContent';
import StaleIndicator from './Header/StaleIndicator';
import { Dayjs } from 'dayjs';
import { useNavigate, useSearch } from '@tanstack/react-router';

export interface DataVisualisationProps {
  widgetContext: WidgetContext;
  dataVisualisationDefinition: VisualisationRenderType;
  section: Section;
}
const StyledGrid = styled(Grid)(({ theme }) => ({
  height: 316,
  maxHeight: 348,
  overflowY: 'auto',
  [theme.breakpoints.down('md')]: {
    minHeight: 50,
    height: 'auto',
    maxHeight: 'none'
  }
}));

const DataVisualisationContainer: React.FC<DataVisualisationProps> = ({
  widgetContext,
  dataVisualisationDefinition,
  section
}) => {
  const { current: chartHeight } = useRef(300);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [currentAction, setCurrentAction] = useState<{
    dataPoint: ActionableDataPoint;
    action: DataPointAction;
  }>();

  const navigate = useNavigate();
  const { visualisationFilters, ...otherParams } = useSearch({ strict: false });

  const filter = useMemo(() => {
    const activeFilters = (visualisationFilters as ActiveVisualisationFilter[]) || [];
    const activeFilter = activeFilters.find(
      (filterInput: ActiveVisualisationFilter) =>
        filterInput.visualisationId === dataVisualisationDefinition.id
    );
    return activeFilter
      ? FieldsFilter.fromFilterInput(activeFilter.filter)
      : FieldsFilter.fromFilterInput(dataVisualisationDefinition.defaultFilter);
  }, [visualisationFilters, dataVisualisationDefinition]);

  const setFilterInUrl = (newFilter: FieldsFilter) => {
    const existingFilters = (visualisationFilters as ActiveVisualisationFilter[]) || [];
    const updatedFilters = existingFilters.map((filter: ActiveVisualisationFilter) =>
      filter.visualisationId === dataVisualisationDefinition.id
        ? { ...filter, filter: newFilter.toFilterInput() }
        : filter
    );

    if (!updatedFilters.some(filter => filter.visualisationId === dataVisualisationDefinition.id)) {
      updatedFilters.push({
        visualisationId: dataVisualisationDefinition.id,
        filter: newFilter.toFilterInput()
      });
    }

    const newFilters = {
      ...otherParams,
      visualisationFilters: updatedFilters
    };

    navigate({ to: '.', search: newFilters });
  };

  const [filterResult, setFilterResult] = useState<FilterResult | undefined>(undefined);
  const [lastPossibleUpdate, setLastPossibleUpdate] = useState<Dayjs | null>(null);

  const [cursorPagination, setCursorPagination] = useState(
    new CursorPagination({
      orderBy: dataVisualisationDefinition.defaultOrdering.key,
      orderDirection: dataVisualisationDefinition.defaultOrdering.orderDirection as OrderDirection,
      pageSize: dataVisualisationDefinition.type === VisualisationType.TABLE ? 10 : null
    })
  );

  const [pagingationState, setPagingationState] = useState<{
    pageInfo: Omit<ConnectionPageInfo, '__typename'>;
    totalCount?: number;
  }>({
    pageInfo: { hasNextPage: false, hasPreviousPage: false, startCursor: null, endCursor: null }
  });

  const onActionClicked = (dataPoint: MappedDataPoint, action: DataPointAction) => {
    if (isActionableDataPoint(dataPoint)) {
      setCurrentAction({ dataPoint, action });
    }
  };

  const isUnavailableInSection =
    section === Section.LOCATION && !dataVisualisationDefinition.isCacheable;

  const { updatePending, setUpdatePending } = useVisualisationPendingUpdateSubscription(
    dataVisualisationDefinition,
    convertWidgetContextToInput(widgetContext)
  );

  const renderVisualisation = () => {
    switch (dataVisualisationDefinition.type) {
      case VisualisationType.GRAPH:
      case VisualisationType.PIE_CHART:
        return <Chart height={chartHeight} onActionClicked={onActionClicked} />;
      case VisualisationType.TABLE:
        return <DataTable onActionClicked={onActionClicked} />;
      case VisualisationType.LIQUID:
        return <Liquid />;
    }
  };

  return (
    <VisualisationContext.Provider
      value={{
        widgetContext,
        definition: dataVisualisationDefinition,
        section,
        setConnectionInfo: setPagingationState,
        totalCount: pagingationState?.totalCount || 0,
        filter,
        setFilter: setFilterInUrl,
        connectionQueryVariables: cursorPagination.connectionVariables,
        setIsUpdatePending: setUpdatePending,
        setLastPossibleUpdate,
        filterResult,
        setFilterResult
      }}>
      <Card aria-label={dataVisualisationDefinition.title} data-testid="data visualisation">
        <WidgetHeader
          title={dataVisualisationDefinition.title}
          staleIndicator={
            dataVisualisationDefinition.isCacheable &&
            lastPossibleUpdate && <StaleIndicator lastPossibleUpdate={lastPossibleUpdate} />
          }
          updatePending={updatePending}>
          <DataVisualisationHeaderContent expanded={expanded} setExpanded={setExpanded} />
        </WidgetHeader>
        {expanded && (
          <Fragment>
            <Divider />
            <StyledGrid item xs={12}>
              {currentAction && (
                <ActionContainer
                  dataPoint={currentAction.dataPoint}
                  action={currentAction.action}
                  onClose={() => setCurrentAction(undefined)}
                />
              )}
              {isUnavailableInSection ? (
                <ErrorMessage message="Non-cacheable visualisations are not available in this section" />
              ) : (
                <ErrorBoundary
                  shouldLog={false}
                  context={`${DataVisualisationContainer.name} - ${dataVisualisationDefinition.key}`}
                  message="Something went wrong rendering this visualisation, please check your configuration"
                  level={LogLevel.Warn}>
                  {renderVisualisation()}
                </ErrorBoundary>
              )}
            </StyledGrid>
            <Grid item xs={12}>
              <Pagination
                value={cursorPagination}
                setValue={setCursorPagination}
                pageInfo={pagingationState.pageInfo}
                totalCount={pagingationState.totalCount}
                orderByOptions={getSortingOptions(dataVisualisationDefinition)}
                showOrdering={shouldShowOrdering(dataVisualisationDefinition)}
              />
            </Grid>
          </Fragment>
        )}
      </Card>
    </VisualisationContext.Provider>
  );
};

export default DataVisualisationContainer;
