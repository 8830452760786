import {
  assertUnreachable,
  DataType,
  FieldFilter,
  isBooleanFieldFilter,
  isDateFieldFilter,
  isDateTimeFieldFilter,
  isDecimalFieldFilter,
  isIntegerFieldFilter,
  isStringFieldFilter,
  isTimeFieldFilter,
  isUserIdentifierFieldFilter,
  StringComparator
} from '@aireframe/shared-types';
import { BooleanFilter } from './Boolean/BooleanFilter';
import { NumericComparatorFilter } from './Comparative/NumericComparatorFilter';
import { FieldsFilter } from './FieldsFilter';
import StringFilter from './String/StringFilter';
import UserIdentifierFilter from './UserIdentifier/UserIdentifierFilter';

const ActiveFilter = ({
  filterKey: key,
  title,
  filters,
  filterState,
  setFilterState,
  resolvedStringValue
}: {
  filterKey: string;
  title: string;
  filters: ReadonlyArray<FieldFilter>;
  filterState: FieldsFilter;
  setFilterState: (fieldsFilter: FieldsFilter) => void;
  resolvedStringValue?: string;
}) => {
  if (filters.every(isBooleanFieldFilter)) {
    // Boolean is the special case as it is the only one that gets applied as OR (multiple values)
    return (
      <BooleanFilter
        title={title}
        onChange={values => {
          filterState.removeFiltersByFieldKey(key);
          filterState.andAny(
            values.map((v, idx) => ({
              id: -1 * idx,
              key: key,
              value: { dataType: DataType.BOOLEAN, booleanValue: v },
              liquidExpression: null
            }))
          );
          setFilterState(filterState);
        }}
        values={filters.map(fitler => fitler.value.booleanValue)}
      />
    );
  }

  const filter = filters[0];

  const onChange = (value: FieldFilter) => {
    filterState.and(value);
    setFilterState(filterState);
  };

  const onRemove = () => {
    filterState.removeFilterById(filter.id);
    setFilterState(filterState);
  };

  if (
    isIntegerFieldFilter(filter) ||
    isDecimalFieldFilter(filter) ||
    isDateTimeFieldFilter(filter) ||
    isDateFieldFilter(filter) ||
    isTimeFieldFilter(filter)
  ) {
    return (
      <NumericComparatorFilter
        title={title}
        onChange={onChange}
        onRemove={onRemove}
        value={filter}
        resolvedStringValue={resolvedStringValue}
      />
    );
  }

  const textFieldProps = {
    name: title,
    inputProps: { 'aria-label': title },
    fullWidth: true,
    label: 'Value'
  };

  if (isStringFieldFilter(filter)) {
    return (
      <StringFilter
        title={title}
        value={filter}
        textFieldProps={{
          ...textFieldProps,
          required: filter.comparator !== StringComparator.Equals
        }}
        onChange={onChange}
        onRemove={onRemove}
        resolvedStringValue={resolvedStringValue}
      />
    );
  }

  if (isUserIdentifierFieldFilter(filter)) {
    return (
      <UserIdentifierFilter
        title={title}
        value={filter}
        onChange={onChange}
        onRemove={onRemove}
        textFieldProps={textFieldProps}
      />
    );
  }

  if (isBooleanFieldFilter(filter)) {
    return null; // Handled above
  }

  assertUnreachable(filter);
};

export default ActiveFilter;
