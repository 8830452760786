import { ResultOf } from '@graphql-typed-document-node/core';
import { query } from '../../api.gen';

export const CurrentUserQuery = query('CurrentUser', q => [
  q.me(m => [
    m.__typename,
    m.id,
    m.displayName,
    m.permissions(p => [p.__typename, p.action, p.subject])
  ])
]);

export type ActiveUser = ResultOf<typeof CurrentUserQuery>['me'];
