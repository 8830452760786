import { ActionableDataPoint, GeneratedGQLApi } from '@aireframe/graphql';
import { Visualisation } from '@aireframe/shared-types';
import { useMutation } from '@apollo/client';
import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core';

export const ErrorMessage = 'An error occurred, please try again later.';

export const PerformActionMutation = GeneratedGQLApi.mutation('PerformAction', m => [
  m.performDataPointAction(
    {
      dataVisualisationId: GeneratedGQLApi.$$('dataVisualisationId'),
      dataPointId: GeneratedGQLApi.$$('dataPointId'),
      subjectId: GeneratedGQLApi.$$('subjectId'),
      action: GeneratedGQLApi.$$('action')
    },
    result => [
      result.__typename,
      result.success,
      result.message,
      result.iFrameToDisplay(iFrame => [iFrame.__typename, iFrame.url, iFrame.sendToken])
    ]
  )
]);
type PerformActionResponse = VariablesOf<typeof PerformActionMutation>['action'];

type UsePerformActionValue = {
  loading: boolean;
  result: ResultOf<typeof PerformActionMutation>['performDataPointAction'] | null;
  reset: () => void;
  performAction: (dataPoint: ActionableDataPoint, action: PerformActionResponse) => void;
};

const usePerformAction = (
  dataVisualisation: Pick<Visualisation, 'id'>,
  options?: {
    onSuccess?: (data: ResultOf<typeof PerformActionMutation>) => void;
    onError?: () => void;
  }
): UsePerformActionValue => {
  const [mutate, { data, loading, error, reset }] = useMutation(PerformActionMutation, {
    onError: options?.onError,
    onCompleted: options?.onSuccess
  });

  const performActionCallback = (dataPoint: ActionableDataPoint, action: PerformActionResponse) => {
    mutate({
      variables: {
        dataVisualisationId: dataVisualisation.id,
        dataPointId: dataPoint.sourceDataPointId,
        subjectId: dataPoint.subjectId,
        action
      }
    });
  };

  let result: UsePerformActionValue['result'] = null;

  if (error) {
    result = {
      __typename: 'PerformActionResult',
      success: false,
      message: ErrorMessage,
      iFrameToDisplay: undefined
    };
  } else if (data) {
    result = data.performDataPointAction;
  }

  return {
    loading,
    result,
    reset,
    performAction: performActionCallback
  };
};

export default usePerformAction;
