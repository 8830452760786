import { GeneratedGQLApi } from '@aireframe/graphql';
import { ExcludeNullable } from '@aireframe/shared-types';
import { ResultOf } from '@graphql-typed-document-node/core';

export const LinkedSubjectQuery = GeneratedGQLApi.query('LinkedSubjectQuery', q => [
  q.me(m => [
    m.__typename,
    m.id,
    m.linkedSubjects(ls => [
      ls.__typename,
      ls.nodes(n => [
        n.__typename,
        n.id,
        n.avatarHash,
        n.primaryFieldValue(pf => [pf.__typename, pf.valueText])
      ])
    ])
  ])
]);

export type LinkedSubject = ExcludeNullable<
  ExcludeNullable<ResultOf<typeof LinkedSubjectQuery>['me']['linkedSubjects']>['nodes']
>[number];
