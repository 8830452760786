import {
  convertWidgetContextToInput,
  ErrorParser,
  GeneratedGQLApi,
  useVisualisationDataChangedSubscription
} from '@aireframe/graphql';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import Loading from '../../../Loading/Loading';
import SanitizedHTML from '../../../SanitizedHTML/SanitizedHTML';
import { NoDataMessage } from '../NoDataMessage';
import { useVisualisationContext } from '../VisualisationContext';
import { WidgetErrorMessage } from '../Chart';
import dayjs from 'dayjs';

const whitelist: XSS.IWhiteList = {
  p: ['style'],
  h1: ['style'],
  h2: ['style']
};

export const GET_LIQUID_WIDGET_RENDERED_HTML = GeneratedGQLApi.query(
  'LiquidWidgetRenderedHTML',
  query => [
    query.visualisation({ id: GeneratedGQLApi.$$('widgetId') }, visualisation => [
      visualisation.__typename,
      visualisation.id,
      visualisation.displayOptions(displayOptions => [
        displayOptions.$on('LiquidDisplayOption', liquidDisplayOptions => [
          liquidDisplayOptions.__typename,
          liquidDisplayOptions.renderHtml(
            { context: GeneratedGQLApi.$$('context') },
            renderHtml => [
              renderHtml.__typename,
              renderHtml.result,
              renderHtml.isPending,
              renderHtml.lastPossibleUpdate
            ]
          )
        ])
      ])
    ])
  ]
);

const Liquid: React.FC = () => {
  const {
    definition,
    widgetContext,
    setConnectionInfo,
    setIsUpdatePending,
    setLastPossibleUpdate
  } = useVisualisationContext();
  const { data, loading, error, refetch } = useQuery(GET_LIQUID_WIDGET_RENDERED_HTML, {
    variables: {
      widgetId: definition.id,
      context: convertWidgetContextToInput(widgetContext)
    },
    onCompleted: data => {
      if (data.visualisation?.displayOptions) {
        const { renderHtml } = data.visualisation.displayOptions;
        setIsUpdatePending(renderHtml?.isPending ?? true);

        if (renderHtml) {
          setLastPossibleUpdate(dayjs(renderHtml.lastPossibleUpdate));
        }
      } else {
        setIsUpdatePending(false);
      }
    }
  });

  useVisualisationDataChangedSubscription(definition, widgetContext, refetch);

  useEffect(() => {
    setConnectionInfo({
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        endCursor: null,
        startCursor: null
      },
      totalCount: data?.visualisation?.displayOptions?.renderHtml?.result ? 1 : 0
    });
  }, [data, setConnectionInfo]);

  if (loading) {
    return <Loading />;
  }

  if (error) return <WidgetErrorMessage errors={ErrorParser.parse(error)} />;
  if (!data?.visualisation?.displayOptions?.renderHtml?.result) {
    return <NoDataMessage />;
  }

  return (
    <SanitizedHTML
      html={data.visualisation.displayOptions.renderHtml.result}
      additionalWhiteList={whitelist}
    />
  );
};

export default Liquid;
