import { FieldType, InputField } from '@aireframe/graphql';
import { assertUnreachable, UserIdentifierType } from '@aireframe/shared-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isPossibleNumber } from 'libphonenumber-js';
import * as zod from 'zod';

dayjs.extend(customParseFormat);

const VALID_DATE_FORMATS = ['YYYY-MM-DD'];
const VALID_TIME_FORMATS = ['HH:mm', 'HH:mmZ', 'HH:mm[Z]', 'HH:mm:ss', 'HH:mm:ssZ', 'HH:mm:ss[Z]'];
const VALID_DATETIME_FORMATS = [
  'YYYY-MM-DDTHH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss[Z]',
  'YYYY-MM-DDTHH:mm:ssZ[Z]',
  'YYYY-MM-DDTHH:mm'
];

const dateFieldZodSchema = zod
  .string({ message: 'Required' })
  .trim()
  .min(1, { message: 'Required' })
  .refine(
    (value): boolean => VALID_DATE_FORMATS.some(format => dayjs(value, format, true).isValid()),
    {
      message: 'Invalid date format'
    }
  );

const timeFieldZodSchema = zod
  .string({ message: 'Required' })
  .trim()
  .min(1, { message: 'Required' })
  .refine(
    (value): boolean => VALID_TIME_FORMATS.some(format => dayjs(value, format, true).isValid()),
    {
      message: 'Invalid time format'
    }
  );

const dateTimeFieldZodSchema = zod
  .string({ message: 'Required' })
  .trim()
  .min(1, { message: 'Required' })
  .refine(
    (value): boolean => VALID_DATETIME_FORMATS.some(format => dayjs(value, format, true).isValid()),
    { message: 'Invalid date time format' }
  );

const phoneNumberFieldZodSchema = zod
  .string({ message: 'Required' })
  .trim()
  .min(1, { message: 'Required' })
  .refine((value: string): boolean => !/\s/.test(value) && isPossibleNumber(value), {
    message: 'Invalid phone number'
  });

export function getInputFieldZodSchema(field: Pick<InputField, 'isArray' | 'required' | 'type'>) {
  const noValueStringType = zod.string().max(0).nullable();

  const type = field.type;
  switch (type.key) {
    case FieldType.TEXT:
      return createSchema(
        zod.string({ message: 'Required' }).trim().min(1, { message: 'Required' }),
        zod.string().trim().max(0).nullable()
      );
    case FieldType.NUMBER:
      return createSchema(
        zod
          .string({ message: 'Required' })
          .trim()
          .min(1, { message: 'Required' })
          .refine(value => !isNaN(Number(value)), { message: 'Invalid number' }),
        noValueStringType
      );
    case FieldType.DATE:
      return createSchema(dateFieldZodSchema, noValueStringType);
    case FieldType.DATE_TIME:
      return createSchema(dateTimeFieldZodSchema, noValueStringType);
    case FieldType.TIME:
      return createSchema(timeFieldZodSchema, noValueStringType);
    case FieldType.EMAIL:
      return createSchema(
        zod.string({ message: 'Required' }).email({ message: 'Invalid Email' }),
        noValueStringType
      );
    case FieldType.PHONE_NUMBER:
      return createSchema(phoneNumberFieldZodSchema, noValueStringType);
    case FieldType.SEX:
    case FieldType.CONSTRAINED_VALUES: {
      if (type.__typename !== 'MultipleChoiceFieldType') {
        throw new Error(`Invalid field typename '${type.__typename}' for type '${type.key}'`);
      }

      const validator = zod
        .string({ message: 'Required' })
        .refine(value => type.options.includes(value), {
          message: 'Invalid value'
        });

      return createSchema(validator, noValueStringType);
    }
    case FieldType.USER_IDENTIFIER: {
      const validator = zod.object(
        {
          identifier: zod.string().trim().min(1),
          userIdentifierType: zod.nativeEnum(UserIdentifierType)
        },
        { message: 'Required' }
      );

      return createSchema(validator, zod.null());
    }
    default:
      return assertUnreachable(type.key);
  }

  function createSchema<TValue extends Zod.Schema, TNoValue extends Zod.Schema>(
    typeSchema: TValue,
    noValueSchema: TNoValue
  ) {
    if (field.isArray) {
      return zod.union([
        zod
          .array(zod.object({ value: typeSchema }, { message: 'Required' }))
          .nonempty({ message: 'Must have at least one value' }),
        field.required
          ? zod.never()
          : zod
              .array(zod.object({ value: noValueSchema }, { message: 'Required' }))
              .max(1, { message: 'Only one empty value allowed' })
      ]);
    }

    return zod.union([typeSchema, field.required ? zod.never() : noValueSchema]);
  }
}

export const buildInputFieldsSchema = (
  fields: Array<Omit<InputField, '__typename'>>
): Zod.ZodSchema => {
  let zodSchema = zod.object({});

  fields.forEach(field => {
    zodSchema = zodSchema.merge(zod.object({ [field.key]: getInputFieldZodSchema(field) }));
  });

  return zodSchema;
};

export type CustomFieldFormValue = zod.infer<ReturnType<typeof getInputFieldZodSchema>>;
export type CustomFieldFormValues = {
  [key: string]: CustomFieldFormValue;
};
