import { ErrorMessage, Loading, useStructureContext } from '@aireframe/components';
import { useQuery } from '@apollo/client';
import { useNavigate } from '@tanstack/react-router';
import { SubjectSelector } from './SubjectSelector';
import { LinkedSubject, LinkedSubjectQuery } from './LinkedSubjectQuery';

export const LandingPage: React.FC = () => {
  const { activeTenant, loading } = useStructureContext();
  const { data: linkedSubjectData, loading: linkedSubjectLoading } = useQuery(LinkedSubjectQuery);
  const navigate = useNavigate();

  const navigateToSubject = (subject: LinkedSubject) => {
    navigate({ to: '/$id', params: { id: subject.id } });
  };

  if (loading || linkedSubjectLoading) {
    return <Loading fullscreen />;
  }

  if (!linkedSubjectData?.me.linkedSubjects?.nodes || !activeTenant) {
    return <ErrorMessage />;
  }

  if (linkedSubjectData.me.linkedSubjects.nodes.length === 0) {
    return <ErrorMessage message={activeTenant.subjectNotLinkedErrorMessage} />;
  }

  if (linkedSubjectData.me.linkedSubjects.nodes.length > 1) {
    return (
      <SubjectSelector
        subjects={linkedSubjectData.me.linkedSubjects.nodes}
        onSelect={navigateToSubject}
      />
    );
  }

  navigateToSubject(linkedSubjectData.me.linkedSubjects.nodes[0]);
};
