import { fragment, Subject } from '../../api.gen';
import { CustomFieldFragment } from '../Fragments';

export const SubjectFragment = fragment(Subject, subject => [
  subject.__typename,
  subject.id,
  subject.customFieldValues(value => [
    value.$on('SubjectCustomFieldArrayValue', av => [av.__typename, av.values(v => [v.valueText])]),
    value.$on('SubjectCustomFieldSingleValue', singleValue => [
      singleValue.__typename,
      singleValue.valueText
    ]),
    value.customField(_ => CustomFieldFragment)
  ]),
  subject.avatarHash
]);
