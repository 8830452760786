import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

export const IntegerFilter = ({
  value,
  onChange,
  endAdornment,
  ...props
}: {
  value: number | null;
  onChange: (value: number | null) => void;
  endAdornment?: React.ReactNode;
} & Omit<
  TextFieldProps,
  'type' | 'defaultValue' | 'onBlur' | 'value' | 'onChange' | 'slotProps'
>) => {
  return (
    <TextField
      {...props}
      type="number"
      defaultValue={value?.toString() ?? ''}
      onBlur={event =>
        event.target.value === '' ? onChange(null) : onChange(parseInt(event.target.value))
      }
      slotProps={{
        input: {
          endAdornment: endAdornment
        },
        htmlInput: { ...props.inputProps, step: 1 }
      }}
      sx={{
        width: '100%',
        marginTop: '10px'
      }}
    />
  );
};
