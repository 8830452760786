import { fragment, Tenant } from '../../../api.gen';
import { CustomFieldFragment } from '../../Fragments/CustomFieldsFragment';

export const BaseTenantFragment = fragment(Tenant, t => [
  t.__typename,
  t.id,
  t.key,
  t.displayName,
  t.environmentKey,
  t.subjectName,
  t.locationName,
  t.showRoles,
  t.avatarIsEnabled,
  t.features(f => [f.__typename, f.featureKey, f.isEnabled]),
  t.customFields(cf => [
    cf.__typename,
    cf.primaryField(pf => [pf.__typename, pf.field(_ => CustomFieldFragment), pf.positionIndex]),
    cf.groups(g => [
      g.__typename,
      g.key,
      g.name,
      g.showByDefault,
      g.bannerPositionIndex,
      g.customFields(c => [c.__typename, c.field(_ => CustomFieldFragment), c.positionIndex])
    ]),
    cf.hiddenFields(h => [h.__typename, h.field(_ => CustomFieldFragment), h.positionIndex])
  ])
]);
