import dayjs, { Dayjs } from 'dayjs';
import pluralize from 'pluralize';

const getLanguage = (): string => {
  if (navigator.languages !== undefined) return navigator.languages[0];
  return navigator.language;
};

export const formatDate = (date: Date | string | number) => {
  return new Date(date).toLocaleDateString(getLanguage(), {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  });
};

export const formatTime = (date: Date | string | number) => {
  return new Date(date).toLocaleTimeString(getLanguage(), {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
};

export const formatDateTime = (date: Date | string | number) => {
  return new Date(date).toLocaleString(getLanguage(), {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
};

export const getHumanizedTime = (date: Dayjs) => {
  const secondsDiff = dayjs().diff(date, 'seconds');

  if (secondsDiff < 3600) {
    const minutes = Math.floor(secondsDiff / 60);
    return `${minutes} ${pluralize('minute', minutes)} ago`;
  } else if (secondsDiff < 86400) {
    const hours = Math.floor(secondsDiff / 3600);
    return `${hours} ${pluralize('hour', hours)} ago`;
  } else {
    const days = Math.floor(secondsDiff / 86400);
    return `${days} ${pluralize('day', days)} ago`;
  }
};
