import { fragment, ISubjectCustomField } from '../../api.gen';

export const CustomFieldFragment = fragment(ISubjectCustomField, cf => [
  cf.id,
  cf.key,
  cf.name,
  cf.$on('SubjectInputField', inputField => [
    inputField.__typename,
    inputField.type(type => [
      type.key,
      type.dataType,
      type.$on('MultipleChoiceFieldType', mcf => [mcf.__typename, mcf.options]),
      type.$on('SimpleFieldType', sf => [sf.__typename])
    ]),
    inputField.required,
    inputField.unique,
    inputField.formPositionIndex,
    inputField.isArray
  ]),
  cf.$on('SubjectComputedField', computedField => [
    computedField.__typename,
    computedField.liquidMapping
  ])
]);
