import {
  DataPointsFilter,
  dataPointsFilterInputSchema,
  FieldFilterInput,
  FilterableField
} from '@aireframe/shared-types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as zod from 'zod';
import { FiltersList } from './FiltersList';
import { FieldsFilter } from './FieldsFilter';

type Props = {
  fields: FilterableField[];
  filter: DataPointsFilter | null | undefined;
  keyMapping?: {
    mapper: (filter: FieldFilterInput) => string;
    extractor: (key: string) => { visualisationId: string | null; key: string };
  };
  onSubmit: (filter: DataPointsFilter | null) => void;
  resolvedLiquidValues: { fieldId: number; resolvedStringValue: string }[] | undefined;
};

const formSchema = zod.object({
  filter: dataPointsFilterInputSchema.nullable()
});

type FormValues = zod.infer<typeof formSchema>;

export const FilterForm = ({
  fields,
  filter,
  keyMapping,
  onSubmit,
  resolvedLiquidValues
}: Props) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset
  } = useForm<FormValues>({
    defaultValues: { filter: filter },
    resolver: zodResolver(formSchema)
  });

  const internalSubmit = (formValues: FormValues) => {
    onSubmit(formValues.filter);
    reset(formValues, { keepDirty: false });
  };

  const onClear = () => {
    onSubmit(null);
    reset({ filter: null }, { keepDirty: false });
  };

  // Temporary until we integrate with RHF better.
  const supressEnterSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <form onSubmit={handleSubmit(internalSubmit)} onKeyDown={supressEnterSubmit}>
      <Controller
        name="filter"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FiltersList
            title="Filter"
            fields={fields}
            value={
              value ? FieldsFilter.fromFilterInput(value, keyMapping?.mapper) : new FieldsFilter()
            }
            onChange={(filter: FieldsFilter) =>
              onChange(filter.toFilterInput(keyMapping?.extractor))
            }
            resolvedLiquidValues={resolvedLiquidValues}
          />
        )}
      />
      <Grid container item justifyContent="space-between">
        <Grid item>
          <Button onClick={onClear} color="error" disabled={!filter}>
            Clear
          </Button>
        </Grid>

        <Grid item>
          <Button type="submit" color="primary" disabled={!isDirty}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
