import { PureAbility } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import React, { createContext } from 'react';
import { AppAbilities, AppAbility } from './AppAbilities';
import { useCurrentUser } from './CurrentUser';
import { useFeatureSetController } from './FeatureToggle';
import { defineAbilitiesFor } from './UserAbilities';
import { Permission } from '@aireframe/shared-types';

export const AbilityContext = createContext<AppAbility>(new PureAbility<AppAbilities>());

export const AbilityContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { activeUser } = useCurrentUser();
  const featureSetController = useFeatureSetController();

  const ability = defineAbilitiesFor(
    (activeUser?.permissions as Array<Permission> | undefined) ?? [],
    featureSetController
  );

  return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};

export const Can = createContextualCan(AbilityContext.Consumer);
