import { query } from '../../api.gen';
import { CustomFieldFragment } from '../Fragments';
import { ResultOf } from '@graphql-typed-document-node/core';

export const TenantConfigurationQuery = query('TenantSubjectConfiguration', q => [
  q.tenant(t => [
    t.__typename,
    t.id,
    t.subjectName,
    t.locationName,
    t.showRoles,
    t.avatarIsEnabled,
    t.customFields(cf => [
      cf.__typename,
      cf.primaryField(pf => [
        pf.__typename,
        pf.field(f => [...CustomFieldFragment, f.canModify]),
        pf.positionIndex
      ]),
      cf.groups(g => [
        g.__typename,
        g.key,
        g.name,
        g.showByDefault,
        g.bannerPositionIndex,
        g.customFields(c => [
          c.__typename,
          c.field(f => [...CustomFieldFragment, f.canModify]),
          c.positionIndex
        ])
      ]),
      cf.hiddenFields(h => [
        h.__typename,
        h.field(f => [...CustomFieldFragment, f.canModify]),
        h.positionIndex
      ])
    ]),
    t.tenantSubjectProvider(tsp => [
      tsp.__typename,
      tsp.dataSource(d => [d.__typename, d.id, d.key, d.name]),
      tsp.configuration(c => [
        c.__typename,
        c.subjectMapping(sm => [
          sm.__typename,
          sm.externalIdMapping,
          sm.liquidLookupExpression,
          sm.customFieldMappings(m => [
            m.__typename,
            m.mapping,
            m.customField(f => [f.__typename, f.id, f.key])
          ])
        ])
      ]),
      tsp.subjectProvider(p => [p.__typename, p.requiresSubjectFieldMapping])
    ])
  ])
]);

export type TenantConfigurationQueryData = ResultOf<typeof TenantConfigurationQuery>['tenant'];
