import { getSubjectPrimaryFieldValue, Subject } from '@aireframe/graphql';
import { Button, ButtonProps } from '@mui/material';
import { Link, LinkComponent } from '@tanstack/react-router';
import React from 'react';
import { useStructureContext } from '../Structure';

interface Props {
  subject: Pick<Subject, 'id' | 'customFieldValues'>;
  buttonProps?: Omit<ButtonProps<LinkComponent<'a'>>, 'component'>;
}

const ViewSubjectRecordButton: React.FC<Props> = ({ subject, buttonProps }) => {
  const { activeTenant } = useStructureContext();

  return (
    <Button
      // eslint-disable-next-line react/display-name
      component={React.forwardRef((props, ref) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore This type isn't correct, but it needs to be refactored to not be consumed by the subject access app
        <Link to={'/subjects/$id'} params={{ id: subject.id }} ref={ref} {...props} />
      ))}
      data-testid="viewSubjectRecord"
      aria-label={`View ${getSubjectPrimaryFieldValue(subject, activeTenant) ?? subject.id}`}
      variant="contained"
      color="primary"
      fullWidth
      {...(buttonProps ?? {})}>
      View Record
    </Button>
  );
};

export default ViewSubjectRecordButton;
