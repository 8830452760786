import { assertUnreachable } from '@aireframe/shared-types';
import { FieldTypeKey } from '../../api.gen';
import { constants } from '../Constants';
import Subject from '../Subject/Subject';
import { ITenant } from '../Tenant';
import { getAllCustomFields } from '../Tenant/CustomFields';
import {
  ComputedField,
  InputField,
  MultipleChoiceInputFieldType,
  SimpleInputFieldType,
  SubjectComputedField,
  SubjectCustomField,
  SubjectCustomFieldValue,
  SubjectInputField
} from './Types';

export function isMultipleChoiceInputFieldType(
  inputFieldType: SimpleInputFieldType | MultipleChoiceInputFieldType
): inputFieldType is MultipleChoiceInputFieldType {
  return (inputFieldType as MultipleChoiceInputFieldType).options !== undefined;
}

export function isComputedField<T extends Omit<ComputedField | InputField, '__typename'>>(
  customField: T
): customField is Exclude<T, Omit<InputField, '__typename'>> {
  return (customField as Omit<ComputedField, '__typename'>).liquidMapping !== undefined;
}

export function isInputField<T extends Omit<ComputedField | InputField, '__typename'>>(
  customField: T
): customField is Exclude<T, Omit<ComputedField, '__typename'>> {
  return (customField as Omit<InputField, '__typename'>)?.required !== undefined;
}

export function filterTenantCustomFieldsByType(
  tenant: ITenant,
  x: SubjectComputedField['__typename']
): Array<SubjectComputedField>;

export function filterTenantCustomFieldsByType(
  tenant: ITenant,
  x: SubjectInputField['__typename']
): Array<SubjectInputField>;

export function filterTenantCustomFieldsByType(
  tenant: ITenant,
  type: SubjectComputedField['__typename'] | SubjectInputField['__typename']
): Array<SubjectComputedField> | Array<SubjectInputField> {
  const allFields = getAllCustomFields(tenant.customFields);

  if (type === constants.TenantInputFieldTypename) {
    return allFields.filter(x => isInputField(x.field)).map(x => x.field as SubjectInputField);
  }

  return allFields.filter(x => isComputedField(x.field)).map(x => x.field as SubjectComputedField);
}

export function getCustomFieldValue(
  fieldValues: Array<SubjectCustomFieldValue>,
  customField: SubjectCustomField
): SubjectCustomFieldValue | undefined {
  return fieldValues.find(cfv => cfv.customField.key === customField.key);
}

export const getSubjectPrimaryFieldValue = (
  subject: Pick<Subject, 'customFieldValues'>,
  tenant?: ITenant
): string | null => {
  const primaryField = tenant?.customFields.primaryField;
  if (!primaryField) return null;

  const customFieldValue = getCustomFieldValue(subject.customFieldValues, primaryField.field);
  if (!customFieldValue) {
    return null;
  }

  if (customFieldValue.__typename === 'SubjectCustomFieldSingleValue') {
    return customFieldValue.valueText;
  }

  throw new Error('Primary field is not a single value field');
};

export const getFieldTypeName = (type: FieldTypeKey): string => {
  switch (type) {
    case FieldTypeKey.TEXT:
      return 'Text';
    case FieldTypeKey.NUMBER:
      return 'Number';
    case FieldTypeKey.DATE:
      return 'Date';
    case FieldTypeKey.DATE_TIME:
      return 'Date Time';
    case FieldTypeKey.TIME:
      return 'Time';
    case FieldTypeKey.CONSTRAINED_VALUES:
      return 'Dropdown List';
    case FieldTypeKey.EMAIL:
      return 'Email';
    case FieldTypeKey.PHONE_NUMBER:
      return 'Phone Number';
    case FieldTypeKey.SEX:
      return 'Sex';
    case FieldTypeKey.USER_IDENTIFIER:
      return 'User Identifier';
    default:
      return assertUnreachable(type);
  }
};
