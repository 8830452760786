import React from 'react';
import DataObjectIcon from '@mui/icons-material/DataObject';
import LiteralIcon from '@mui/icons-material/TextFormat';
import { ValueMode } from './ValueMode';
import { Chip, Switch, Tooltip } from '@mui/material';

interface IProps {
  mode: ValueMode;
  onModeChange: (mode: ValueMode) => void;
}

const ValueModeSwitch: React.FC<IProps> = ({ mode, onModeChange }) => {
  const changeMode = () => {
    const newMode = mode === 'exact' ? 'liquid' : 'exact';
    onModeChange(newMode);
  };

  const title = mode === 'liquid' ? 'Toggle to Exact Value Mode' : 'Toggle to Liquid Value Mode';

  const ModeIcon = () => {
    const title = mode === 'exact' ? 'Exact Value Mode' : 'Liquid Value Mode';
    const icon = mode === 'exact' ? <LiteralIcon /> : <DataObjectIcon />;
    return <Tooltip title={title}>{icon}</Tooltip>;
  };

  return (
    <Chip
      icon={<ModeIcon />}
      label={
        <React.Fragment>
          <span>
            <Tooltip title={title}>
              <Switch
                aria-label={title}
                edge="end"
                checked={mode === 'liquid'}
                color="primary"
                onClick={changeMode}
              />
            </Tooltip>
          </span>
        </React.Fragment>
      }
      clickable
      variant="outlined"
      sx={{
        marginBottom: '10px',
        paddingLeft: '10px',
        cursor: 'default'
      }}
    />
  );
};

export default ValueModeSwitch;
