import { query } from '../../api.gen';

export const CustomFieldTypesQuery = query('CustomFieldTypes', q => [
  q.appConfig(a => [
    a.__typename,
    a.customFieldTypes(c => [
      c.__typename,
      c.nodes(n => [
        n.$on('MultipleChoiceFieldType', m => [m.__typename]),
        n.$on('SimpleFieldType', s => [s.__typename]),
        n.key
      ])
    ])
  ])
]);
