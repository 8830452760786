import { VariablesOf } from '@graphql-typed-document-node/core';
import { $$, mutation } from '../../api.gen';

export const CreateStructureTypeMutation = mutation('CreateStructureType', m => [
  m.createStructureType({ input: $$('structureType') }, st => [
    st.__typename,
    st.key,
    st.name,
    st.subjectAssignable,
    st.isUsed,
    st.customFields(cf => [
      cf.__typename,
      cf.key,
      cf.name,
      cf.required,
      cf.type(t => [
        t.key,
        t.$on('SimpleFieldType', sft => [sft.__typename]),
        t.$on('MultipleChoiceFieldType', cvft => [cvft.__typename, cvft.options])
      ]),
      cf.isArray
    ])
  ])
]);

export type StructureTypeInput = VariablesOf<typeof CreateStructureTypeMutation>['structureType'];
