import { sortBy } from 'lodash-es';
import { useRef, useState } from 'react';
import { FieldsFilter, FilterForm, FilterIconButton } from '../../../../Filter';
import { PopupMenu } from '../../../../PopupMenu';
import { useVisualisationContext } from '../../VisualisationContext';
import { DataPointsFilter } from '@aireframe/shared-types';

export const VisualisationFilter = () => {
  const { definition, filter, setFilter, filterResult } = useVisualisationContext();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const hasFailedFilters = filterResult && filterResult.failedFilters.length > 0;
  const fields = sortBy(definition.series, s => s.order).map(series => ({
    title: series.name,
    key: series.key,
    dataType: series.dataType
  }));

  const onSubmit = (filter: DataPointsFilter | null) => {
    setFilter(FieldsFilter.fromFilterInput(filter));
    setOpen(false);
  };

  return (
    <>
      <FilterIconButton
        onClick={() => setOpen(o => !o)}
        filterActive={filter.hasFilter()}
        invalidFilter={hasFailedFilters}
        ref={anchorRef}
      />
      {open && (
        <PopupMenu
          onClose={() => setOpen(false)}
          title="Filter"
          showTitle={false}
          popoverProps={{
            anchorEl: anchorRef.current,
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            anchorOrigin: { vertical: 'center', horizontal: 'center' },
            slotProps: {
              paper: {
                sx: {
                  pt: 1
                },
                style: {
                  width: '40vw',
                  maxHeight: '60vh',
                  overflowX: 'hidden',
                  overflowY: 'auto'
                }
              }
            }
          }}>
          <FilterForm
            fields={fields}
            filter={filter.toFilterInput()}
            onSubmit={onSubmit}
            resolvedLiquidValues={filterResult?.resolvedLiquidValues}
          />
        </PopupMenu>
      )}
    </>
  );
};
