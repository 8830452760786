import { createFileRoute } from '@tanstack/react-router';
import * as zod from 'zod';
import { validateAuthenticated } from '../../router';
import { activeVisualisationFilterSchema } from '@aireframe/shared-types';

const routeSearchSchema = zod.object({
  visualisationFilters: zod.array(activeVisualisationFilterSchema).optional()
});

export const Route = createFileRoute('/$id/')({
  beforeLoad: ({ context, location }) => validateAuthenticated({ context, location }),
  validateSearch: routeSearchSchema
});
