import { useLazyQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useAuthentication } from '../Authentication';
import { CurrentUserQuery } from '@aireframe/graphql';

export const useCurrentUser = () => {
  const { isLoggedIn, user } = useAuthentication();
  const [query, { data, loading }] = useLazyQuery(CurrentUserQuery, {
    errorPolicy: 'all',
    onError: () => {
      return;
    }
  });

  useEffect(() => {
    if (isLoggedIn) query();
  }, [isLoggedIn, query]);

  const activeUser = useMemo(() => {
    if (!user || !data) return undefined;

    return {
      ...data.me,
      displayName: user.name,
      email: user.email,
      picture: user.profile?.picture,
      jobRole: user.jobRole
    };
  }, [data, user]);

  return {
    activeUser,
    loading
  };
};
