import { gql } from '@apollo/client';

export const INPUT_FIELD_FRAGMENT_NAME = 'InputFieldFragment';
export const INPUT_FIELD_FRAGMENT = gql`
    fragment ${INPUT_FIELD_FRAGMENT_NAME} on InputField {
        key
        name
        required
        type {
            key
            dataType
            ... on MultipleChoiceFieldType {
                options
            }
        }
    }
`;
