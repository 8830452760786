import { UserWithAccessToSubjectQuery } from '@aireframe/graphql';
import { UserIdentifier, UserIdentifierType } from '@aireframe/shared-types';
import { useLazyQuery } from '@apollo/client';
import {
  Autocomplete,
  AutocompleteProps,
  CircularProgress,
  TextField,
  TextFieldProps
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useAbility } from '../../Authorisation';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { SearchOptions, useDebouncedUserSearch } from './DebouncedUserSearch';

type Option = UserIdentifier & { displayName: string };
function isOption(value: UserIdentifier): value is Option {
  return (value as Option).displayName !== undefined;
}

export type UserIdentifierLookUpFieldProps = SearchOptions & {
  onChange: (value: UserIdentifier | undefined) => void;
  value: UserIdentifier | undefined | null;
  autoCompleteProps?: Pick<
    AutocompleteProps<Option, false, undefined, true>,
    'fullWidth' | 'disabled'
  > &
    Omit<TextFieldProps, 'value' | 'onChange' | 'disabled'>;
};

export const UserIdentifierLookUpField = ({
  onChange,
  autoCompleteProps: { fullWidth, disabled, ...textFieldProps } = {},
  value,
  ...searchOptions
}: UserIdentifierLookUpFieldProps) => {
  const ability = useAbility();
  const canList = ability.can('list', 'User');

  const { getUsers, loading, options } = useDebouncedUserSearch(searchOptions);
  const [userDetailQuery, { data: userData, loading: defaultValueLoading }] = useLazyQuery(
    UserWithAccessToSubjectQuery(!!searchOptions.withAccessToSubjectId)
  );

  useEffect(() => {
    if (value && !loading && !options) {
      userDetailQuery({
        variables: {
          id: value.identifier,
          withAccessToSubjectId: searchOptions.withAccessToSubjectId as string
        }
      });
    }
  }, [value, loading, options, searchOptions.withAccessToSubjectId, userDetailQuery]);

  const onChangeInternal = (_: React.SyntheticEvent, value: Option | null) => {
    let userIdentifier: UserIdentifier | undefined;
    if (value) {
      userIdentifier = {
        identifier: value.identifier,
        userIdentifierType: UserIdentifierType.User
      };
    }
    onChange(userIdentifier);
  };

  const resolvedValue = useMemo<Option | null>(() => {
    if (!value) {
      return null;
    }

    if (isOption(value)) {
      return value;
    }

    if (options) {
      return options.find(o => o.identifier === value.identifier) ?? null;
    }

    if (
      userData?.tenant.user?.id === value.identifier &&
      (userData.tenant.user.isEnabled || searchOptions.includeInactive) &&
      (!searchOptions.withAccessToSubjectId || userData.tenant.user.hasAccessToSubject)
    ) {
      // User has been queried from the API with provided options
      return {
        identifier: userData.tenant.user.id,
        displayName: userData.tenant.user.displayName,
        userIdentifierType: UserIdentifierType.User
      };
    }

    // Data is either loading, or the value does not match any options/API data
    return null;
  }, [value, options, userData, searchOptions]);

  const onInputChange = (_: React.SyntheticEvent, value: string) => {
    if (value.length >= 3 && value !== resolvedValue?.displayName) {
      getUsers(value);
    }
  };

  if (!canList) {
    return (
      <ErrorMessage message="The List:User permission is required to use this functionality" />
    );
  }

  return (
    <Autocomplete
      fullWidth={fullWidth}
      disabled={disabled || defaultValueLoading}
      value={resolvedValue}
      multiple={false}
      onChange={onChangeInternal}
      isOptionEqualToValue={(option, value) => option.identifier === value.identifier}
      getOptionLabel={option => option.displayName}
      options={options ?? []}
      loading={loading || defaultValueLoading}
      onInputChange={onInputChange}
      filterOptions={(o, state) => (state.inputValue.length >= 3 ? o : [])}
      renderInput={params => (
        <TextField
          {...textFieldProps}
          {...params}
          helperText={textFieldProps?.helperText ?? 'Enter at least 3 characters to search'}
          slotProps={{
            input: {
              ...textFieldProps?.InputProps,
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              )
            },

            htmlInput: {
              ...textFieldProps?.inputProps,
              ...params.inputProps
            },

            inputLabel: {
              ...textFieldProps?.InputLabelProps,
              shrink: true
            }
          }}
        />
      )}
    />
  );
};

export default UserIdentifierLookUpField;
